
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Grid, GridProps, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { EmailOutlined as EmailIcon, PhoneOutlined as Phone, PhoneIphoneOutlined as PhoneIcon, WhatsApp } from "@mui/icons-material";
import { Link } from "src/components";
import { getWhatsAppLinkWithMessage, initialsFor } from "src/helpers";
import { applicationActions } from "src/store/application/actions";
import { useStyles } from "./styles";

interface ConsultantCardProps extends GridProps {
	text?: string;
	/** if is lastOne get latest application */
	applicationOrigin?: 'lastOne' | 'current',
	/** if is true get request lastest application */
	requestLastOneApplication?: boolean;
	/** Show a loader during 9s */
	showDelayedLoader?: boolean
}

export function ConsultantCard({ text, applicationOrigin = 'lastOne', requestLastOneApplication, showDelayedLoader, ...rest }: ConsultantCardProps) {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.customer.user);
	const application = useSelector(state => state.applications.application);
	const lastOneApplication = useSelector(state => state.applications.lastApplication);
	const isLoading = useSelector(state => state.applications.isLoading);
	const isAuthLoading = useSelector(state => state.customer.isLoading)
	const [delayed, setDelayed] = useState(showDelayedLoader)

	useEffect(() => {
		if (applicationOrigin === 'lastOne' && requestLastOneApplication) {
			dispatch(applicationActions.getLastOneApplication());
		}
	}, []);

	useEffect(() => {
		if (showDelayedLoader) {
			setDelayed(true)
			setTimeout(() => {
				setDelayed(false)
			}, 9000)
		}
	}, [showDelayedLoader])

	return (
		<>
			<Grid
				container
				title={text || "¿Dudas? Agenda tu asesoría."}
				className={classes.card}
				{...rest}
			>
				<Grid container spacing={3}>

					{isLoading || isAuthLoading || delayed ? (
						<>
							<Grid item xs={12} className={classes.item}>
								<Skeleton variant="text" width={500} className={classes.skeleton} />
							</Grid>

							<Grid item xs={12} className={classes.item}>
								<Skeleton variant="circular" width={90} height={90} className={classes.skeleton} />
							</Grid>

							<Grid item xs={12} className={cx(classes.item, classes.nameDescription)}>
								<Skeleton variant="text" width={500} className={classes.skeleton} />
								<Skeleton variant="text" width={500} className={classes.skeleton} />
							</Grid>

							<Grid item xs={12} className={cx(classes.item, classes.contact)}>
								<Skeleton variant="text" width={500} className={classes.skeleton} />
								<Skeleton variant="text" width={500} className={classes.skeleton} />
							</Grid>
						</>
					) : (
						<>
							<Grid item xs={12} className={classes.item}>
								<Typography variant="h5" className={classes.title}> ¿Dudas? Agenda una asesoría  </Typography>
							</Grid>

							<Grid item xs={12} className={classes.item}>
								<Avatar
									color="primary"
									className={classes.avatar}
									src={user?.profile_image}
								>
									{initialsFor(`${user?.first_name} ${user?.last_name}`)}
								</Avatar>
							</Grid>

							<Grid item xs={12} className={cx(classes.item, classes.nameDescription)}>
								<Typography variant="h3" className={classes.name}>
									{user?.first_name || ''} {user?.last_name || ''}
								</Typography>
								<Typography>
									¡Hola! Estoy para resolver tus preguntas, por favor no dudes en contactarme.
								</Typography>
							</Grid>

							<Grid item xs={12} className={cx(classes.item, classes.contact)}>
								{user?.email && <Typography> <EmailIcon color="primary" /> {user?.email} </Typography>}
								{user?.phone && <Typography> <PhoneIcon color="primary" /> {user?.phone?.replace('+', '')} </Typography>}
							</Grid>

						</>
					)}
				</Grid>
			</Grid>

			{!isLoading && !isAuthLoading && !delayed && (
				<Grid item xs={12} className={classes.itemActions}>
					<Grid container spacing={2}>

						{user?.phone && (
							<Grid item xs={12} md={user?.calendly_user ? 6 : 12}>
								<a
									href={getWhatsAppLinkWithMessage({
										user,
										application: applicationOrigin === 'lastOne' ? lastOneApplication : application,
										origin: applicationOrigin
									})}
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: 'none' }}
								>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										fullWidth
										startIcon={<WhatsApp />}
									>
										Enviar WhatsApp
									</Button>
								</a>
							</Grid>
						)}

						{user?.calendly_user && (
							<Grid item xs={12} md={6}>
								<Link to='/home/consultant/schedule'>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										fullWidth
										startIcon={<Phone />}
									>
										Agendar asesoría
									</Button>
								</Link>
							</Grid>
						)}

					</Grid>
				</Grid>
			)}
		</>
	)
}