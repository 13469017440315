import { Action, PaymentType } from "src/types"
import { PAYMENTS_TYPES } from "./types"

export interface PaymentsState {
    data: PaymentType[]
    isLoading: boolean
    payment?: PaymentType
    isLoadingOne: boolean
    isUpdating: boolean
    isGeneratingLink: boolean
}

const INITIAL_STATE: PaymentsState = {
    data: [],
    isLoading: false,
    isLoadingOne: false,
    isUpdating: false,
    isGeneratingLink: false
}

export const applicationPaymentsReducer = (state: PaymentsState = INITIAL_STATE, action: Action): PaymentsState => {
    const { type, payload } = action

    switch (type) {
        default: return state

        case PAYMENTS_TYPES.SET: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case PAYMENTS_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true
            }
        }

        case PAYMENTS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.payments
            }
        }

        case PAYMENTS_TYPES.GET_ONE:
        case PAYMENTS_TYPES.UPDATE: {
            return {
                ...state,
                isLoadingOne: true
            }
        }

        case PAYMENTS_TYPES.LINK: {
            return {
                ...state,
                isGeneratingLink: true
            }
        }

        case PAYMENTS_TYPES.SET_ONE: {
            return {
                ...state,
                payment: payload.payment,
                isLoadingOne: false,
                isUpdating: false,
                isGeneratingLink: false
            }
        }

        case PAYMENTS_TYPES.CREATE:
        case PAYMENTS_TYPES.UPDATE: {
            return {
                ...state,
                isUpdating: true,
                isGeneratingLink: true
            }
        }
    }
}