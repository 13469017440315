import { useMemo, useState } from 'react'
import { Card } from 'src/components'
import { CustomSwitchableEvaluatorQualifierField } from 'src/types'
import { useIsMobile } from 'src/helpers'
import { DesktopDocumentList, MobileDocumentList } from './Views'
import { DocumentDialog } from './DocumentDialog'
import { useStyles } from './styles'

import { useSelector } from 'react-redux'

export type DocDialogParams = { open: boolean, approval?: CustomSwitchableEvaluatorQualifierField }
export type SetDialogFunction = (docDialog: DocDialogParams) => void

interface DocumentListProps {
    /** If set, the approvals will be filtered by this actionableId */
    actionableId?: string
    title?: string
}

export function DocumentList({ actionableId, title }: DocumentListProps) {
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile()

    const storeApprovals = useSelector(state => state.applications.switchableEvaluators?.approvals || [])

    const approvals = useMemo(() => {
        const filteredApprovals = storeApprovals.filter(approval => approval?.document_required)

        if (!actionableId)
            return filteredApprovals.filter(
                approval => ['failed', 'pending'].includes(approval?.status)
            )

        return filteredApprovals.filter(approval => approval?.actionable_id === actionableId)
    }, [storeApprovals, actionableId])

    const [docDialog, setDocDialog] = useState<DocDialogParams>({ open: false })

    const listProps = { approvals, setDocDialog }

    return (
        <>
            {docDialog?.approval && (
                <DocumentDialog
                    approval={docDialog.approval!}
                    dialogProps={{
                        open: docDialog.open,
                        onClose: () => setDocDialog({ open: false }),
                    }}
                />
            )}

            <Card
                titleOutside
                title={title ? title : 'Documentos que requieren acción'}
                titleProps={{ className: classes.cardTitle }}
                className={cx({
                    [classes.emptyCard]: approvals.length == 0,
                })}
            >
                {isMobile ? <MobileDocumentList {...listProps} /> : <DesktopDocumentList {...listProps} />}
            </Card>
        </>
    )
}