import { Table } from "src/components"
import { PaymentType } from "src/types"
import { paymentColumns } from "./columns"

interface PaymentsTableProps {
    applicationId: string
    payments?: PaymentType[]
}

export function PaymentsTable({ applicationId, payments }: PaymentsTableProps) {
    return (
        <Table
            data={payments}
            columns={paymentColumns}
            isLink
            getHref={payment => `/home/applications/${applicationId}/payments/${payment?.id}`}
            emptyStateText="No se encontraron pagos"
            emptyStateProps={{
                size: 'small'
            }}
        />
    )
}