import { SvgIcon } from "@mui/material"

/**
 * HOC to wrap a component with an material SVG icon
 */
export function withSvg(Svg: any) {
    return (
        props: React.ComponentProps<typeof SvgIcon>
    ) => (
        <SvgIcon {...props}>
            <Svg />
        </SvgIcon>
    )
}