import { useLocation } from "react-router-dom"
import { AppBar } from "@mui/material"
import { cookies, getCookieName, segment } from "src/helpers"
import { useStyles } from "./Topbar.styles"

import { useDispatch, useSelector } from "react-redux"
import { authActions } from "src/store/auth/actions"
import { Toolbar } from "./Toolbar"

export { DESKTOP_TOOLBAR_HEIGHT, MOBILE_TOOLBAR_HEIGHT } from "./Topbar.styles"

export function Topbar() {
	const dispatch = useDispatch()
	const location = useLocation()

	const organization = useSelector(state => state.organization)
	const { isAuthenticated: isStoreAuth, customer } = useSelector(state => state.auth)

	const { classes } = useStyles(organization?.theme)()

	const logout = () => dispatch(authActions.logout())
	const isAuthenticated = isStoreAuth && Boolean(cookies.get(getCookieName()))
	const hideLoginButtons = location.pathname === "/maintenance"

	function handleLogout() {
		logout()
		segment.track('Customer', 'Logout')
	}

	return (
		<AppBar className={classes.root} position="fixed" elevation={0}>
			<Toolbar
				customer={customer}
				organization={organization}
				isAuthenticated={isAuthenticated}
				hideLoginButtons={hideLoginButtons}
				logout={handleLogout}
			/>
		</AppBar>
	)
}