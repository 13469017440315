import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: 33
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        width: 218,
        maxWidth: '100%',
        gap: 24
    },
    itemIconWrapper: {
        '& svg': {
            minHeight: 24,
            minWidth: 24,
            color: theme.palette.primary.main,
            '& path': {
                fill: 'currentcolor'
            }
        }
    },
    itemContent: {
    },
    itemTitle: {
        color: 'black',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        letterSpacing: "0.15px",
        marginBottom: 4
    },
    itemValueWrapper: {
    },
    itemValue: {
        fontSize: "16px",
        lineHeight: "22px",
        letterSpacing: "0.5px"
    }
}))