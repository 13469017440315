import { useMemo, useState } from "react"
import { Box, Button, Grid, IconButton, Typography, MenuItem } from "@mui/material"
import { MoreVert as MoreVertIcon } from "@mui/icons-material"
import DocumentImage from "src/assets/img/home/documents/document.svg"
import { Link, LinkProps, Menu } from "src/components"
import { ActionableType, CustomSwitchableEvaluatorQualifierField } from "src/types"
import { DocumentsPercentBar } from "./DocumentBar"
import { getStatus, MenuActionProps } from "./helpers"
import { useStyles } from "./styles"
import _ from "lodash"

export interface ProgressCardProps {
    /** Title card */
    title: string

    /** Description card */
    description?: string

    /** Title or text of principal button */
    actionTitle?: string

    /** Principal icon, if not defined shows a default icon */
    Icon?: any

    /** Handle function of principal button */
    handleAction?: () => void

    /** Menu items of popup menu (top right dots) */
    menuActions?: MenuActionProps[]

    /** Hidden menu actions (top right dots) */
    hideMenuActions?: boolean

    /** Filtered approvals */
    approvals?: CustomSwitchableEvaluatorQualifierField[]

    /** Resource id related to card (applicationId, employmentId, etc.) */
    actionableId?: string

    actionableType?: ActionableType

    to?: LinkProps['to']

    rootProps?: Omit<LinkProps, 'to'>

    disabled?: boolean
}

export function ProgressCard({
    title,
    description,
    actionTitle,
    Icon,
    approvals = [],
    handleAction,
    menuActions = [],
    hideMenuActions,
    actionableId,
    actionableType,
    to,
    rootProps,
    disabled
}: ProgressCardProps) {
    const { cx, classes } = useStyles()

    const [actionMenu, setActionMenu] = useState<HTMLButtonElement | null>(null)

    const totals = useMemo(() => {
        return {
            finished: approvals.filter(a => a?.status === 'finished').length,
            rejected: approvals.filter(a => a?.status === 'failed').length,
            inReview: approvals.filter(a => a?.status === 'input_required').length,
            pending: approvals.filter(a => a?.status === 'pending').length
        }
    }, [approvals])

    const status = getStatus({
        inReview: totals.inReview,
        finished: totals.finished,
        approvalsTotal: totals.finished + totals.inReview + totals.pending + totals.rejected
    })

    return (
        <>
            {menuActions?.length > 0 && (
                <Menu
                    anchorEl={actionMenu}
                    open={Boolean(actionMenu)}
                    onClose={() => setActionMenu(null)}
                >
                    {menuActions?.map((action, index) => {
                        const { handleAction, disabled } = action
                        return (
                            <MenuItem
                                key={index}
                                disabled={disabled}
                                onClick={() => {
                                    if (_.isFunction(handleAction)) {
                                        handleAction(actionableId, actionableType)
                                    }

                                    setActionMenu(null)
                                }}
                            >
                                {action.title}
                            </MenuItem>
                        )
                    })}
                </Menu>
            )}

            <Link
                {...rootProps}
                to={to || '#'}
                className={cx(
                    classes.root,
                    rootProps?.className,
                    { ['disabled']: disabled }
                )}
            >
                <>
                    {(!hideMenuActions && menuActions.length > 0) && (
                        <div className={classes.menuButton}>
                            <IconButton
                                size="small"
                                onClick={e => setActionMenu(e.currentTarget)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </div>
                    )}

                    <Box className={classes.container}>
                        <Grid container style={{ textAlign: 'center', alignItems: 'stretch' }}>
                            <Grid
                                item
                                xs={12}
                                className={classes.iconContainer}
                            >
                                {Icon ? <Icon /> : <DocumentImage />}
                            </Grid>

                            <Grid item xs={12} className={classes.titleDescriptionContainer}>
                                <Typography className={classes.title}> {title} </Typography>

                                {description && (
                                    <Typography className={classes.description}> {description} </Typography>
                                )}
                            </Grid>

                            <Grid container className={classes.footerContainer}>
                                <Grid item xs={12}>
                                    <DocumentsPercentBar totals={totals} />
                                </Grid>

                                <Grid item xs={12}>
                                    <div
                                        className={classes.statusChip}
                                        style={{ color: status.color, background: status.bgColor }}
                                    >
                                        {status.label}
                                    </div>
                                </Grid>

                                {(actionTitle && handleAction) && (
                                    <Grid item xs={12}>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            className={classes.actionButton}
                                            onClick={_ => handleAction?.()}
                                        >
                                            {actionTitle}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </Link>
        </>
    )
}