import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    dataCard: {
        borderRadius: 5,
        backgroundColor: '#fff',
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        '& path': {
            fill: theme.palette.primary.main
        }
    },
    data: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    detailsCard: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        border: '1px solid #F4F6F9',
        borderLeft: 'none',
        minHeight: 350,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    detailsData: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        minHeight: 330,
    }
}))