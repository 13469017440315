import { GetOptionsType, PaymentGenerateLinkMode, PaymentQueryParamsType, PaymentType } from "src/types"
import { PAYMENTS_TYPES } from "./types"
import { PaymentsState } from "./reducer"

function set(key: keyof PaymentsState, value: any) {
    return {
        type: PAYMENTS_TYPES.SET,
        payload: { key, value }
    }
}

function getOne(id: string) {
    return {
        type: PAYMENTS_TYPES.GET_ONE,
        payload: { id }
    }
}

function setOne(payment?: PaymentType) {
    return {
        type: PAYMENTS_TYPES.SET_ONE,
        payload: { payment }
    }
}

function getAll(options: GetOptionsType<any, PaymentQueryParamsType>) {
    return {
        type: PAYMENTS_TYPES.GET_ALL,
        payload: { options }
    }
}

function setAll(payments: PaymentType[] = []) {
    return {
        type: PAYMENTS_TYPES.SET_ALL,
        payload: { payments }
    }
}

function create(payment: Object) {
    return {
        type: PAYMENTS_TYPES.CREATE,
        payload: { payment }
    }
}

function update(paymentId: string, payment: Object) {
    return {
        type: PAYMENTS_TYPES.UPDATE,
        payload: { paymentId, payment }
    }
}

function destroy(id: string, applicationId: string) {
    return {
        type: PAYMENTS_TYPES.DESTROY,
        payload: { id, applicationId }
    }
}

function link(id: string, templateType: PaymentGenerateLinkMode) {
    return {
        type: PAYMENTS_TYPES.LINK,
        payload: { id, templateType }
    }
}

export const applicationPaymentsActions = {
    set,
    getAll,
    setAll,
    getOne,
    setOne,
    create,
    update,
    destroy,
    link
}