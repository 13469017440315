import { SelectValueType } from "src/types"

export const translateApprovalStatus: { [key: string]: string } = {
    error: 'Error en ejecución',
    pending: 'Pendiente',
    running: 'En ejecución',
    finished: 'Sí cumple',
    input_required: 'En revisión',
    failed: 'No cumple',
    mannual_not_applicable: 'No aplica',
    not_applicable: 'No aplica'
}

export const translateApprovalDetailStatus: { [key: string]: string } = {
    error: 'Error en ejecución',
    pending: 'Pendiente',
    running: 'En ejecución',
    finished: 'Aceptado',
    input_required: 'En revisión',
    failed: 'Rechazado',
    mannual_not_applicable: 'No aplica',
    not_applicable: 'No aplica'
}

export function getApprovalStatusColor(status?: string): { color: string, background: string } {
    switch (status) {
        case 'finished':
            return { color: '#169B61', background: '#E5F4EC' };
        case 'running':
            return { color: '#4AAAFF', background: '#E4F3FF' };
        case 'error':
        case 'failed':
            return { color: '#F44336', background: '#FFF1F1' };
        case 'input_required':
            return { color: '#FF782D', background: 'rgba(255, 196, 45, 0.1)' };
        case 'pending':
            return { color: '#8892A0', background: 'rgba(117, 139, 183, 0.08)' };
        default:
            return { color: '#8892A0', background: 'rgba(117, 139, 183, 0.08)' };
    }
}

export const approvalStatuses: SelectValueType[] = [
    { value: 'finished', label: 'Aceptado' },
    { value: 'input_required', label: 'En revisión' },
    { value: 'running', label: 'En ejecución' },
    { value: 'pending', label: 'Doc. Pendiente' },
    { value: 'failed', label: 'Rechazado' },
    { value: 'error', label: 'Error en ejecución' },
]