import { Table } from 'src/components'
import { DocumentListViewProps } from '..'
import { images } from 'src/helpers/images'
import { getColumns } from './helpers'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'

export function DesktopDocumentList({ approvals, setDocDialog }: DocumentListViewProps) {
    const { classes } = useStyles()

    const orgSlug = useSelector(state => state.organization?.slug)

    return (
        <Table
            emptyStateText="No tienes documentos pendientes"
            columns={getColumns(setDocDialog)}
            data={approvals}
            tableHeadProps={{ className: classes.tableHead }}
            emptyStateProps={{
                className: classes.emptyState,
                customIcon: orgSlug ? images[orgSlug].docs.empty : undefined
            }}
        />
    )
}