import { PaymentType, SessionPaymentStatusType, SessionStatusType } from "src/types"
import { currencyString } from "./format"

export const translatePaymentSessionStatus: { [key in SessionStatusType | '']: string } = {
    complete: 'En proceso',
    expired: 'Expirado',
    open: 'Pendiente',
    succeeded: 'Aprobado',
    '': '',
}

export const hasExtraExpirationTime = (payment: PaymentType): boolean => {
    const expiresAt = payment?.after_expiration?.recovery?.expires_at || 0

    // multiply by 1000 to convert seconds to milliseconds
    return (expiresAt * 1000) > Date.now()
}


export const customTranslatePaymentSessionStatus = (payment?: PaymentType): string => {
    const { status, payment_intent_status } = payment || {}

    let customStatus = translatePaymentSessionStatus['open']

    if (!payment)
        return customStatus.toLowerCase()

    // if the payment way is a bank transfer, it status will be complete and payment_intent_status
    // will be not available, so we return the open status to show the payment as 'in process'
    if (status === 'complete' && !payment_intent_status) {
        customStatus = translatePaymentSessionStatus['complete']
    } else if (payment_intent_status === 'succeeded' && status === 'complete') {
        customStatus = translatePaymentSessionStatus['succeeded']
    } else if (status === 'expired') {
        if (hasExtraExpirationTime(payment)) {
            customStatus = translatePaymentSessionStatus['open']
        } else {
            customStatus = translatePaymentSessionStatus[status]
        }
    }

    return customStatus.toLowerCase()
}

export const translatePaymentSessionPaymentStatus: { [key in SessionPaymentStatusType]: string } = {
    no_payment_required: 'No requerido',
    paid: 'Aprobado',
    unpaid: 'Pendiente',
}

export const getPaymentSessionStatusColor = (status?: SessionStatusType): { color: string, backgroundColor: string } => {
    switch (status) {
        default:
        case 'open':
            return { color: '#8892A0', backgroundColor: '#F4F6F9' }
        case 'complete':
        case 'succeeded':
            return { color: '#1DAA6C', backgroundColor: '#E5F4EC' }
        case 'expired':
            return { color: '#F44336', backgroundColor: '#FFF1F1' }
    }
}

export const getPaymentStatus = (payment?: PaymentType): SessionStatusType => {
    const { status, payment_intent_status } = payment || {}

    // if the payment way is a bank transfer, it status will be complete and payment_intent_status
    // will be not available, so we return the open status to show the payment as pending
    if (!payment || (status === 'complete' && !payment_intent_status))
        return 'open'

    if (payment_intent_status === 'succeeded' && status === 'complete')
        return 'complete'

    if (status === 'expired') {
        if (hasExtraExpirationTime(payment)) {
            return 'open'
        }
        return status
    }

    return payment?.status || 'open'
}

export function getAppraisalPayment(payments?: PaymentType[]): PaymentType | undefined {
    return payments?.find(payment => payment?.payment_type?.toLowerCase().includes('aval'))
}

/**
 * Format any payment amount in string format
 * @param amount Amount in cents
 * @param applyCurrencyFormat Apply currency format
 * @returns Amount divided by 100 and formatted as currency
 */
export const formatPaymentAmount = (amount?: number, applyCurrencyFormat: boolean = true): string => {
    if (!amount) return ''
    if (amount === 0) {
        return `${amount}`
    }
    if (applyCurrencyFormat) {
        return currencyString(`${amount / 100}`)
    }
    return `${amount / 100}`
}

export function getPaymentSeverity(status?: string): "success" | "error" | "warning" {
    switch (status) {
        default:
        case "open":
            return "warning"
        case "complete":
        case 'succeeded':
            return "success"
        case "expired":
            return "error"
    }
}