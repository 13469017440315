import { useEffect, useState } from "react"
import { Grid, Zoom } from "@mui/material"
import { Loading, StagePage } from 'src/components'
import { segment } from "src/helpers"
import { DashboardCard, ConsultantCard } from "./components"
import _ from "lodash"

import { CancelApplicationDrawer } from "../Applications/CancelApplicationDrawer"
import { CoaccreditDrawer } from "../Applications/CoaccreditDrawer"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions, applicationCategoriesActions, applicationRejectedDocuments } from "src/store/application/actions"
import { creditsActions } from "src/store/credits/actions"

export function HomeMain() {
	const dispatch = useDispatch()

	const customer = useSelector(state => state.auth.customer)
	const canceledCategories = useSelector(state => state.applications.categories?.data || [])
	const lastOneApplication = useSelector(state => state.applications.lastApplication)
	const isLoadingApplication = useSelector(state => state.applications.isLoading)
	const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded)
	const isLoadingRejectedDocuments = useSelector(state => state.applications.rejectedDocuments.isLoading)

	const [cancelApplicationOpen, setCancelApplicationOpen] = useState(false)
	const [coaccreditOpen, setCoaccreditOpenOpen] = useState(false)

	useEffect(() => {
		fetchCancelledCategories()
		dispatch(applicationActions.getLastOneApplication())
	}, [])

	useEffect(() => {
		if (lastOneApplication?.id) {
			dispatch(applicationRejectedDocuments.getRequiredCount(lastOneApplication.id))
		}

		if (lastOneApplication?.credit_id) {
			dispatch(creditsActions.getOne(lastOneApplication.credit_id))
		}
	}, [lastOneApplication?.id])

	useEffect(() => {
		isSegmentLoaded && segment.page('Home')
	}, [isSegmentLoaded])

	function fetchCancelledCategories() {
		if (canceledCategories.length > 0) {
			return
		}
		dispatch(applicationCategoriesActions.getAll({
			search: { status_eq: 'cancelled' },
			perPage: 200
		}))
	}

	return (
		<>
			<CancelApplicationDrawer
				application={lastOneApplication}
				open={cancelApplicationOpen}
				onClose={() => setCancelApplicationOpen(false)}
			/>

			<CoaccreditDrawer
				application={lastOneApplication}
				open={coaccreditOpen}
				onClose={() => setCoaccreditOpenOpen(false)}
			/>

			<StagePage>
				<Loading
					isLoading={isLoadingApplication || isLoadingRejectedDocuments}
					variant="skeleton"
					skeletonsNumber={2}
				>
					<Grid container gap={'40px'}>
						<Grid item xs={12}>
							<DashboardCard
								customer={customer}
								application={lastOneApplication}
								setCancelApplicationOpen={setCancelApplicationOpen}
								setCoaccreditOpen={setCoaccreditOpenOpen}
							/>
						</Grid>

						{customer?.user && (
							<Zoom in>
								<Grid item xs={12}>
									<ConsultantCard />
								</Grid>
							</Zoom>
						)}
					</Grid>
				</Loading>
			</StagePage >
		</>
	)
}