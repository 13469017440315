import { Box, Typography } from "@mui/material"
import { PaymentType } from "src/types"
import { items } from "./items"
import { useStyles } from "./styles"

interface PaymentAsideProps {
    payment?: PaymentType
}

export function PaymentAside({ payment }: PaymentAsideProps) {
    const { classes } = useStyles()

    return (
        <Box className={classes.root}>
            {items.map((item, index) => {
                const { title, icon: Icon, key, render } = item

                const content = payment && render
                    ? render(payment?.[key], payment!, index)
                    : `${payment?.[key]}` || '-'

                return (
                    <Box className={classes.item}>
                        <Box className={classes.itemIconWrapper}>
                            <Icon />
                        </Box>

                        <Box className={classes.itemContent}>
                            <Typography className={classes.itemTitle}>
                                {title}
                            </Typography>

                            <Box className={classes.itemValueWrapper}>
                                {typeof content !== 'string' ? content : (
                                    <Typography className={classes.itemValue}>
                                        {content}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}