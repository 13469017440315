import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    dialogTitleContainer: {
        padding: '0 0 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px',
        }
    },
    dialogTitle: {
        fontSize: 24,
        lineHeight: '24px',
        fontWeight: 700
    },
    dialogPaper: {
        borderRadius: 10,
        padding: '24px 32px',
        margin: 16,
        width: 'calc(100% - 32px)',
        [theme.breakpoints.down('sm')]: {
            padding: 24
        }
    },
    dialogContent: {
        padding: 0,
    },
    iconButton: {
        marginLeft: 'auto',
        height: 24,
        width: 24,
        '& svg': {
            color: '#8892A0',
            '& path': {
                fill: 'currentcolor'
            },
        }
    }
}));