import { tss } from "tss-react/mui"
import { SMALL_TABLET_BREAKPOINT } from "src/assets/theme/theme"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'transparent',
        }
    },
    titleContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: '0px 0px 16px',
        }
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    title: {
        fontSize: 34,
        fontWeight: 'bold',
        lineHeight: '40px',
        color: theme.palette.primary.main,
        paddingBottom: 16,
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            fontSize: 24,
            lineHeight: '30px',
            letterSpacing: '0.18px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '26px',
            letterSpacing: '0.15px',
            paddingBottom: 0
        }
    }
}))