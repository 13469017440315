import { Grid, Skeleton } from "@mui/material"

export function SidebarLoading() {
    return (
        <Grid container style={{ padding: '0px 37px' }}>
            <Skeleton
                variant="rectangular"
                height={30}
                width="100%"
                style={{ marginBottom: 40 }}
            />

            {Array(8).fill(null).map((_, index) => (
                <Skeleton
                    key={index}
                    variant="rectangular"
                    height={30}
                    width="100%"
                    style={{ marginBottom: 16 }}
                />
            ))}
        </Grid>
    )
}