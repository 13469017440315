import { Action } from "src/types";
import { NAVIGATION_TYPES } from "./types";
import { NavigateOptions } from "react-router-dom";

export interface NagivationStateType {
    to?: string;
    options?: NavigateOptions
}

const INITIAL_STATE: NagivationStateType = {}

export function navigationReducer(state: NagivationStateType = INITIAL_STATE, action: Action): NagivationStateType {
    const { type, payload } = action
    switch (type) {

        case NAVIGATION_TYPES.TO: {
            return {
                ...state,
                to: payload.to,
                options: payload.options
            }
        }

        case NAVIGATION_TYPES.CLEAR: {
            return INITIAL_STATE
        }

        default:
            return state;
    }
}