import { AxiosInstance } from "axios";
import { ServiceConfig } from "src/services/service";
import _ from "lodash"

export type ApiType = 'iam' | 'apollo' | 'abacus' | 'koncierge' | 'scoring' | 'kronos';
export type NamespaceType = 'admin' | 'onboarding' | 'backoffice' | 'weesign' | 'customer' | 'v1' | 'incode' | 'kredi';

export function getServiceUrl(
    type: ApiType,
    namespace: NamespaceType = 'onboarding',
    options?: {
        disableNamespace?: boolean,
        provider?: string
    }): string {
    switch (type) {
        case 'iam': return `${import.meta.env?.REACT_APP_IAM_URL}`;
        case 'abacus': return `${import.meta.env?.REACT_APP_ABACUS_URL}`;
        case 'koncierge': return `${import.meta.env?.REACT_APP_KONCIERGE_URL}/v1${options?.provider ? `/services/${options?.provider}` : `/services/${namespace}`}`
        case 'scoring': return `${import.meta.env?.REACT_APP_SCORING_URL}/v1${options?.disableNamespace ? '' : namespace ? `/${namespace}` : ''}`
        case 'kronos': return `${import.meta.env?.REACT_APP_KRONOS_URL}${options?.disableNamespace ? '' : namespace ? `/${namespace}` : ''}`
        default: return `${import.meta.env?.REACT_APP_BASE_URL}/${namespace}/`;;
    }
}

/**
 * Get and normalize each of the keys from value object
 * @param obj value object
 * @returns value object normalized
 */
export function normalizeSearch(obj: any) {
    if (!obj) return {};
    const _obj = { ...obj };
    Object.keys(obj).forEach((key: any) => {
        if (obj[key] === '0' || obj[key] === '' || obj[key] == null) {
            delete _obj[key];
        } else if (_.isDate(obj[key])) {
            _obj[key] = normalizeTime(obj[key], key);
        }
    });
    return _obj;
}

/**
 * normalize datetime values by key
 * @param value value date
 * @param key search key (if key have gteg set time is 00:00:00:000 otherwise its lteq set time is 23:59:59:999)
 * @returns date with normalized times
 */
function normalizeTime(value: Date, key: any) {
    const _date = value;
    const keyType = _.isString(key) ? key.substring(key.lastIndexOf('_') + 1, key.length) : '';
    if (keyType === 'gteq') {
        _date.setHours(0, 0, 0, 0);
    } else if (keyType === 'lteq') {
        _date.setHours(23, 59, 59, 999);
    }
    return _date;
}

/**
 * Creates a base url for the service if it is not defined
 * @param service 
 * @param config 
 * @returns 
 */
export function getBaseUrl(service: AxiosInstance, config: ServiceConfig): string | undefined {
    return service.defaults.baseURL?.includes('undefined')
        ? getServiceUrl(config?.api || 'apollo', config?.namespace, { ...config })
        : service.defaults.baseURL;
}