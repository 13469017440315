import { Grid, GridProps } from "@mui/material";
import { useStyles } from "./RowCounter.styles";

interface RowCounterProps {
    perPage: number;
    total: number;
}

export const RowCounter = ({ perPage, total, ...rest }: RowCounterProps) => {
    const { classes, cx } = useStyles();

    return (
        <Grid item>
            <span className={classes.label} {...rest}>
                {(!!perPage) && (
                    <>Se muestran {perPage <= total ? perPage : total} registros</>
                )}
                {(!!perPage && !!total) && (
                    <> de {total}</>
                )}
            </span>
        </Grid>
    );
}