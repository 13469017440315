import { tss } from "tss-react/mui"
import { OrganizationThemeType } from "src/types"
import { SMALL_TABLET_BREAKPOINT } from "src/assets/theme/theme"

export const useStyles = (orgTheme?: OrganizationThemeType) => tss.create(({ theme }) => {
    const { navbar } = orgTheme || {}
    return {
        avatar: {
            border: '2px solid #fafafa',
            width: 32,
            height: 32,
            '&:nth-child(2n+1)': {
                background: theme.palette.primary.main
            },
            '&:nth-child(2n)': {
                background: theme.palette.primary.light
            }
        },
        text: {
            color: '#fff',
            fontSize: '12px !important',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
        },
        customerNameText: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '20px',
            color: theme.palette.primary.main || navbar?.color,
            marginLeft: 8,
            [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
                display: 'none'
            }
        },
        divider: {
            backgroundColor: theme.palette.primary.main,
            height: 24,
            width: 1,
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        logoutButton: {
            height: 24,
            width: 24,
            '& svg': {
                color: theme.palette.primary.main,
                minHeight: 24,
                minWidth: 24,
                '& path': {
                    fill: 'currentcolor'
                }
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
    }
})