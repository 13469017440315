import { TextHelpWithImage } from "@kredimx/form-builder"
import { FieldType } from "src/types"
import { useIsMobile } from "src/helpers"
import { getTemplateContent } from "./helpers"

interface UploadTemplateProps {
    title?: string
    fieldType: FieldType
}

export function UploadTemplate({ title, fieldType }: UploadTemplateProps) {
    const isMobile = useIsMobile()
    const { content, imageUrl } = getTemplateContent(fieldType)
    return (
        <TextHelpWithImage
            title={title}
            content={content}
            imageUrl={isMobile ? undefined : imageUrl}
        />
    )
}