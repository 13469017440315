import { tss } from "tss-react/mui"

const TOP_BOTTOM_MARGIN = 24

export const useStyles = tss.create(({ theme }) => ({
	paper: {
		height: `calc(100% - ${TOP_BOTTOM_MARGIN * 2}px)`,
		boxShadow: '0px -4px 20px 0px rgba(0, 0, 0, 0.12)',
		borderRadius: 10,
		margin: '24px 0px 24px 8px',
		width: 309,
	},
	titleContainer: {
		padding: '24px 32px 10px',
		paddingLeft: '18px',
		paddingRight: '21px',
		'& > h3': {
			color: '#242C37',
			fontSize: 24,
			fontWeight: 'bold'
		}
	},
	container: {
		padding: '37px 18px',
		paddingRight: '21px',
		overflowY: 'auto',
		height: '100%',
	},
	itemsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: 'space-between',
		'& a': {
			marginBottom: 36,
			'& p': {
				display: "flex",
				alignItems: "center",
				fontSize: 18,
				lineHeight: '20px',
				fontWeight: 'bold'
			}
		},
		'& svg': {
			fill: theme.palette.primary.main,
			height: 24,
			width: 24,
			marginRight: 16,
			'& path': {
				fill: theme.palette.primary.main,
			}
		},
		'& > div': {
			display: "flex",
			flexDirection: "column",
		},
	},
	logoutLink: {
		marginBottom: '0px !important',
		'& svg': {
			'& path': {
				fill: `${theme.palette.text.primary} !important`,
			}
		}
	},
	arrowCloseIcon: {
		fill: theme.palette.primary.main,
		height: 20,
		width: 20,
	}
}))