import { Grid } from "@mui/material";
import { Pagination as MuiPagination, PaginationItem, PaginationProps as MuiPaginationProps } from "@mui/material";
import { useStyles } from "./Pagination.styles";
import { useIsMobile } from "src/helpers";

export interface PaginationProps {
    /** Current page */
    page: number;
    /** Max records number to display */
    perPage: number;
    /** Current records number */
    total: number;
    /** Current records pages number */
    totalPages: number;
    handlePagination: (event: any, newPage: number) => void;
}

/** Use to show links list to navigate between records */
export function Pagination({ page, perPage, total, totalPages, handlePagination, ...rest }: PaginationProps & MuiPaginationProps) {
    const { classes, cx } = useStyles();
    const isMobile = useIsMobile()

    return (
        <Grid item className={classes.container}>
            <MuiPagination
                count={totalPages}
                page={page}
                onChange={handlePagination}
                siblingCount={isMobile ? 0 : 1}
                className={classes.pagination}
                renderItem={(item) => <PaginationItem className={classes.item} {...item} />}
                {...rest}
            />
        </Grid>
    )
}