import { Button } from "@mui/material";
import { useStyles } from "./styles"
import { HomeOutlined } from "@mui/icons-material";
import { Link } from "src/components";

interface HomeButtonProps {
    isLoading?: boolean
}

export function HomeButton({ isLoading }: HomeButtonProps) {
    const { classes } = useStyles()

    return (
        <Link to="/">
            <Button
                variant="text"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<HomeOutlined />}
                disabled={isLoading}
            >
                Volver al home
            </Button>
        </Link>
    )
}