import { Application, RelatedApplication } from "src/types"
import _ from "lodash"
import { images } from 'src/helpers/images'

export interface MainCardItem {
    image: any
    title: string
    description: string
    linkTitle: string
    linkTo: string
    isExternalLink?: boolean,
    disabled?: boolean,
    onClick?: () => void
    hidden?: boolean
}

interface MainCardItemsParams {
    application?: Application
    isPriority?: boolean
    canContinue?: boolean
    currentSectionLink?: string
    relatedApplication?: RelatedApplication
    hasApplicationParent: boolean
    setCancelApplicationOpen: (open: boolean) => void
    setCoaccreditOpen: (open: boolean) => void
    continueRelatedApplication: (relatedApplicationId: string) => void
    isKredi?: boolean
    hasCreditProposals?: boolean
    organizationSlug?: string
}

export function mainCardItems({
    application,
    isPriority,
    canContinue,
    currentSectionLink,
    relatedApplication,
    hasApplicationParent,
    setCancelApplicationOpen,
    setCoaccreditOpen,
    continueRelatedApplication,
    isKredi,
    hasCreditProposals,
    organizationSlug,
}: MainCardItemsParams): MainCardItem[] {
    const applicationId = application?.id
    const coaccreditValues = getCoaccreditValues(relatedApplication, hasApplicationParent, application)

    return ([
        {
            image: images[organizationSlug!].coaccredited,
            title: 'Coacreditado',
            description: coaccreditValues.description,
            linkTitle: coaccreditValues.linkTitle,
            linkTo: '/',
            disabled: (!Boolean(applicationId) ||
                Boolean(coaccreditValues?.customerName) ||
                hasApplicationParent
            ),
            onClick: () => {
                if (relatedApplication?.related_application_workflow_id && coaccreditValues.isDraft) {
                    continueRelatedApplication(relatedApplication.related_application_workflow_id)
                    return
                }
                setCoaccreditOpen(true)
            }
        },
        {
            image: images[organizationSlug!].documents,
            title: 'Documentos de solicitud',
            description: 'Actualiza y/o comparte tus documentos.',
            linkTitle: 'Ir a documentos',
            linkTo: isPriority && canContinue && currentSectionLink
                ? currentSectionLink
                : `/home/main/${applicationId || ''}/documents`,
            disabled: !Boolean(applicationId)
        },
        {
            hidden: hasApplicationParent || !hasCreditProposals,
            image: images[organizationSlug!].creditProposal,
            title: 'Propuestas de crédito',
            description: 'Conoce los detalles de tus propuestas.',
            linkTitle: 'Ir a propuestas',
            linkTo: `/home/applications/${applicationId || ''}/proposals`,
            disabled: !Boolean(applicationId),
        },
        {
            image: images[organizationSlug!].cancelApplication,
            title: 'Cancelar solicitud',
            description: 'Si no deseas continuar el proceso.',
            linkTitle: 'Cancelar',
            linkTo: '/',
            disabled: !Boolean(applicationId) || !_.isFunction(setCancelApplicationOpen),
            hidden: !isKredi,
            onClick: () => {
                if (setCancelApplicationOpen) {
                    setCancelApplicationOpen(true)
                }
            }
        },
    ])
}

function getCoaccreditValues(relatedApplication?: RelatedApplication, isCoaccredited?: boolean, application?: Application) {
    const { application_workflow_status, customer_name } = relatedApplication || {}
    const isDraft = application_workflow_status === 'draft'

    let description = 'Agrega un coacreditado a tu solicitud.'
    let linkTitle = 'Agregar'

    if (isCoaccredited) {
        description = application?.parent?.customer_name || '-'
        linkTitle = 'Tu solicitud ya está vinculada'
    } else if (isDraft && !customer_name) {
        description = 'Continua con la solicitud en proceso'
        linkTitle = 'Ir a solicitud'
    } else if (relatedApplication?.id) {
        description = relatedApplication?.customer_name || '-'
        linkTitle = 'Ya cuentas con un coacreditado'
    }

    return {
        description,
        linkTitle,
        isDraft,
        customerName: customer_name
    }
}