import { ComponentType } from 'react'
import { Badge, BadgeProps, Box, BoxProps, Typography, TypographyProps } from '@mui/material'
import { ArrowForward as ArrowIcon } from '@mui/icons-material'
import { Link, LinkProps } from '../Link'
import { useStyles } from './Shortcut.styles'

export interface ShortcutProps {
    title: string
    to?: string
    /** If onClick is provided, the component will be a button instead of a link and the `to` prop will be ignored */
    onClick?: () => void
    startIcon?: ComponentType
    disabled?: boolean
    rootProps?: Omit<LinkProps, 'to'>
    titleProps?: TypographyProps
    iconWrapperProps?: BoxProps
    badge?: BadgeProps
}

export function Shortcut({
    startIcon: StartIcon,
    title,
    to = '#',
    onClick,
    disabled,
    rootProps,
    titleProps,
    iconWrapperProps,
    badge
}: ShortcutProps) {
    const { cx, classes } = useStyles()

    return (
        <Link
            {...rootProps}
            to={to}
            onClick={onClick}
            className={cx(
                classes.root,
                rootProps?.className,
                { ['disabled']: disabled }
            )}
        >
            <>
                {StartIcon && (
                    <Box
                        {...iconWrapperProps}
                        className={cx(classes.iconWrapper, iconWrapperProps?.className)}
                    >
                        <StartIcon />
                    </Box>
                )}

                {badge && (
                    <Badge
                        {...badge}
                        className={cx(classes.badge, badge?.className)}
                    />
                )}

                <Typography {...titleProps} className={cx(classes.title, titleProps?.className)}>
                    {title}
                </Typography>

                <ArrowIcon className={classes.arrowIcon} />
            </>
        </Link>
    )
}