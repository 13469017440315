import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { EmptyState, Loading, StagePage, Table } from "src/components";
import { formatDate, isValidId } from "src/helpers";
import { creditAccountStatementsActions } from "src/store/credits/actions";
import { AccountStatementType } from "src/types";

import { getColumns } from "./columns";
import { useStyles } from "./styles";
import _ from "lodash"

interface BankStatementsPageProps {
}

export function BankStatementsPage({ }: BankStatementsPageProps) {
    const { creditId }: any = useParams()
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()
    const columns = getColumns({ classes, downloadPdf })

    const { data: accountsStatements, isLoading } = useSelector(state => state.credits.accountStatements)
    const credit = useSelector(state => state.credits.credits.credit)

    useEffect(() => {
        if (isValidId(creditId)) {
            dispatch(creditAccountStatementsActions.getAll(creditId, {}))
        }
    }, [])

    function downloadPdf(accountStatement: AccountStatementType) {
        const month = _.capitalize(formatDate(accountStatement?.cutoff_date, 'MMMM'))
        const year = formatDate(accountStatement?.cutoff_date, 'YYYY')
        const fileName = `${credit?.internal_id || 'EstadoCuenta'}_${month}_${year}`

        dispatch(creditAccountStatementsActions.downloadByUrl(accountStatement.pdf_url!, fileName))
    }

    return (
        <StagePage
            title='Estados de cuenta'
            breadcrumbs={[
                {
                    name: 'Crédito',
                    to: '/home/credit'
                },
                {
                    name: 'Estados de cuenta',
                },
            ]}
        >
            <Loading
                isLoading={isLoading}
                variant="skeleton"
                skeletonContainerProps={{ className: classes.skeletonsContainer }}
            >
                {(accountsStatements?.length || 0) <= 0 ? (
                    <EmptyState text="No se encontraron registros" className={classes.emptyState} />
                ) : (
                    <Table
                        data={accountsStatements || []}
                        columns={columns}
                    />
                )}
            </Loading>
        </StagePage>
    );
}