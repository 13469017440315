import { tss } from "tss-react/mui";
import { alpha } from "@mui/material";

export const useStyles = tss.create(({ theme }) => ({
    label: {
        fontWeight: 'bold'
    },
    paper: {
        borderRadius: '10px !important',
        backgroundColor: '#fff !important',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        padding: '0px !important',
    },
    container: {
        padding: 8,
        borderRadius: 10,
        maxWidth: '100%',
        width: 185
    },
    sortButton: {
        color: 'black',
        transition: 'background-color 0.3s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
        padding: 8,
        height: 32,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
        '& > div': {
            display: 'flex',
            gap: 8
        },
        '& p': {
            fontSize: 12
        }
    },
    selectedOrder: {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    sortIcon: {
        width: 16,
        height: 16,
        "& path": {
            fill: 'black !important'
        }
    },
    sortIconAsc: {
        transform: 'rotate(180deg)'
    },
}));