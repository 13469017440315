import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        justifyContent: 'left',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    progressCard: {
        width: 180,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
}))