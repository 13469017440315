import { useState } from "react"
import { Divider, Grid, Pagination, PaginationItem, Typography } from "@mui/material"
import { Employment } from "src/types"
import SwipeableViews from 'react-swipeable-views';
import { useStyles } from "./styles";
import { translateSalaryScheme } from "../../../Details/Form/fields";
import { Link } from "src/components";

interface EmploymentMobileCardProps {
    applicationId?: string
    employments?: Employment[]
}

export function EmploymentMobileCard({ applicationId, employments }: EmploymentMobileCardProps) {
    const { classes } = useStyles()
    const [currentIndex, setCurrentIndex] = useState(0)

    return (
        <Grid container>

            <SwipeableViews index={currentIndex} onChangeIndex={index => setCurrentIndex(index)}>
                {employments?.map((employment, index) => (
                    <Grid
                        container
                        key={employment.id}
                        className={classes.card}
                        spacing={2}
                        component={Link}
                        to={`/home/applications/${applicationId}/employments/${employment.id}`}
                    >
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography className={classes.title}> Ingreso {index + 1} </Typography>
                            {employment?.predetermined ? <div className={classes.chip}> Predeterminado </div> : <div />}
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.label}> Actividad económica </Typography>
                            <Typography className={classes.value}> {translateSalaryScheme(employment?.salary_scheme)} </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.label}> Ingreso bruto mensual </Typography>
                            <Typography className={classes.value}> {employment?.before_taxes_salary} </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.label}> Ingreso bruto anual </Typography>
                            <Typography className={classes.value}> {employment?.yearly_before_taxes_salary} </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.label}> Lugar de ingreso </Typography>
                            <Typography className={classes.value}> {employment?.nationality} </Typography>
                        </Grid>
                    </Grid>
                ))}
            </SwipeableViews>

            <Grid item xs={12} className={classes.paginationContainer}>
                <Pagination
                    count={employments?.length}
                    page={currentIndex + 1}
                    onChange={(_, page) => setCurrentIndex(page - 1)}
                    //  siblingCount={isMobile ? 0 : 1}
                    className={classes.pagination}
                    renderItem={(item) => <PaginationItem className={classes.paginationItem} {...item} />}
                    classes={{
                        ul: classes.paginationUl
                    }}
                />
            </Grid>

        </Grid>
    )
}