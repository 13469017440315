import { TableColumnType } from "src/components/Table"
import { PaymentType } from "src/types"
import { formatDate } from "src/helpers"
import { PaymentStatus } from "./Status"
import { formatPaymentAmount } from "src/helpers/payments"

export const paymentColumns: TableColumnType<PaymentType>[] = [
    {
        label: 'Tipo de pago',
        key: 'payment_type',
        render: (value, payment) => payment?.metadata?.['payment_type'] || value || '-'
    },
    {
        label: 'Fecha de creación',
        key: 'created',
        render: value => formatDate((value || 0) * 1000) || '-'
    },
    {
        label: 'Monto',
        key: 'amount_total',
        render: value => formatPaymentAmount(value)
    },
    {
        label: 'Estatus',
        key: 'status',
        render: (value, row, index) => <PaymentStatus index={index} payment={row} />
    },
]