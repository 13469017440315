import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Loading, StagePage } from "src/components"
import { isValidId } from "src/helpers"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions, applicationEvaluatorFieldsActions } from "src/store/application/actions"

import { DocumentList } from "../components"
import { useStyles } from "./styles"

export function ActionableDocumentsPage() {
    const { applicationId, actionableId }: any = useParams()

    const { classes } = useStyles()
    const dispatch = useDispatch()

    const storeApplicationId = useSelector(state => state.applications.application?.id)
    const evaluators = useSelector(state => state.applications.switchableEvaluators?.data || [])
    const isLoadingApplication = useSelector(state => state.applications.isLoading)
    const isLoadingApprovals = useSelector(state => state.applications.switchableEvaluators.isLoading)
    const approvalsMeta = useSelector(state => state.applications.switchableEvaluators.meta)

    const actionable = evaluators.find(e => e.uuid === actionableId)
    const qualifierName = actionable?.qualifiers?.[0]?.name || 'Documentos'

    useEffect(() => {
        if (!isValidId(applicationId) || !isValidId(actionableId)) return

        if (!storeApplicationId || storeApplicationId !== applicationId) {
            dispatch(applicationActions.getApplication(applicationId))
        }

        if (evaluators.length <= 0) {
            dispatch(applicationEvaluatorFieldsActions.getSwitchableEvaluators(
                applicationId,
                { ...approvalsMeta }
            ))
        }
    }, [applicationId, actionableId])

    return (
        <>
            <StagePage
                title="Documentos"
                titleProps={{ className: classes.stageTitle }}
                titleContainerProps={{ className: classes.stageTitleContainer }}
                breadcrumbs={[
                    { name: 'Inicio', to: '/home/main' },
                    { name: 'Documentos', to: `/home/applications/${applicationId}/documents` },
                    { name: qualifierName },
                ]}
            >
                <Loading
                    variant="skeleton"
                    isLoading={isLoadingApplication || isLoadingApprovals}
                >
                    <DocumentList title={qualifierName} actionableId={actionableId} />
                </Loading>
            </StagePage>
        </>
    )
}