import { Card, StagePage, Table } from "src/components";
import { columns } from "./columns";

const data = [
    {
        last_updated: '02/Ene/21',
    },
    {
        last_updated: '03/Dic/21',
    },
    {
        last_updated: '02/Feb/22',
    },
];

interface AmortTableHistoryPageProps {
}

export function AmortTableHistoryPage({ }: AmortTableHistoryPageProps) {
    return (
        <StagePage
            title='Historial de tablas de amortización'
            breadcrumbs={[
                {
                    name: 'Crédito',
                    to: '/home/credit'
                },
                {
                    name: 'Información de mi crédito',
                    to: '/home/credit/details'
                },
                {
                    name: 'Historial de tablas de amortización',
                },
            ]}
        >
            <Table
                data={data}
                columns={columns}
                isLink
                getHref={(row) => '/home/credit/details/history/1'}
            />
        </StagePage>
    );
}