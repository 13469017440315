import CheckIcon from "@mui/icons-material/Check";
import MoreIcon from "@mui/icons-material/MoreVert";
import { ApplicationWorkflowStage } from "src/types";
import { useStyles } from "./styles";
import _ from "lodash";


export interface GroupedApplicationWorkflowStage extends ApplicationWorkflowStage {
    lastSortOrder: number;
    ids: string[];
}

/**
 * Iterate stages and group depending on visibility
 */
export function groupStages(stages: ApplicationWorkflowStage[]): GroupedApplicationWorkflowStage[] {
    const groupedStages: GroupedApplicationWorkflowStage[] = [];

    if (stages.some(stage => stage.visibility === 'user')) {

        stages.forEach(stage => {
            if (stage.visibility == 'user') {
                const index = _.findLastIndex(groupedStages, grouped => grouped.visibility === 'user');
                if (index > -1) {
                    if (groupedStages[index].lastSortOrder === (stage?.sort_order || 0) - 1) {
                        groupedStages[index].lastSortOrder = stage?.sort_order!;
                        groupedStages[index].ids.push(stage.id!)
                    } else {
                        groupedStages.push({
                            ...stage,
                            lastSortOrder: stage.sort_order!,
                            ids: [stage.id!]
                        });
                    }
                }
                else
                    groupedStages.push({
                        ...stage,
                        lastSortOrder: stage.sort_order!,
                        ids: [stage.id!]
                    });

            } else {
                groupedStages.push({
                    ...stage,
                    lastSortOrder: stage.sort_order!,
                    ids: [stage.id!]
                });
            }
        });

        if (groupedStages[groupedStages.length - 1]?.visibility === 'user') {
            groupedStages.pop();
        }

        return groupedStages;

    } else
        // @ts-ignore
        return stages;
}

export function StepIcon({ active, completed, icon, isPrivate }: any) {
    const { classes, cx } = useStyles();
    return (
        <div
            className={cx(classes.iconContainer, {
                [classes.iconCompleted]: completed,
                [classes.iconActive]: !completed && active
            })}
        >
            {completed ? <CheckIcon /> : (isPrivate ? <MoreIcon className={classes.privateIcon} /> : icon)}
            {/* {isPrivate ? <MoreIcon className={classes.privateIcon} /> : (completed ? <CheckIcon /> : icon)} */}
        </div>
    )
}

/**
 * 
 * @param groupedStages Stages grouped by visibility
 * @param currentStage 
 * @returns The current sort order minus the hidden stages
 */
export function getCurrentStep(groupedStages: GroupedApplicationWorkflowStage[] | any[], currentStage: ApplicationWorkflowStage): number {
    let currentOrder = currentStage?.sort_order!;
    let pastStages = groupedStages.filter(stage => stage.sort_order < currentStage.sort_order!)

    if (pastStages?.length > 0) {
        pastStages = pastStages.filter(stage => stage.ids?.length > 1);
    }

    pastStages.forEach(stage => {
        const lastId = stage.ids[stage.ids.length - 1];
        if (lastId === currentStage.id) {
            currentOrder = currentOrder - (stage.ids?.length - 1);
        } else {
            currentOrder = currentOrder - (stage.ids?.filter((id: string) => id != currentStage.id).length - 1);
        }
    });

    return currentOrder;
}