import { useMemo } from 'react'
import { Grid, Zoom } from '@mui/material'
import { Shortcut } from 'src/components'
import { useApplicationStage, useIsKredi } from 'src/helpers'
import { Application } from 'src/types'
import { getShortcuts } from './items'

import { useDispatch, useSelector } from 'react-redux'
import { applicationActions } from 'src/store/application/actions'

interface ShortcutsProps {
    application: Application
    setCancelApplicationOpen: (open: boolean) => void
    setCoaccreditOpen: (open: boolean) => void
}

export function Shortcuts({ application, setCancelApplicationOpen, setCoaccreditOpen }: ShortcutsProps) {
    const dispatch = useDispatch()
    const isKredi = useIsKredi()

    const {
        canContinue,
        currentSectionLink,
        currentStage,
        isPriority
    } = useApplicationStage({ useLastOneApplication: true })

    const organization = useSelector(state => state.organization)
    const relatedApplications = useSelector(state => state.relatedApplications.data || [])
    const creditProposals = useSelector(state => state.creditProposals?.data || [])
    const requiredDocumentsCount = useSelector(state => state.applications.rejectedDocuments?.count || 0)

    const shortcuts = useMemo(() => {
        if (!organization?.slug) {
            return []
        }
        return getShortcuts({
            isPriority,
            application,
            canContinue,
            currentSectionLink,
            creditProposals,
            relatedApplications,
            isKredi,
            requiredDocumentsCount,
            organizationSlug: organization.slug,
            handleCoaccreditOpen,
            setCancelApplicationOpen,
        })
    }, [organization?.slug, application?.id, currentStage?.id, requiredDocumentsCount, relatedApplications])

    function handleCoaccreditOpen() {
        const { related_application_workflow_id, application_workflow_status } = relatedApplications?.[0] || {}

        if (related_application_workflow_id && application_workflow_status === 'draft') {
            dispatch(applicationActions.continueApplication(related_application_workflow_id))
            return
        }

        setCoaccreditOpen(true)
    }

    return (
        <Grid container spacing={3}>
            {shortcuts.map((shortcut, index) => {
                return (
                    <Zoom
                        in
                        key={index}
                        style={{ transitionDelay: `${(index * 100) + 200}ms` }}
                    >
                        <Grid item xs={12} sm={4}>
                            <Shortcut {...shortcut} />
                        </Grid>
                    </Zoom>
                )
            })}
        </Grid>
    )
}