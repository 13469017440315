import { useMemo } from "react"
import { Button, ButtonProps } from "@mui/material"
import { VisibilityOutlined as EyeIcon } from "@mui/icons-material"
import { Upload2Icon } from "src/assets/icons"
import { CustomSwitchableEvaluatorQualifierField } from "src/types"
import { SetDialogFunction } from ".."
import { downloadFiles, getDownloadUrlsFromEvaluatedValues } from "./helpers"

interface ApprovalActionsProps {
    approval?: CustomSwitchableEvaluatorQualifierField
    buttonProps?: ButtonProps
    setDocDialog: SetDialogFunction
}

export function ApprovalActions({ approval, buttonProps, setDocDialog }: ApprovalActionsProps) {
    const { status } = approval || {}

    const mode: 'upload' | 'view' =
        ['finished', 'input_required'].includes(status || '') ? 'view' : 'upload'

    const files = useMemo(() =>
        getDownloadUrlsFromEvaluatedValues(approval?.evaluated_values),
        [approval?.evaluated_values]
    )

    const buildActionText = () => {
        switch (status) {
            default:
                return 'Subir documento'
            case 'failed':
                return 'Subir nuevamente'
            case 'finished':
            case 'input_required':
                return 'Ver documento'
        }
    }

    function handleAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault()
        e.stopPropagation()

        if (mode === 'upload') {
            setDocDialog({ open: true, approval })
            return
        }

        downloadFiles(files)
    }

    return (
        <Button
            {...buttonProps}
            color="primary"
            onClick={handleAction}
            startIcon={mode === 'upload' ? <Upload2Icon /> : <EyeIcon />}
            variant={mode === 'upload' ? 'contained' : 'text'}
            disabled={mode === 'view' && files.length <= 0}
            sx={{
                fontSize: 12,
                lineHeight: '14px',
                letterSpacing: '0.1px',
                padding: '4px 10px',
                minWidth: 142,
                height: 26,
                '& .MuiSvgIcon-root': {
                    width: 16,
                }
            }}
        >
            {buildActionText()}
        </Button>
    )
}