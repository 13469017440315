import { Grid } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { Confetti } from "src/components";
import { useApplicationPusher, useIsMobile } from "src/helpers";
import { getCustomer, getDefaultCustomerEmployment } from "../../../helpers";

import { ApplicationStatusComponent, ConsultantCard } from "../components";
import { NextStepsCard } from "../components/NextStepsCard";
import { useParams } from "react-router-dom";

interface PreapprovedPageProps {
	status?: any;
}

export function PreapprovedPage({ status }: PreapprovedPageProps) {
	const queryParams: any = useParams()
	const { classes, cx } = useStyles();
	const isMobile = useIsMobile()
	const application = useSelector(state => state.applications.application);
	const isLoading = useSelector(state => state.applications.isLoading);
	useApplicationPusher({ getDefaultCustomerEmployment, getCustomer })

	return (
		<>
			<Confetti />

			<Grid
				id="preapproved-hero"
				container
				spacing={isMobile ? 6 : 3}
				className={classes.hero}
			>
				<Grid item xs={12} md={6}>
					<ApplicationStatusComponent
						status={status || queryParams.status}
						isLoading={isLoading}
						application={application}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ConsultantCard applicationOrigin="current" />
				</Grid>

				<Grid item xs={12} className={classes.stagesContainer}>
					<NextStepsCard
						application={application}
						isLoading={isLoading}
					/>
				</Grid>

			</Grid>

		</>
	)
}