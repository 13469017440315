import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useStyles } from "./styles";
import { Loading } from "src/components";
import { useIsMobile } from "src/helpers";

import { useDispatch, useSelector } from "react-redux";
import { applicationEvaluatorFieldsActions } from "src/store/application/actions";

import { DesktopDocumentList, MobileDocumentList } from "src/pages/Home/Documents/components/List/Views";
import { DocDialogParams } from "src/pages/Home/Documents/components";
import { DocumentDialog } from "src/pages/Home/Documents/components/List/DocumentDialog";

interface EmploymentDocumentsProps {
    applicationId: string
    employmentId: string
}

export function EmploymentDocuments({ applicationId, employmentId }: EmploymentDocumentsProps) {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const isLoading = useSelector(state => state.applications.rejectedDocuments.isLoading)

    const approvals = useSelector(state => state.applications.switchableEvaluators?.approvals || [])
        .filter(approval => approval.actionable_id == employmentId && approval?.document_required)

    const [docDialog, setDocDialog] = useState<DocDialogParams>({ open: false })
    const listProps = { setDocDialog, approvals }

    useEffect(() => {
        dispatch(applicationEvaluatorFieldsActions.getSwitchableEvaluators(applicationId, {
            search: {
                uuid_eq: employmentId
            }
        }))
    }, [applicationId])

    return (
        <>
            {docDialog?.approval && (
                <DocumentDialog
                    approval={docDialog.approval!}
                    dialogProps={{
                        open: docDialog.open,
                        onClose: () => setDocDialog({ open: false }),
                    }}
                />
            )}

            <Loading isLoading={isLoading} variant="skeleton" skeletonsNumber={2}>
                <Grid container className={classes.card}>
                    {isMobile ? <MobileDocumentList {...listProps} /> : <DesktopDocumentList {...listProps} />}
                </Grid>
            </Loading>
        </>
    )
}