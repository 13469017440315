export const PAYMENTS_TYPES = {
    SET: 'PAYMENTS/SET',

    CREATE: 'PAYMENTS/CREATE',
    UPDATE: 'PAYMENTS/UPDATE',
    DESTROY: 'PAYMENTS/DESTROY',
    GET_ONE: 'PAYMENTS/GET_ONE',
    SET_ONE: 'PAYMENTS/SET_ONE',
    GET_ALL: 'PAYMENTS/GET_ALL',
    SET_ALL: 'PAYMENTS/SET_ALL',

    LINK: 'PAYMENTS/LINK',
}