import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { StageStepper } from "src/pages/Summary/Main/StageStepper";
import { useStyles } from "./styles";
import _ from "lodash";

import { ContainerCarrousel, Loading } from "src/components";
import { Application } from "src/types";

interface NextStepsCardProps {
    application: Application;
    isLoading: boolean;
}

export function NextStepsCard({ application, isLoading }: NextStepsCardProps) {
    const { classes, cx } = useStyles();
    const currentApplicationWorkflowStage = application.applicationWorkflowStages?.find(stage => stage.stage_id === application.current_stage?.id)
    const [stageWrapperRef, setStageWrapperRef] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
        if (stageWrapperRef) {
            scrollToCurrentStage()
        }
    }, [stageWrapperRef])

    function scrollToCurrentStage() {
        if (stageWrapperRef) {
            if (stageWrapperRef.scrollWidth <= stageWrapperRef.clientWidth) {
                return
            }

            const currentStageElement = stageWrapperRef.querySelector(`.currentStepRoot`) as HTMLElement

            if (currentStageElement) {
                const stageWrapperRect = stageWrapperRef.getBoundingClientRect()
                const currentStageRect = currentStageElement.getBoundingClientRect()

                stageWrapperRef.scrollLeft = currentStageRect.left - stageWrapperRect.left
                stageWrapperRef.scrollLeft -= (stageWrapperRect.width / 2) - (currentStageRect.width / 2)
            }
        }
    }

    return (
        <Box className={cx(classes.root)}>
            <Typography className={classes.title}> Tu proceso de solicitud </Typography>

            <Loading
                isLoading={isLoading}
                variant="skeleton"
                skeletonsNumber={1}
                skeletonProps={{
                    style: {
                        height: 128
                    }
                }}
            >
                <ContainerCarrousel onRef={setStageWrapperRef} rgbaGradient="#FAFAFC">
                    <StageStepper
                        activeStep={currentApplicationWorkflowStage?.sort_order || 0}
                        stages={_.sortBy(application?.applicationWorkflowStages || [], 'sort_order')}
                        currentStage={currentApplicationWorkflowStage}
                        applicationStatus={application?.status}
                        stepperProps={{
                            orientation: 'horizontal'
                        }}
                        stepConnectorProps={{
                            className: classes.stepperConnector
                        }}
                    />
                </ContainerCarrousel>
            </Loading>

        </Box>
    )
}