import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    container: {
        textAlign: 'center',
        justifyContent: 'center',
    },
    buttonLink: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    button: {
        minHeight: 36,
        minWidth: 250,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    title: {
        fontSize: 34,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12
    }
}));
