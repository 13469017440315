import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    row: {
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #CFD5E2',
        '& > p': {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.25px"
        },
        '&.totalRow > p': {
            fontSize: "20px",
            lineHeight: "26px",
            letterSpacing: "0.15px",
            fontWeight: 700
        }
    },
}))