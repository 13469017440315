import { ReactNode } from 'react'
import { Box, BoxProps, Button } from '@mui/material'
import { Link, LinkProps } from '../Link'
import { useStyles } from './Alert.styles'

export interface AlertProps {
    children: ReactNode
    /** @default `contained` */
    variant?: 'contained' | 'outlined'
    rootProps?: BoxProps
    childrenProps?: BoxProps
    customAction?: ReactNode
    actionProps?: {
        title: string
        linkProps?: Omit<LinkProps, 'children'>
    }
}

export function Alert({
    variant = 'contained',
    rootProps,
    childrenProps,
    children,
    actionProps,
    customAction
}: AlertProps) {
    const { classes, cx } = useStyles()

    return (
        <Box
            {...rootProps}
            className={cx(
                classes.root,
                variant,
                rootProps?.className,
            )}
        >
            <Box
                textAlign={actionProps || customAction ? 'left' : 'center'}
                {...childrenProps}
                className={cx(classes.children, childrenProps?.className)}
            >
                {children}
            </Box>

            {(actionProps && !customAction) && (
                <Button
                    variant={variant}
                    color='primary'
                    className={cx(
                        classes.actionButton,
                        variant,
                        actionProps.linkProps?.className,
                    )}
                >
                    <Link
                        {...actionProps?.linkProps}
                        to={actionProps.linkProps?.to || '#'}
                        style={{ color: 'inherit', height: '100%', width: '100%' }}
                    >
                        {actionProps.title}
                    </Link>
                </Button>
            )}

            {customAction && customAction}
        </Box>
    )
}