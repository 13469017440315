import CoacreditadoImg from 'src/assets/img/coacreditado_circle.svg'
import DocumentsImg from 'src/assets/img/home_documents.svg'
import CreditProposalImg from 'src/assets/img/home_credit_proposal.svg'
import CancelApplicationImg from 'src/assets/img/home_cancel_application.svg'
import PerfilIcon from "src/assets/img/home/documents/perfil.svg"
import DocumentImage from "src/assets/img/home/documents/document.svg"
import HomeIcon from "src/assets/img/home/documents/home.svg"
import ComprarTuCasaImg from "src/assets/img/inicio-comprar-tu-casa.svg"
import ProfileAddressImg from 'src/assets/img/profile_address.svg';
import ProfileContactImg from 'src/assets/img/profile_contact.svg';
import ProfilePersonalImg from 'src/assets/img/profile_personal.svg';
import ApplicationApprovedImg from 'src/assets/img/application_approved.svg'
import ApplicationRejectedImg from 'src/assets/img/application_rejected.svg'
import ApplicationInReviewImg from 'src/assets/img/application_in_review.svg'

import Documents2Img from "src/assets/img/home/main_sc_documents.svg"
import IncomeImg from "src/assets/img/home/main_sc_income.svg"
import CoaccreditedImg from "src/assets/img/home/main_sc_coaccredit.svg"
import CreditProposal2Img from "src/assets/img/home/main_sc_creditproposal.svg"
import PaymentsImg from "src/assets/img/home/main_sc_payments.svg"
import EmptyStateIcon from "src/assets/img/empty_state.svg";

import StartApplicationImg from 'src/assets/img/bancoppel/star_application.svg'
import BancoppelCoacreditadoImg from 'src/assets/img/bancoppel/coaccredited.svg'
import BancoppelDocumentsImg from 'src/assets/img/bancoppel/documents.svg'
import BancoppelCreditProposalImg from 'src/assets/img/bancoppel/credit_proposal.svg'
import BancoppelCreditProposal2Img from 'src/assets/img/bancoppel/credit_proposal2.svg'
import BancoppelPersonalDOcumentsImg from 'src/assets/img/bancoppel/personal_documents.svg'
import BancoppelEmploymentDocumentsImg from 'src/assets/img/bancoppel/employment_documents.svg'
import BancoppelIncomesImg from 'src/assets/img/bancoppel/incomes.svg'
import BancoppelProperyDocumentsImg from 'src/assets/img/bancoppel/property_documents.svg'
import BancoppelPaymentsImg from 'src/assets/img/bancoppel/payments.svg'
import BancoppelProfileAddressImg from 'src/assets/img/bancoppel/address.svg'
import BancoppelProfileContactImg from 'src/assets/img/bancoppel/contact.svg'
import BancoppelProfilePersonalImg from 'src/assets/img/bancoppel/personal_data.svg';
import BancoppelApplicationApprovedImg from 'src/assets/img/bancoppel/application_approved.svg'
import BancoppelApplicationRejectedImg from 'src/assets/img/bancoppel/application_rejected.svg'
import BancoppelApplicationInReviewImg from 'src/assets/img/bancoppel/application_in_review.svg'
import BancoppelEmptyStateIcon from "src/assets/img/bancoppel/letter.svg";

export const images: {
    [key: string]: {
        startApplication: any,
        coaccredited: any,
        documents: any,
        creditProposal: any,
        cancelApplication?: any,
        personalDocuments: any,
        employmentDocuments: any
        propertyDocuments?: any,
        profile: {
            personal: any,
            contact: any,
            address: any
        },
        applicationStatus: {
            approved: any,
            rejected: any,
            in_review: any,
        },
        home: {
            // Shortcuts images
            scDocuments: any,
            scIncome: any,
            scCoaccedited: any,
            scCreditProposal: any,
            scPayments: any
        },
        docs: {
            empty: any,
        }
    }
} = {
    app: {
        startApplication: ComprarTuCasaImg,
        coaccredited: CoacreditadoImg,
        documents: DocumentsImg,
        creditProposal: CreditProposalImg,
        cancelApplication: CancelApplicationImg,
        personalDocuments: PerfilIcon,
        employmentDocuments: DocumentImage,
        propertyDocuments: HomeIcon,
        profile: {
            personal: ProfilePersonalImg,
            contact: ProfileContactImg,
            address: ProfileAddressImg
        },
        applicationStatus: {
            approved: ApplicationApprovedImg,
            rejected: ApplicationRejectedImg,
            in_review: ApplicationInReviewImg
        },
        home: {
            scDocuments: Documents2Img,
            scIncome: IncomeImg,
            scCoaccedited: CoaccreditedImg,
            scCreditProposal: CreditProposal2Img,
            scPayments: PaymentsImg
        },
        docs: {
            empty: EmptyStateIcon,
        }
    },
    bancoppel: {
        startApplication: StartApplicationImg,
        coaccredited: BancoppelCoacreditadoImg,
        documents: BancoppelDocumentsImg,
        creditProposal: BancoppelCreditProposalImg,
        personalDocuments: BancoppelPersonalDOcumentsImg,
        employmentDocuments: BancoppelEmploymentDocumentsImg,
        propertyDocuments: BancoppelProperyDocumentsImg,
        profile: {
            personal: BancoppelProfilePersonalImg,
            contact: BancoppelProfileContactImg,
            address: BancoppelProfileAddressImg
        },
        applicationStatus: {
            approved: BancoppelApplicationApprovedImg,
            rejected: BancoppelApplicationRejectedImg,
            in_review: BancoppelApplicationInReviewImg
        },
        home: {
            scDocuments: BancoppelDocumentsImg,
            scIncome: BancoppelIncomesImg,
            scCoaccedited: BancoppelCoacreditadoImg,
            scCreditProposal: BancoppelCreditProposal2Img,
            scPayments: BancoppelPaymentsImg
        },
        docs: {
            empty: BancoppelEmptyStateIcon,
        }
    }
}