import { call, put, takeLatest, debounce } from "redux-saga/effects";
import { sagaWrapper } from "src/helpers";
import { ApplicationService, Service } from "src/services";
import { Action, Response } from "src/types";
import { APPLICATION_REJECTED_DOCUMENTS } from "./types";
import { applicationRejectedDocuments } from "./actions";
import _ from "lodash";

const scoringCustomerService = new Service('customer', { api: 'scoring', disableNamespace: true })

function* getRejectedDocuments({ payload }: Action) {
    const response: Response = yield call(ApplicationService.allPath, `${payload.applicationId}/documents`, {
        search: {
            'status_not_in': ['error', 'not_applicable', 'mannual_not_applicable', 'running'],
            ...payload.options?.search
        },
    });
    yield put(applicationRejectedDocuments.setAll(response.data));
}

function* getRejectedDocumentsDelayed({ payload }: Action) {
    const response: Response = yield call(ApplicationService.allPath, `${payload.applicationId}/documents`, {
        search: {
            'status_not_in': ['error', 'not_applicable', 'mannual_not_applicable', 'running']
        }
    });
    yield put(applicationRejectedDocuments.setAll(response.data));
}

function* hasAny({ payload }: Action) {
    const response: { any: boolean } = yield call(scoringCustomerService.allPath, `rejected_documents/${payload.applicationId}/any`, {}, false)
    yield put(applicationRejectedDocuments.setHasAny(response.any))
}

function* getRequiredCount({ payload }: Action) {
    const response: { count?: number } = yield call(
        scoringCustomerService.findOne,
        `documents/require_attention/${payload.applicationId}/count`,
        false,
        true
    );
    yield put(applicationRejectedDocuments.setRequiredCount(response?.count));
}

export function* applicationRejectedDocumentsSagas() {
    yield takeLatest(APPLICATION_REJECTED_DOCUMENTS.GET_ALL, sagaWrapper(getRejectedDocuments, applicationRejectedDocuments.setAll()));
    yield debounce(4000, APPLICATION_REJECTED_DOCUMENTS.GET_ALL_DELAYED, sagaWrapper(getRejectedDocumentsDelayed, applicationRejectedDocuments.setAll()));
    yield takeLatest(APPLICATION_REJECTED_DOCUMENTS.HAS_ANY, sagaWrapper(hasAny))
    yield takeLatest(APPLICATION_REJECTED_DOCUMENTS.GET_REQUIRED_COUNT, sagaWrapper(getRequiredCount, applicationRejectedDocuments.setRequiredCount()))
}