import { StepConnector as MuiStepConnector, alpha } from "@mui/material";
import { tss, withStyles } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    iconContainer: {
        height: 16,
        width: 16,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#B4BAC3',
        border: '1px solid #B4BAC3',
        fontWeight: 'bold',
        fontSize: 16,
        '& > svg': {
            fill: '#fff',
            height: 14,
            width: 14
        },
    },
    iconCompleted: {
        background: theme.palette.primary.main,
        color: '#fff',
        border: '1px solid ' + theme.palette.primary.main,
        '& path': {
            fill: '#fff !important',
            color: '#fff !important'
        }
    },
    iconActive: {
        color: theme.palette.primary.main,
        border: '1px solid #B4BAC3',
    },
    label: {
        fontWeight: '600 !important',
        color: '#000',
        fontSize: 14,
        '& > span': {
            color: 'inherit !important',
            fontSize: 'inherit !important',
            fontWeight: 'inherit !important',
        }
    },
    stepper: {
        paddingLeft: 0,
        paddingRight: 0,
        '& .MuiStepLabel-completed': {
            textDecoration: 'line-through',
            color: theme.palette.primary.main + '!important'
        },
        '& .MuiStepLabel-labelContainer': {
            paddingLeft: 8,
            paddingRight: 8,
            width: 'auto'
        },
        '& $currentStep .MuiStepLabel-labelContainer': {
            color: '#242C37 !important',
            backgroundColor: alpha(theme.palette.primary.main, .08),
            borderRadius: 24,
        }
    },
    currentStep: {
        color: theme.palette.primary.main
    },
    privateIcon: {
        '& svg': {
            height: 18,
            width: 18
        },
        '& path': {
            fill: '#8892A0',
            color: '#8892A0'
        }
    },
    completed: {
        color: `${theme.palette.primary.main} !important`,
    },
    customLabel: {
        minWidth: 112
    }
}));

export const StepConnector = withStyles(
    MuiStepConnector,
    theme => ({
        alternativeLabel: {
            top: 8,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        active: {
            '& $line': {
                borderColor: theme.palette.primary.main,
            },
        },
        completed: {
            '& $line': {
                borderColor: theme.palette.primary.main,
            },
        },
        line: {
            borderColor: alpha(theme.palette.primary.main, .2),
            borderTopWidth: 2,
            borderRadius: 1,
        },
        vertical: {
            padding: '0px 0',
            margin: '-2px 0px -2px 8px',
        },
    }));