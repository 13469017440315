import { Topbar } from "./Topbar"
import { getRoutes } from "src/routes"
import { useStyles } from "./styles"
import { Routes, Snackbar } from "src/components"
import { cookies, getCookieName } from "src/helpers"
import { NotFound } from "src/pages"
import { MessageDialog } from "src/components"

export function Layout() {
    const { classes } = useStyles()
    return (
        <div className={classes.root}>

            {window.location.pathname !== '/expired-link' && <Topbar />}

            <div className={classes.content}>
                <Routes
                    notFoundPage={NotFound}
                    routes={getRoutes({
                        isAuth: Boolean(cookies.get(getCookieName())),
                        hideAccountCreation: true// loginConfig?.disable_account_creation
                    })}
                />
            </div>

            <Snackbar />

            <MessageDialog />
        </div>
    )
}