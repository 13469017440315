import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    stageTitle: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    stageTitleContainer: {
        paddingBottom: 40,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 48,
        },
    },
}))