import { images } from "src/helpers/images";
import { Organization } from "src/types";

export function cardItems(organization: Organization): {
	image: any
	title: string
	description: string
	linkTitle: string
	linkTo: string
	isExternalLink?: boolean,
	disabled?: boolean,
	onClick?: () => void
	hidden?: boolean
}[] {
	return ([
		{
			image: images[organization.slug!].profile?.personal,
			title: 'Datos personales',
			description: 'Consulta tus datos personales.',
			linkTitle: 'Ir a datos',
			linkTo: '/home/profile/personal-data'
		},
		{
			image: images[organization.slug!].profile?.contact,
			title: 'Contacto',
			description: 'Consulta tu información de contacto.',
			linkTitle: 'Ir a contacto',
			linkTo: '/home/profile/contact-data'
		},
		{
			image: images[organization.slug!].profile?.address,
			title: 'Direcciones',
			description: 'Edita o agrega una dirección',
			linkTitle: 'Ir a direcciones',
			linkTo: '/home/profile/addresses'
		},
		// {
		// 	image: ProfileEmploymentsImg,
		// 	title: 'Empleos',
		// 	description: 'Edita o agrega un empleo.',
		// 	linkTitle: 'Ir a empleos',
		// 	linkTo: '/home/profile/employments'
		// },
	]);
}