import { StagePage } from "src/components";
import { EmploymentsTable } from "./Table";
import { Link, useParams } from "react-router-dom";
import { AddIcon } from "src/assets/icons/actions";
import { Button, Grid, Typography } from "@mui/material";
import { useIsMobile } from "src/helpers";
import { useStyles } from "./styles"

export function EmploymentsListPage() {
    const { applicationId }: any = useParams()
    const isMobile = useIsMobile()
    const { classes } = useStyles()

    const addButton = (
        <Link to={`/home/applications/${applicationId}/employments/new`}>
            <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
                fullWidth={isMobile}
                className={classes.button}
            >
                Agregar ingresos
            </Button>
        </Link>
    )

    return (
        <StagePage
            title="Ingresos"
            breadcrumbs={[
                { name: 'Inicio', to: '/' },
                { name: 'Ingresos' }
            ]}
            action={!isMobile && addButton}
        >
            <Grid container spacing={3}>
                {isMobile && (
                    <Grid item xs={12}>
                        {addButton}
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.title}> Ingresos guardados </Typography>
                </Grid>

                <Grid item xs={12}>
                    <EmploymentsTable applicationId={applicationId} />
                </Grid>
            </Grid>
        </StagePage>
    )
}