import { alpha } from "@mui/material";
import { DESKTOP_TOOLBAR_HEIGHT, MOBILE_TOOLBAR_HEIGHT } from "src/layout/Topbar";
import { tss } from "tss-react/mui";

export const APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH = 320;

export const useStyles = tss.create(({ theme }) => ({
	sidebar: {
		width: APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH,
		background: '#FAFAFC',
		height: '100%',
		paddingTop: 48
	},
	sidebarContent: {
		maxHeight: '100%',
		overflowY: 'auto'
	},
	breadcrumbs: {
		paddingTop: 68,
		paddingLeft: 37,
		'& a': {
			fontSize: 12,
			fontWeight: 500,
			color: '#242C37'
		},
		'& p': {
			fontSize: 12,
			fontWeight: 500,
			color: theme.palette.primary.main
		}
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#242C37',
		paddingBottom: 16,
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			fontSize: 32
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			fontSize: 14,
			overflow: "hidden",
			display: "block",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		}
	},
	item: {
		width: '100%',
		minHeight: 40,
		padding: '8px 18px 8px 37px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
		'& p': {
			color: '#242C37',
			fontSize: 16,
			fontWeight: 400,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 16px'
		}
	},
	subItem: {
		width: '100%',
		height: 40,
		padding: '0px 18px 0px 86px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
		'& p': {
			color: '#77808C',
			fontSize: 16,
			fontWeight: 400,
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 16px'
		}
	},
	icon: {
		height: 16,
		width: 16,
		marginRight: 8,
	},
	itemIconContainer: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			fill: '#B4BAC3',
			height: 16,
			width: 16,
			marginRight: 8,
		},
		'& > svg path': {
			fill: '#B4BAC3',
		},
	},
	statusIconContainer: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			height: 18,
			width: 18,
			marginRight: 8,
		}
	},
	selectedItem: {
		background: alpha(theme.palette.primary.main, .1) + ' !important',
		borderLeft: '4px solid ' + theme.palette.primary.main,
		paddingLeft: '33px',
		'& svg': {
			fill: theme.palette.primary.main + ' !important',
		},
		'& path': {
			fill: theme.palette.primary.main + ' !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 13px'
		}
	},
	selectedSubitem: {
		background: 'rgba(117, 139, 183, 0.1)',
		'& p': {
			color: '#242C37 !important',
		}
	},
	help: {
		fontSize: 16,
		fontWeight: 500,
		width: '100%',
		textAlign: 'center',
		lineHeight: 1.5,
		paddingBottom: 16,
		'& span': {
			color: theme.palette.primary.main,
			textDecoration: 'underline'
		}
	},
	appbar: {
		background: '#fff',
		height: 40,
		// boxShadow: '0px 2px 10px rgba(57, 74, 97, 0.12)',
		top: DESKTOP_TOOLBAR_HEIGHT,
		[theme.breakpoints.down('sm')]: {
			top: MOBILE_TOOLBAR_HEIGHT
		}
	},
	toolbar: {
		height: 40,
		display: 'flex',
		alignItems: 'center',
		zIndex: 9000,
		padding: '8px 16px',
		justifyContent: 'space-between',
		position: 'relative',
		background: alpha(theme.palette.primary.main, .1),
		'& > svg': {
			fill: '#242C37'
		},
	},
	toolbarContent: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '90px',
		width: '100%',
		top: 0,
		left: 0,
		position: 'fixed',
		background: '#fff',
		zIndex: 900,
		boxShadow: '0px 9px 46px rgba(57, 74, 97, 0.12)'
	},
	toolbarContentHidde: {
		display: 'none',
	},
	overlay: {
		position: 'fixed',
		width: '100%',
		height: '100%',
		zIndex: 800,
		'webkit-tap-highlight-color': 'transparent',
		backgroundColor: 'transparent',
	},
	expandIcon: {
		cursor: 'pointer'
	},
	expandIconOpen: {
		transform: 'rotateX(180deg)'
	},
	dividerContainer: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: '100%',
		padding: '0px 16px'
	},
	disabledText: {
		color: '#B4BAC3 !important'
	},
	hover: {
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.08)'
		}
	},
	circle: {
		strokeLinecap: 'round',
	},
	progressAndTitle: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		maxWidth: "90%"
	},
	progressLineDesktop: {
		[theme.breakpoints.up('md')]: {
			top: DESKTOP_TOOLBAR_HEIGHT,
			width: `calc(100% - ${APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH}px)`,
			marginLeft: APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH,
		}
	},
	number: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.light,
	},
	progressLineContainer: {
		marginLeft: APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH,
		width: '100%',
		position: 'fixed',
		top: 96,
		left: 0,
		zIndex: 1199,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
		[theme.breakpoints.between('sm', 'md')]: {
			marginLeft: 0,
			top: DESKTOP_TOOLBAR_HEIGHT,
		}
	},
	progressLine: {
		height: 4,
		background: theme.palette.primary.main,
		transition: theme.transitions.create(['width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	hideOnDesktop: {
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	sectionNumber: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.primary.light,
	},
}));