import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    root: {
        position: 'relative',
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 16,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        }
    },
    stepperConnector: {
        minWidth: 20,
        '& .MuiStepConnector-lineHorizontal': {
            borderColor: '#CFD5E2',
        },
    },
}));
