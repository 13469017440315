import { TableColumnType } from "src/components";
import { formatPaymentAmount } from "src/helpers/payments";
import { SessionLineItemType } from "src/types";

export const paymentItemColumns: TableColumnType<SessionLineItemType>[] = [
    {
        label: 'Concepto',
        key: 'description'
    },
    {
        label: 'Monto',
        key: 'amount_subtotal',
        render: value => formatPaymentAmount(value)
    },
]