import { ComponentType, Fragment } from "react"
import { Badge, BadgeProps, Chip, ChipProps, Grid, GridProps, Typography } from "@mui/material"
import { useStyles } from "./ItemCard.styles"

export interface ItemCardProps {
    Icon: ComponentType,
    title: string,
    description: string,
    actionElement?: JSX.Element,
    rootGridProps?: GridProps,
    actionElementProps?: GridProps
    badgeProps?: BadgeProps
    chipProps?: ChipProps
}

export function ItemCard({ Icon, title, description, actionElement, rootGridProps, actionElementProps, badgeProps, chipProps }: ItemCardProps) {
    const { classes, cx } = useStyles()

    const { className: actionElementClassName, ...restActionElementProps } = actionElementProps || {}
    const { className: chipClassName, ...restChipProps } = chipProps || {}

    const Wrapper = badgeProps ? Badge : Fragment

    return (
        <Grid container {...rootGridProps}>
            <Wrapper className={classes.wrapper} {...badgeProps}>
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.iconContainer}>
                        <Icon />

                        {chipProps && (
                            <Chip
                                color="default"
                                className={cx(classes.chip, chipClassName)}
                                {...restChipProps}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography variant="subtitle1" className={classes.description}>
                            {description}
                        </Typography>
                    </Grid>

                    {!!actionElement && (
                        <Grid item xs={12} className={cx(classes.actionContainer, actionElementClassName)} {...restActionElementProps}>
                            {actionElement}
                        </Grid>
                    )}
                </Grid>
            </Wrapper>
        </Grid>
    )
}