import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	values: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 0
	},
	input: {
		fontWeight: 'bold',
		color: '#242C37',
		fontSize: 14,
	},
	value: {
		fontSize: 14,
		// letterSpacing: '0.5px',
		color: '#8892A0',
		marginTop: 4,

	},
	fileName: {
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	fileContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		"&:hover": {
			textDecoration: 'underline',
			cursor: "pointer"
		}
	}

}));