import { alpha } from "@mui/material"
import { tss } from "tss-react/mui"

export const useStyles = tss
    .withNestedSelectors<"message" | "icon">()
    .create(({ theme, classes }) => ({
        root: {
            height: 40,
            padding: 10,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            '&.success': {
                border: `1px solid #1DAA6C`,
                backgroundColor: 'E5F4EC',
                [`& .${classes.message}, & .${classes.icon}`]: {
                    color: '#1DAA6C'
                },
            },
            '&.warning': {
                border: `1px solid #B4BAC3`,
                backgroundColor: alpha('#B4BAC3', 0.08),
                [`& .${classes.message}, & .${classes.icon}`]: {
                    color: '#8892A0'
                },
            },
            '&.error': {
                border: `1px solid #F44336`,
                backgroundColor: '#FFF1F1',
                [`& .${classes.message}`]: {
                    color: '#F44336'
                },
                [`& .${classes.icon} svg path`]: {
                    // Close icon not has fill, only stroke
                    stroke: 'currentColor'
                },
            },
        },
        message: {
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '0.1px',
            padding: 0
        },
        icon: {
            padding: 0,
            '& svg': {
                width: 16,
                height: 16,
                color: 'inherit',
                '& path': {
                    fill: 'currentColor',
                }
            }
        },
        children: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 6,
            color: '#8892A0',
            fontSize: 14
        }
    }))