import { tss } from "tss-react/mui"
import { SIDEBAR_PADDING_TOP } from "../Sidebar/Sidebar.styles"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        width: '100%'
    },
    container: {
        maxWidth: 1440,
        padding: `${SIDEBAR_PADDING_TOP}px 24px`,
        [theme.breakpoints.down('md')]: {
            padding: '24px 16px'
        }
    },
    containerApplication: {
        [theme.breakpoints.down('md')]: {
            padding: '80px 16px'
        }
    },
    name: {
        color: theme.palette.primary.main,
        fontSize: 34,
        fontWeight: 'bold',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            paddingBottom: 24,
        }
    },
    nameApplication: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            paddingBottom: 0,
        }
    },
    description: {
        '& p': {
            fontSize: 16
        },
        '& .mde-preview-content': {
            paddingLeft: 0,
            paddingBottom: 32,
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 0
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& p': {
                fontSize: 14
            },
        },
    },
    divider: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: 16,

        }
    },
    content: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    titleContainer: {
        paddingBottom: 32,
    }
}));
