import { TableColumnType } from "src/components";
import { currencyString, translateEnumAnswer } from "src/helpers";
import { Employment } from "src/types";
import { translateSalaryScheme } from "../../Details/Form/fields";

export const employmentColumns: TableColumnType<Employment>[] = [
    {
        label: 'Actividad económica',
        key: 'salary_scheme',
        render: value => translateSalaryScheme(value),
    },
    {
        label: 'Ingreso bruto mensual',
        key: 'before_taxes_salary',
        render: value => currencyString(value),
    },
    {
        label: 'Ingreso bruto anual',
        key: 'yearly_before_taxes_salary',
        render: value => currencyString(value),
    },
    {
        label: 'Lugar de ingreso',
        key: 'nationality',
    },
    {
        label: '',
        key: 'predetermined',
    },
]