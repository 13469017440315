import { lighten } from "@mui/material"
import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        position: 'relative',
    },
    wrapper: {
        overflow: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
}))