import { Grid, Typography, GridProps } from "@mui/material";
import { ComponentType, ReactNode } from "react";
import EmptyStateIcon from "src/assets/img/empty_state.svg";
import EmptyStateWarningIcon from "src/assets/img/empty_state_warning.svg";
import { useStyles } from "./EmptyState.styles";

export interface EmptyStateProps extends GridProps {
    text?: string
    secondaryText?: string
    children?: ReactNode | ReactNode[]
    containerProps?: GridProps
    /** If variant is 'warning' show a magnifying glass with a warning icon inside  */
    variant?: 'standard' | 'warning'
    size?: 'medium' | 'large' | 'small'
    customIcon?: ComponentType
}

/** Use to show a message and a magnifying glass icon to say there are no records */
export function EmptyState({
    text,
    children,
    className,
    containerProps,
    variant = 'standard',
    secondaryText,
    size = 'large',
    customIcon: CustomIcon,
    ...rest
}: EmptyStateProps) {
    const { classes, cx } = useStyles();

    const Icon = () => {
        if (CustomIcon) return <CustomIcon />
        if (variant === 'warning') {
            //@ts-ignore 
            return <EmptyStateWarningIcon className={classes[`${size}Img`]} />
        }
        //@ts-ignore 
        return <EmptyStateIcon className={classes[`${size}Img`]} />
    }

    return (
        <Grid
            container
            justifyContent="center"
            className={cx(classes.container, className)}
            {...rest}
        >
            <Grid item xs={12} className={classes.iconContainer}>
                <Icon />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    //@ts-ignore
                    className={cx(classes.text, classes[`${size}Text`])}
                    dangerouslySetInnerHTML={{ __html: text || '' }}
                />
                {secondaryText && (
                    <Typography
                        className={classes.secondaryText}
                        dangerouslySetInnerHTML={{ __html: secondaryText }}
                    />
                )}
            </Grid>
            {children && (
                <Grid item xs={12} className={classes.children} {...containerProps}>
                    {children}
                </Grid>
            )}
        </Grid>
    )
}