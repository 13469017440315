import { BankStatementInput as KrediBankStatementInput } from "@kredimx/form-builder"
import { ApplicationService } from "src/services"
import { UploadComponentProps } from ".."

import { providersActions } from "src/store/providers/actions"
import { snackbarActions } from "src/store/snackbar/actions"

import _ from "lodash"

export function BankStatementInput({
    onUpload,
    fieldKey,
    approval,
    application,
    customer,
    user,
    actionable,
    approvalId,
    dispatch,
    hideLabel,
    isUploading
}: UploadComponentProps) {
    return (
        <KrediBankStatementInput
            {...approval?.field}
            //@ts-ignore
            label={hideLabel ? '' : approval?.field?.label}
            //@ts-ignore
            name={fieldKey}
            config={{
                ...approval?.field?.config,
                comments: approval?.comments,
                rejectedReasons: approval?.verification_reason?.rejected,
                requestReasons: approval?.notes?.map((note) => note?.note_text || '')
            }}
            actionableId={actionable?.id}
            actionableType={actionable?.type}
            approval_id={approvalId}
            application={application}
            customer={customer}
            user={user}
            userType="Customer"
            hidePreviousFile
            onChangeCurrentAttachment={onUpload}
            isCustomUploading={isUploading}
            itemProps={{
                md: 12
            }}
            upsert={data => {
                dispatch(providersActions.krediUpsert(data))
            }}
            onError={details => {
                dispatch(snackbarActions.add('error', 'Lo sentimos, ocurrió un error al procesar tu documento'))
            }}
            uploadAttachment={async (attachment: any, application: any, id: string) => {
                return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment)
            }}
        />
    )
}