import { Avatar, Box, BoxProps, Divider, IconButton, Typography } from "@mui/material"
import { LogoutIcon } from "src/assets/icons"
import { Customer, Organization } from "src/types"
import { getFullName, initialsFor } from "src/helpers"
import { useStyles } from "./styles"

interface CustomerAvatarProps {
    organization?: Organization
    customer?: Customer
    logout: () => void
    wrapperProps?: BoxProps
}

export function CustomerAvatar({ organization, customer, wrapperProps, logout }: CustomerAvatarProps) {
    const { classes } = useStyles(organization?.theme)()
    return (
        <Box
            {...wrapperProps}
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={'24px'}
        >
            <Box display='flex' alignItems='center'>
                <Avatar className={classes.avatar}>
                    <Typography className={classes.text}>
                        {initialsFor(getFullName(customer)) || '🙂'}
                    </Typography>
                </Avatar>

                <Typography className={classes.customerNameText}>
                    {`${customer?.first_name || ''} ${customer?.last_name || ''}`}
                </Typography>
            </Box>

            <Divider orientation="vertical" className={classes.divider} />

            <IconButton className={classes.logoutButton} onClick={logout}>
                <LogoutIcon />
            </IconButton>
        </Box>
    )
}