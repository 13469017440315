import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    title: {
        fontSize: 20,
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center'
        }
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24
        }
    }
}))