import AppStatusDefaultImg from 'src/assets/img/application_status_solicita.svg';
import InReviewIconImg from 'src/assets/img/application_in_review.svg'
import PersonInReviewIconImg from 'src/assets/img/application_person_in_review.svg'
import ApprovedImg from 'src/assets/img/application_approved2.svg'
import RejectedImg from 'src/assets/img/application_rejected2.svg'
import PreapprovedImg from 'src/assets/img/application_preapproved.svg'
import CancelledImg from 'src/assets/img/application_cancelled2.svg'
import HomeImg from 'src/assets/img/home_casa.svg'
import HomeMobileImg from 'src/assets/img/home_casa_mobile.svg'
import HomeHasCreditImg from 'src/assets/img/home_has_credit_whands.svg'
import { Application, ApplicationWorkflowStage } from 'src/types';
import { STAGE_STATUS } from 'src/helpers';

export interface ApplicationStatusInfo {
    title: string
    subtitle: string
    Icon: any
    button?: {
        title?: string
    },
    status?: {
        title?: string,
        backgroundColor?: string
    }
}

const applicationStatusDefault: ApplicationStatusInfo = {
    title: "¡Solicita tu crédito hoy mismo!",
    subtitle: "Inicia tu solicitud, solo te llevará unos minutos, fácil, rápido y en línea.",
    Icon: AppStatusDefaultImg,
    button: {
        title: 'Solicitar'
    }
}

const applicationStatusInProcess: ApplicationStatusInfo = {
    title: "Continua con tu solicitud y recibe tu crédito",
    subtitle: "Estás a solo unos clics de completar tu solicitud para comprar el hogar de tus sueños.",
    Icon: HomeImg,
    button: {
        title: 'Continuar solicitud'
    },
    status: {
        title: 'Tu solicitud se encuentra en proceso',
        backgroundColor: '#95e1bf',
    },
}

export function getStatusInfo(
    application?: Application,
    currentStage?: ApplicationWorkflowStage,
    showCredit?: boolean
): ApplicationStatusInfo {
    if (!application || !application?.status) {
        return applicationStatusDefault
    }

    if (Boolean(application?.parent?.id)) {
        return {
            ...applicationStatusInProcess,
            title: 'Continua con tu solicitud',
            subtitle: `Estás a solo unos clics de completar tu solicitud para ayudar a ${application?.parent?.customer_name || '-'} a comprar el hogar de sus sueños.`,
            status: undefined
        }
    }

    if (showCredit) {
        return {
            ...applicationStatusDefault,
            title: 'Conoce los detalles de tu crédito',
            subtitle: 'Dale seguimiento a tu crédito: pagos, asesoría, estados de cuenta.',
            button: {
                title: 'Ver detalles'
            },
            status: undefined
        }
    }

    switch (application!.status!) {
        default: {
            return applicationStatusDefault
        }

        case 'draft':
        case 'in_process':
        case 'action_required': {

            if (currentStage?.visibility === 'user' || currentStage?.status === STAGE_STATUS.IN_REVIEW) {
                return {
                    ...applicationStatusInProcess,
                    button: undefined,
                }
            }

            return {
                ...applicationStatusInProcess,
                status: undefined
            }
        }

        case 'rejected': {
            return {
                ...applicationStatusDefault,
                title: 'Lo sentimos, tu solicitud fue rechazada',
                subtitle: '¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.',
                status: undefined,
                Icon: RejectedImg
            }
        }

        case 'cancelled': {
            return {
                ...applicationStatusDefault,
                title: 'Lo sentimos, tu solicitud fue cancelada',
                subtitle: '¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.',
                status: undefined,
                Icon: CancelledImg
            }
        }

        case 'approved': {
            return {
                ...applicationStatusDefault,
                title: 'Tu solicitud ha sido aprobada',
                subtitle: "¡Felicidades! Pronto recibirás tu propuesta de crédito.",
                Icon: ApprovedImg,
                button: undefined,
                status: {
                    title: 'Aprobada',
                    backgroundColor: '#95e1bf',
                }
            }
        }
    }
}