import { Action, DirectDebitType } from "src/types";
import { DIRECTS_DEBITS_TYPES } from "./types";

export interface CreditDirectsDebitsStateType {
    data?: DirectDebitType[]
    directDebit?: DirectDebitType
    isLoading: boolean
}

const INITIAL_STATE: CreditDirectsDebitsStateType = {
    isLoading: false
}

export function directsDebitsReducer(state: CreditDirectsDebitsStateType = INITIAL_STATE, action: Action): CreditDirectsDebitsStateType {
    const { type, payload } = action
    switch (type) {

        case DIRECTS_DEBITS_TYPES.GET_ONE:
        case DIRECTS_DEBITS_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true
            }
        }

        case DIRECTS_DEBITS_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.directsDebits
            }
        }

        case DIRECTS_DEBITS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                directDebit: payload.directDebit
            }
        }

        default:
            return state;
    }
}