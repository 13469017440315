import { Components, alpha } from "@mui/material";

const CircularStdNormalOtf = "../fonts/CircularStd/CircularStd-Book.otf";
const CircularStdMediumOtf = "../fonts/CircularStd/CircularStd-Medium.otf";
const CircularStdBoldOtf = "../fonts/CircularStd/CircularStd-Bold.otf";

export function components(primaryColor: string, secondaryColor: string): Components {
    return ({
        MuiCssBaseline: {
            styleOverrides: `
			@font-face {
				font-family: 'Circular Std';
				src: url(${CircularStdNormalOtf}) format('opentype');
				font-weight: 400;
				font-style: normal;
			}
	
			@font-face {
				font-family: 'Circular Std';
				src: url(${CircularStdMediumOtf}) format('opentype');
				font-weight: 500;
				font-style: normal;
			}
	
			@font-face {
				font-family: 'Circular Std';
				src: url(${CircularStdBoldOtf}) format('opentype');
				font-weight: bold;
				font-style: normal;
			}
		`
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: 14,
                    fontWeight: 400,
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 60,
                    textTransform: 'none',
                    '&$disabledd': {
                        '&:hover': {
                            boxShadow: 'none !important'
                        }
                    },
                },
                sizeSmall: {
                    fontSize: 14,
                    padding: '8px 16px',
                    height: 36
                },
                sizeLarge: {
                    fontSize: 16,
                    padding: '14px 40px',
                    height: 52
                },
                contained: {
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: primaryColor + ' !important',
                        boxShadow: `0px 16px 24px ${alpha(primaryColor, .14)}, 0px 8px 10px ${alpha(primaryColor, .26)} !important`,
                    },

                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: primaryColor + ' !important'
                    },
                    '& path': {
                        fill: '#fff'
                    }
                },
                outlined: {
                    borderRadius: 60,
                    padding: '14px 40px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#fff !important',
                        boxShadow: `0px 16px 24px ${alpha(primaryColor, .14)}, 0px 8px 10px ${alpha(primaryColor, .26)} !important`,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: '#242C37 !important',
                    fontWeight: 'bold',
                    marginBottom: 8,
                    lineHeight: 1.4,
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    minHeight: 50,
                    ['@media (min-width: 600px)']: {
                        minHeight: 50
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    '& > svg': {
                        fill: '#242C37'
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '10px 14px !important',
                    height: 20
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    padding: '10px 14px !important',
                    height: 20
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: '#242C37',
                    backgroundColor: '#242C37',
                    fontSize: 12,
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    top: '10px !important',
                    padding: '4px 12px',
                },
                tooltipPlacementBottom: {
                    margin: '2px 0px !important'
                },
                arrow: {
                    color: '#242C37'
                }
            }
        },
    })
}