import { ActionableType, CustomSwitchableEvaluatorQualifierField } from "src/types"

export const personalActionableTypes: ActionableType[] = ['Customer', 'ApplicationWorkflow', 'Address']
export const employmentActionableTypes: ActionableType[] = ['Employment']
export const bankAccountActionableTypes: ActionableType[] = ['BankAccount']
export const propertyActionableTypes: ActionableType[] = ['Property']

/** Use to filter approvals by an actionable type and/or actionable identifier
 * @param approvals Approvals array
 * @param actionableType `ActionableType[]`
 * @param actionableId Example: If actionableType is a Customer and we need filter their approvals,
 * we set actionableId with the customer identifier
 * @returns Filtered approvals array
 */
export function filterApprovals(approvals: CustomSwitchableEvaluatorQualifierField[], actionableType?: ActionableType[], actionableId?: string): CustomSwitchableEvaluatorQualifierField[] {
    if (!Boolean(actionableType)) {
        return []
    }
    if (Boolean(actionableId)) {
        return approvals.filter(a =>
            Boolean(a?.actionable_type)
            && actionableType?.includes(a.actionable_type! as ActionableType)
            && a?.actionable_id === actionableId
        ) || []
    } else {
        return approvals.filter(a =>
            Boolean(a?.actionable_type)
            && actionableType?.includes(a.actionable_type! as ActionableType)
        ) || []
    }
}

function filterActionableIds(approvals: CustomSwitchableEvaluatorQualifierField[], actionableType: ActionableType): string[] {
    return approvals.filter((approval, index, array) =>
        approval?.actionable_type === actionableType &&
        array.findIndex(d => d?.actionable_id === approval?.actionable_id) === index
    ).map(approval => approval?.actionable_id || '')
}

/** Build approvals object with personal, employment, bank account and property approvals
 * @param approvals Approvals array
 * @returns Object with personal, employments, bank accounts and properties approvals
*/
export const buildApprovals = (approvals: CustomSwitchableEvaluatorQualifierField[]): {
    personal: CustomSwitchableEvaluatorQualifierField[],
    property: { actionableId: string, approvals: CustomSwitchableEvaluatorQualifierField[] }[],
    employment: { actionableId: string, approvals: CustomSwitchableEvaluatorQualifierField[] }[],
    bankAccount: { actionableId: string, approvals: CustomSwitchableEvaluatorQualifierField[] }[]
} => {
    const employmentsIds = filterActionableIds(approvals, 'Employment')
    const bankAccountsIds = filterActionableIds(approvals, 'BankAccount')
    const propertiesIds = filterActionableIds(approvals, 'Property')

    return {
        personal: filterApprovals(approvals, personalActionableTypes),
        employment: employmentsIds.map(id => ({
            actionableId: id,
            approvals: filterApprovals(approvals, employmentActionableTypes, id)
        })),
        bankAccount: bankAccountsIds.map(id => ({
            actionableId: id,
            approvals: filterApprovals(approvals, bankAccountActionableTypes, id)
        })),
        property: propertiesIds.map(id => ({
            actionableId: id,
            approvals: filterApprovals(approvals, propertyActionableTypes, id)
        })),
    }
}

export const buildUrl = (applicationId?: string, actionableId?: string): string => {
    if (!applicationId || !actionableId) {
        return `#`
    }
    return `/home/applications/${applicationId}/documents/${actionableId}`
}