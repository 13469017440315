import { Box, Divider, Grid, Typography } from "@mui/material"
import { PaymentType } from "src/types"
import { useStyles } from "./styles"
import { formatPaymentAmount } from "src/helpers/payments"

interface PaymentTotalsProps {
    payment?: PaymentType
}

export function PaymentTotals({ payment }: PaymentTotalsProps) {
    const { classes, cx } = useStyles()
    const { amount_total, amount_subtotal } = payment || {}

    return (
        <Box className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box className={cx(classes.row, "totalRow")}>
                        <Typography> Total </Typography>
                        <Typography> {formatPaymentAmount(amount_total) || '-'} </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}