import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {},
    headerButton: {
        '& svg': {
            height: 24,
            fill: '#323232',
            width: 24,
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '30px',
        letterSpacing: '0.18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '26px',
            letterSpacing: '0.15px',
            textAlign: 'center',
        }
    },
}))