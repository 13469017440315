import { Avatar, Box, Typography } from '@mui/material'

import {
    EmailOutlined as EmailIcon,
    PhoneIphoneOutlined as PhoneIcon,
} from "@mui/icons-material"

import { Card } from 'src/components'
import { Application } from 'src/types'
import { initialsFor, useIsMobile } from 'src/helpers'
import { useStyles } from './styles'

import { useSelector } from 'react-redux'
import { formatPhoneNumber } from './helpers'
import { ConsultantCardActions } from './Actions'
import { useMemo } from 'react'

interface ConsultantCardProps {
    application?: Application
}

export const ConsultantCard = (props: ConsultantCardProps) =>
    useMemo(() => <MemoConsultantCard {...props} />, [props?.application?.id])

export function MemoConsultantCard({ application: _application }: ConsultantCardProps) {
    const { classes } = useStyles()
    const isMobile = useIsMobile()

    const application = _application || useSelector(state => state.applications?.lastApplication)
    const user = useSelector(state => state.auth.customer.user)

    function Actions() {
        if (!user?.email && !user?.phone) {
            return null
        }
        return <ConsultantCardActions application={application} user={user} />
    }

    return (
        <>
            <Card
                title='Tu asesor'
                titleOutside
                contentGridProps={{ className: classes.cardContent }}
            >
                <Box className={classes.contentWrapper}>
                    <Box className={classes.userContainer}>
                        <Avatar
                            color="primary"
                            className={classes.avatar}
                            src={user?.profile_image}
                        >
                            {initialsFor(`${user?.first_name} ${user?.last_name}`)}
                        </Avatar>

                        <Box className={classes.userDataContainer}>
                            <Typography className={classes.userNameText} variant="h3">
                                {user?.first_name || ''} {user?.last_name || ''}
                            </Typography>

                            <Typography className={classes.greetingText}>
                                ¡Hola! Estoy para resolver todas tus preguntas, por favor no dudes en contactarme.
                            </Typography>

                            {(user?.email || user?.phone) && (
                                <Box className={classes.contactContainer}>
                                    {user?.email && <Typography> <EmailIcon /> {user.email} </Typography>}
                                    {user?.phone && <Typography> <PhoneIcon /> {formatPhoneNumber(user?.phone)} </Typography>}
                                </Box>
                            )}
                        </Box>
                    </Box>

                    {!isMobile && <Actions />}
                </Box>
            </Card>

            {isMobile && <Actions />}
        </>
    )
}