import { useMemo } from 'react'
import { Grid, Zoom } from '@mui/material'
import { images } from 'src/helpers/images'
import { buildApprovals, buildUrl } from './helpers'
import { useStyles } from './styles'

import { ProgressCard } from './Card'

import { useSelector } from 'react-redux'

interface ProgressCardsProps {
    applicationId?: string
}

export function ProgressCards({ applicationId }: ProgressCardsProps) {
    const { classes } = useStyles()

    const orgSlug = useSelector(state => state.organization?.slug)
    const evaluators = useSelector(state => state.applications.switchableEvaluators?.data || [])

    const approvals = useSelector(state =>
        state.applications.switchableEvaluators?.approvals || []
    ).filter(approval => approval?.document_required)

    const approvalsByActionables = useMemo(() => buildApprovals(approvals), [approvals])

    return (
        <Grid
            container
            spacing={3}
            className={classes.root}
        >
            <Zoom in hidden={approvalsByActionables['personal'].length <= 0}>
                <Grid item>
                    <ProgressCard
                        title="Solicitante principal"
                        Icon={images[orgSlug!].personalDocuments}
                        approvals={approvalsByActionables['personal']}
                        to={buildUrl(applicationId, approvalsByActionables['personal']?.[0]?.actionable_id)}
                        rootProps={{ className: classes.progressCard }}
                    />
                </Grid>
            </Zoom>

            {approvalsByActionables['employment'].map((actionable, employmentIdx) => (
                <Zoom key={employmentIdx} in hidden={actionable.approvals.length <= 0}>
                    <Grid item>
                        <ProgressCard
                            title={`Ingreso ${employmentIdx + 1}`}
                            Icon={images[orgSlug!].employmentDocuments}
                            approvals={actionable.approvals}
                            actionableId={actionable?.actionableId}
                            actionableType='Employment'
                            to={buildUrl(applicationId, actionable?.actionableId)}
                            rootProps={{ className: classes.progressCard }}
                        />
                    </Grid>
                </Zoom>
            ))}

            {approvalsByActionables['bankAccount'].map((actionable, accountIdx) => {
                // @ts-ignore
                const { institution_name } = evaluators.find(e => e?.uuid === actionable?.actionableId) || {}
                return (
                    <Zoom key={accountIdx} in hidden={actionable.approvals.length <= 0}>
                        <Grid item>
                            <ProgressCard
                                title={`Cuenta bancaria ${institution_name || accountIdx + 1}`}
                                Icon={images[orgSlug!].employmentDocuments}
                                approvals={actionable.approvals}
                                actionableId={actionable?.actionableId}
                                actionableType='BankAccount'
                                to={buildUrl(applicationId, actionable?.actionableId)}
                                rootProps={{ className: classes.progressCard }}
                            />
                        </Grid>
                    </Zoom>
                )
            })}

            {approvalsByActionables['property'].map((actionable, propertyIdx) => (
                <Zoom key={propertyIdx} in hidden={actionable.approvals.length <= 0}>
                    <Grid item>
                        <ProgressCard
                            title={`Propiedad ${propertyIdx + 1}`}
                            Icon={images[orgSlug!].propertyDocuments}
                            approvals={actionable.approvals}
                            actionableId={actionable?.actionableId}
                            actionableType='Property'
                            to={buildUrl(applicationId, actionable?.actionableId)}
                            rootProps={{ className: classes.progressCard }}
                        />
                    </Grid>
                </Zoom>
            ))}
        </Grid >
    )
}