import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    button: {
        color: theme.palette.primary.dark,
        height: 36,
        width: 250,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
        }
    }
}));