import { alpha } from "@mui/material"
import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    card: {
        borderRadius: 10,
        backgroundColor: '#fff',
        padding: '24px 26px'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    chip: {
        borderRadius: 12,
        fontSize: 12,
        padding: '4px 8px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.main
    },
    label: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.text.primary
    },
    value: {
        fontSize: 14,
        color: theme.palette.text.primary
    },
    paginationContainer: {
        paddingTop: 32
    },
    pagination: {
        justifyContent: 'center',
        '& .Mui-selected': {
            background: 'rgba(117, 139, 183, 0.08)'
        },
    },
    paginationItem: {
        fontWeight: 500,
    },
    paginationUl: {
        justifyContent: 'center'
    }
}))