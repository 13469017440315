import { ActionableType } from "src/types"

export interface MenuActionProps {
    title: string,
    handleAction?: (actionableId?: string, actionableType?: ActionableType) => void,
    disabled?: boolean
}

export const getStatus = (totals: {
    inReview: number,
    finished: number,
    approvalsTotal: number
}) => {
    const { inReview, finished, approvalsTotal } = totals

    if (finished === approvalsTotal) {
        return {
            label: 'Completado',
            bgColor: '#E5F4EC',
            color: '#1DAA6C'
        }
    }

    if (inReview > 0) {
        return {
            label: 'Revisión',
            bgColor: '#FFEFDC',
            color: '#FF8D02'
        }
    }

    return {
        label: 'En proceso',
        bgColor: '#E7F3FE',
        color: '#64A8F8'
    }
}