import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    paperContent: {
        height: '100%',
        overflow: 'auto',
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        padding: '40px 40px 20px',
        borderBottom: 'none',
        '& svg': {
            fill: '#8892A0',
            height: 18,
            width: 18
        },
        [theme.breakpoints.down('sm')]: {
            padding: "20px 24px 20px"
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: '0.15px'
    },
    titleContainerArrow: {
        padding: '24px 16px 0',
        '& svg': {
            fill: '#242C37',
        }
    },
    container: {
        padding: '12px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '12px 24px',
        }
    },
    paper: {
        borderRadius: 14,
        height: 'calc(100% - 48px)',
        margin: 24,
        width: '30%',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '55%',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100%',
            margin: 0,
            borderRadius: 0,
        }
    },
    arrowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}))