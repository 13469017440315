import { Fragment } from "react";
import { Grid, Stepper, Step, StepLabel, StepperProps, StepConnectorProps, GridProps, alpha } from "@mui/material";
import { useStyles, StepConnector } from "./styles";
import { ApplicationStatusType, ApplicationWorkflowStage } from "src/types";
import { StepIcon, groupStages, getCurrentStep } from "./helpers";

export interface StageStepperProps {
    activeStep: number;
    stages: ApplicationWorkflowStage[];
    currentStage?: ApplicationWorkflowStage;
    stepperProps?: Partial<StepperProps>;
    stepConnectorProps?: StepConnectorProps
    containerProps?: GridProps
    applicationStatus?: ApplicationStatusType
}

export function StageStepper({
    activeStep,
    stages,
    currentStage,
    stepperProps,
    stepConnectorProps,
    containerProps,
    applicationStatus
}: StageStepperProps) {
    const { classes, cx } = useStyles();
    const groupedStages = groupStages(stages);

    return (
        <Stepper
            activeStep={getCurrentStep(groupedStages, currentStage!) || activeStep}
            connector={<StepConnector {...stepConnectorProps} />}
            orientation="horizontal"
            {...stepperProps}
            alternativeLabel
            className={cx(classes.stepper, stepperProps?.className)}
        >
            {groupedStages.map((stage, index) => {
                const isPrivate = stage.visibility === 'user';
                const isCurrentStage = stage.ids
                    ? stage.ids.includes(currentStage?.id || '')
                    : currentStage?.id === stage.id;

                return (
                    <Step key={stage.id} className={cx({ ['currentStepRoot']: isCurrentStage })}>
                        <StepLabel
                            // @ts-ignore
                            StepIconComponent={StepIcon}
                            StepIconProps={{
                                //@ts-ignore
                                isPrivate,
                                isCurrent: isCurrentStage,
                                isRejected: applicationStatus == 'rejected',
                                isCancelled: applicationStatus == 'cancelled',
                            }}
                            icon={<Fragment />}
                            className={cx(classes.label, {
                                [cx(classes.currentStep, 'currentStep')]: isCurrentStage
                            })}
                            classes={{
                                completed: classes.completed,
                                active: classes.currentStep,
                                label: classes.customLabel
                            }}
                        >
                            {isPrivate ? 'Revisión de documentos' : stage?.name}
                        </StepLabel>
                    </Step>
                )
            })}
        </Stepper>
    )
}