import { tss } from "tss-react/mui"

const ROW_VARIANT_CARD_SPACING = 16

export const useStyles = tss.create(({ theme }) => ({
	columnName: {
		color: theme.palette.text.primary,
		fontWeight: 400,
		borderBottom: 'none',
	},
	columnNameSortBy: {
		//cursor: 'pointer',
	},
	columnNameInCell: {
		padding: '8px',
		borderBottom: 'none',
		verticalAlign: 'top',
	},
	columnNameInCellLabel: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontWeight: 700,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,

	},
	subheader: {
		alignItems: 'center',
		borderRadius: 4,
		display: 'inline-flex',
		cursor: 'pointer',
		gap: 4,
		fontWeight: 'bold',
		transition: theme.transitions.create('', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.12)',
		},
		'&:hover $arrowIcon': {
			opacity: 1,
		}
	},
	cell: {
		fontSize: 14,
		color: '#242C37'
	},
	rowHover: {
		cursor: 'pointer',
		'&:hover': {
			background: 'rgba(117, 139, 183, 0.04)'
		},
		'&:focus': {
			background: 'rgba(117, 139, 183, 0.04)'
		},
	},
	row: {
		'& p': {
			margin: 0
		}
	},
	rowDisabled: {
		'& p': {
			margin: 0
		}
	},
	filterIconButton: {
		'& path': {
			fill: '#242C37'
		}
	},
	arrowIcon: {
		fontSize: 16,
		height: 16,
		width: 16,
		'& svg': {
			height: 16,
			width: 16,
		},
		transition: theme.transitions.create('', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	arrowClose: {
		opacity: 0,
	},
	arrowOpen: {
		opacity: 1,
	},
	sortDesc: {
		transform: 'rotate(180deg)',
		'& svg': {
			transform: 'rotate(180deg)',
		}
	},
	arrowIconContainer: {
		transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
	},
	rowCounter: {
		marginBottom: 24
	},
	pagination: {
		marginBottom: 24
	},
	mobilePaginationList: {
		justifyContent: 'space-between'
	},
	borderBottomNone: {
		borderBottom: 'none'
	},
	cellDisabled: {
		opacity: 0.4,
		cursor: 'not-allowed'
	},
	collapsableIconButton: {
		color: 'black',
		height: 18,
		width: 18,
		marginRight: 8,
		verticalAlign: 'text-bottom'
	},
	rowVariantCardTableContainer: {
		marginTop: -ROW_VARIANT_CARD_SPACING,
		marginBottom: -ROW_VARIANT_CARD_SPACING,
	},
	rowVariantCardTable: {
		borderCollapse: 'separate',
		borderSpacing: `0 ${ROW_VARIANT_CARD_SPACING}px`,
	},
	rowVariantCardTableBody: {
		'& a, & tr': {
			height: 53,
			borderSpacing: 8,
			"& td": {
				paddingTop: '8px',
				paddingBottom: '8px',
				borderTop: '1px solid #CFD5E2',
				borderBottom: '1px solid #CFD5E2',

			},
			"& td:first-child": {
				borderLeft: '1px solid #CFD5E2',
				borderTopLeftRadius: 8,
				borderBottomLeftRadius: 8,
				paddingLeft: 24

			},
			"& td:last-child": {
				borderRight: '1px solid #CFD5E2',
				borderBottomRightRadius: 8,
				borderTopRightRadius: 8,
			},
			'&:hover': {
				background: 'rgba(117, 139, 183, 0.04)'
			}
		}
	}
}));