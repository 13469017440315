import { Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { Loading } from "src/components";
import { Fragment } from "react";

export function RejectedReasons() {
    const { classes, cx } = useStyles();
    const scoreResult = useSelector(state => state.applications.scoreResult)
    const isLoading = useSelector(state => state.applications.isLoadingScoreResult)

    return (
        <Grid container className={classes.card}>
            <Typography className={classes.title}> Encontramos las siguientes motivos de rechazo </Typography>

            <Loading isLoading={isLoading} variant="skeleton">
                {scoreResult?.ko_reasons ? (
                    <ul>
                        {scoreResult?.ko_reasons?.map(({ ko_label, ko_reason }, index) => (
                            <li key={index} className={classes.reason}>
                                <div className={classes.reasonTitle}>
                                    <Typography> {ko_label} </Typography>
                                </div>
                                <Typography
                                    className={classes.reasonDescription}
                                    dangerouslySetInnerHTML={{ __html: ko_reason }}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Fragment />
                )}
            </Loading>
        </Grid>
    )
}