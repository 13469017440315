import { useMemo } from 'react'
import { Grid, Zoom } from '@mui/material'
import { Card } from 'src/components'
import { Application, Customer } from 'src/types'
import { useApplicationStage } from 'src/helpers'
import { showShortcuts } from './helpers'
import { useStyles } from './styles'

import { ApplicationStatusAlert } from './Alert'
import { Shortcuts } from './Shortcuts'

import { useSelector } from 'react-redux'

interface DashboardCardProps {
    customer?: Customer
    application?: Application
    setCancelApplicationOpen: (open: boolean) => void
    setCoaccreditOpen: (open: boolean) => void
}

export const DashboardCard = (props: DashboardCardProps) =>
    useMemo(() => <MemoDashboardCard {...props} />, [props?.application?.id, props?.customer?.id])

export function MemoDashboardCard({
    customer: _customer,
    application: _application,
    setCancelApplicationOpen,
    setCoaccreditOpen
}: DashboardCardProps) {
    const { classes } = useStyles()
    const { currentStage } = useApplicationStage({ useLastOneApplication: true })

    const customer = _customer || useSelector(state => state.auth.customer)
    const application = _application || useSelector(state => state.applications?.lastApplication)

    return (
        <Card
            title={customer?.first_name ? `¡Hola ${customer.first_name}!` : '¡Hola!'}
            className={classes.root}
            titleProps={{ className: classes.title }}
            titleContainerProps={{ className: classes.titleContainer }}
            contentGridProps={{ className: classes.content }}
        >
            <Grid container gap={'40px'}>
                <Zoom in hidden={!Boolean(application?.id)}>
                    <Grid item xs={12}>
                        <ApplicationStatusAlert application={application} />
                    </Grid>
                </Zoom>

                {showShortcuts(application, (currentStage?.sort_order || 0) == 0) && (
                    <Grid item xs={12}>
                        <Shortcuts
                            application={application!}
                            setCancelApplicationOpen={setCancelApplicationOpen}
                            setCoaccreditOpen={setCoaccreditOpen}
                        />
                    </Grid>
                )}
            </Grid>
        </Card>
    )
}