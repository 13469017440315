import { GetOptionsType } from "src/types"
import { APPLICATION_REJECTED_DOCUMENTS } from "./types"

interface DocumentsParamsType {
    actionable_id_eq?: string
    actionable_type_eq?: string
}

function getAll(applicationId: string, options?: GetOptionsType<DocumentsParamsType>) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.GET_ALL,
        payload: { applicationId, options }
    }
}

function getAllDelayed(applicationId: string) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.GET_ALL_DELAYED,
        payload: { applicationId }
    }
}

function setAll(documents?: any[]) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.SET_ALL,
        payload: { documents }
    }
}

function upload(applicationId: string, documentId: string, attachment: any) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.UPLOAD,
        payload: { applicationId, documentId, attachment }
    }
}

function hasAny(applicationId: string) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.HAS_ANY,
        payload: { applicationId }
    }
}

function setHasAny(value: boolean) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.SET_HAS_ANY,
        payload: { value }
    }
}

function getRequiredCount(applicationId: string) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.GET_REQUIRED_COUNT,
        payload: { applicationId }
    }
}

function setRequiredCount(count?: number) {
    return {
        type: APPLICATION_REJECTED_DOCUMENTS.SET_REQUIRED_COUNT,
        payload: { count }
    }
}

export const applicationRejectedDocuments = {
    getAll,
    getAllDelayed,
    setAll,
    upload,
    hasAny,
    setHasAny,
    getRequiredCount,
    setRequiredCount
}