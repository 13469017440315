import { alpha } from "@mui/material";
import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    chip: {
        padding: '4px 8px',
        height: 24,
        maxWidth: '100%',
        fontSize: 12,
    },
    label: {
        padding: 0
    },
    default: {
        color: theme.palette.text.primary,
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
    },
    defaultOutlined: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
    },
    primary: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    primaryOutlined: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    warning: {
        color: theme.palette.warning.main,
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
    },
    warningOutlined: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
    },
    error: {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.1),
    },
    errorOutlined: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.1),
    },
    success: {
        color: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.1),
    },
    successOutlined: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.1),
    },
    info: {
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.1),
    },
    infoOutlined: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.1),
    },
    disabled: {
        color: theme.palette.text.disabled,
        backgroundColor: alpha(theme.palette.text.disabled, 0.1),
    },
    disabledOutlined: {
        color: theme.palette.text.disabled,
        borderColor: theme.palette.text.disabled,
        backgroundColor: alpha(theme.palette.text.disabled, 0.1),
    },
}))