import { SMALL_TABLET_BREAKPOINT } from "src/assets/theme/theme";
import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        borderRadius: 10,
        background: '#fff'
    },
    border: {
        border: '1px solid #CFD5E2',
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            marginTop: 4
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        color: '#242C37',
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
    },
    titleContainer: {
        padding: '24px 24px 0px',
    },
    paddingNone: {
        padding: 0
    },
    divider: {
        borderBottom: '1px solid #CFD5E2',
        paddingBottom: 24
    },
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    contentContainer: {
        padding: '24px',
    },
}))