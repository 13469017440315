import { ArrowForwardIos } from "@mui/icons-material"

type Direction = 'left' | 'right'

interface DirectionArrowProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    direction?: Direction
    setScrollSpeed: (speed: number) => void
    onMouseEnter: (direction: Direction) => void
    onMouseLeave: () => void
}

export const SCROLL_SPEED = 1.5
export const SCROLL_SPEED_FAST = 8

export function DirectionArrow({
    wrapperRef,
    direction = 'right',
    onMouseEnter,
    onMouseLeave,
    setScrollSpeed,
}: DirectionArrowProps) {
    const wrapper = wrapperRef?.current

    const scrollWidth = wrapper?.scrollWidth || 0
    const clientWidth = wrapper?.clientWidth || 0
    const nScrollWidth = scrollWidth - clientWidth
    const scrollLeft = wrapper?.scrollLeft || 0

    function getDisplay(): 'block' | 'none' {
        if (direction === 'left') {
            return scrollLeft === 0 ? 'none' : 'block'
        } else {
            return scrollLeft >= nScrollWidth ? 'none' : 'block'
        }
    }

    if (!wrapper) {
        return null
    }

    return (
        <div
            onMouseDownCapture={() => setScrollSpeed(SCROLL_SPEED_FAST)}
            onMouseUp={() => setScrollSpeed(SCROLL_SPEED)}
            onMouseEnter={() => onMouseEnter(direction)}
            onMouseLeave={onMouseLeave}
            style={{
                position: 'absolute',
                right: 0,
                cursor: 'pointer',
                userSelect: 'none',
                zIndex: 1305,
                top: -5,
                ...(direction === 'left' ? {
                    left: 0,
                    right: 'auto'
                } : {}),
                display: getDisplay()
            }}
        >
            <ArrowForwardIos
                sx={theme => ({
                    height: 12,
                    width: 12,
                    color: theme.palette.primary.main,
                    verticalAlign: 'middle',
                    transform: direction === 'left' ? 'rotate(180deg)' : 'none'
                })}
            />
        </div>
    )
}