import { PopoverProps, TableCellProps } from "@mui/material";
import { SelectValueType } from "src/types";

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type TableRowVariantType = 'default' | 'cards'

export interface TableColumnType<T = any> extends TableCellProps {
    label: any;
    key: keyof T;
    render?: (value: any, row: T, index: number) => any | string | Element;
    hidden?: boolean;
    filterOptions?: SelectValueType[];
    onFilterSelect?: (value: SelectValueType) => void;
    disableSortBy?: boolean;
    /** TableCellprops for specific column header*/
    tableHeaderCellProps?: TableCellProps;
    /** TableCellProps for especific column, replace tableCellProps from TableProps */
    tableCellProps?: TableCellProps;
    /** Hidde in mobile */
    hiddenSm?: boolean;
    sortOrder?: number
    disabled?: (value: any, row?: T) => boolean;
    /** Set this column as a actions, positioned in front of title in mobile view */
    isActionsCellOnMobile?: boolean;
}

export interface TableActionsType<T = any> {
    label: string;
    onClick?: (row: T) => void;
    disabled?: (row: T) => boolean;
    hidden?: (row: T) => boolean;
    isLink?: boolean;
    isExternalLink?: boolean;
    getHref?: (row: T) => string;
    hideSm?: boolean
    icon?: any
}

export function getTooltipTitle(label: string, sortBy: { key: string, order: string }) {
    if (!sortBy || !sortBy.order) {
        return "Sin orden aplicado";
    }

    let _label: string = sortBy.order === "asc" ? "Orden A-Z" : "Orden Z-A";
    const lowerLabel = label.toLocaleLowerCase();

    if (lowerLabel.includes("fecha")) {
        _label = sortBy.order === "asc" ? "Más antiguo" : "Más reciente";
    } else if (["teléfono", "telefono", "monto"].filter((s) => s.includes(lowerLabel)).length > 0) {
        _label = sortBy.order === "asc" ? "Orden menor" : "Orden mayor";
    }
    return _label;
}