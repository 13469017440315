import { useState } from "react";
import { StagePage, Table } from "src/components";
import { useIsMobile } from "src/helpers";
import { getColumns } from "./columns";
import { PdfDialog } from "./PdfDialog";
import { useStyles } from "./styles";

const data = [
    {
        name: 'Contrato de crédito',
        date: '02/Ene/21',
    },
    {
        name: 'Seguro de daños',
        date: '03/Dic/21',
    },
    {
        name: 'Compra del inmueble',
        date: '02/Feb/22',
    },
];

interface PdfDialogType {
    open: boolean;
    title: string;
    data?: any | any[];
}

interface ContractsPageProps {
}

export function ContractsPage({ }: ContractsPageProps) {
    const { classes, cx } = useStyles();
    const isMobile = useIsMobile();
    const [pdfDialog, setPdfDialog] = useState<PdfDialogType>({ open: false, title: "" });
    const columns = getColumns(onDownloadClick, isMobile);

    function onDownloadClick(value: any, row: any) {
        setPdfDialog({
            open: true,
            title: row?.name || '',
        })
    }

    return (
        <>
            <PdfDialog
                title={pdfDialog.title || ''}
                open={pdfDialog.open}
                onClose={() => setPdfDialog({ open: false, title: '' })}
                data={pdfDialog.data}
                maxWidth='lg'
            />

            <StagePage
                title='Contratos'
                breadcrumbs={[
                    {
                        name: 'Crédito',
                        to: '/home/credit'
                    },
                    {
                        name: 'Contratos',
                    },
                ]}
            >
                <Table
                    data={data}
                    columns={columns}
                />
            </StagePage>
        </>
    );
}