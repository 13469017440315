import { TableColumnType } from "src/components"
import { CustomSwitchableEvaluatorQualifierField } from "src/types"

import { ApprovalActions } from "../../Actions"
import { ApprovalStatus } from "../../Status"
import { SetDialogFunction } from "../.."

const tableHeaderCellProps = {
    sx: {
        '& > div': { justifyContent: 'center' }
    }
}

export const getColumns = (setDocDialog: SetDialogFunction): TableColumnType<CustomSwitchableEvaluatorQualifierField>[] => [
    {
        label: 'Tipo de documento',
        key: 'field',
        render: value => value?.label || 'Sin nombre',
    },
    {
        label: 'Estatus',
        key: 'status',
        tableHeaderCellProps: tableHeaderCellProps,
        tableCellProps: { align: 'center' },
        render: (_, row) => <ApprovalStatus approval={row} />
    },
    {
        label: '',
        key: 'id',
        tableCellProps: { align: 'right' },
        render: (_, row) => <ApprovalActions setDocDialog={setDocDialog} approval={row} />
    },
]