import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    icon: {
        color: `${theme.palette.text.secondary}`,
        marginRight: 10,
        '& path': {
            fill: `${theme.palette.text.secondary}`,
        }
    }
}))