import { ReactNode, FC } from "react"
import { MoneyIcon } from "src/assets/icons"
import { formatDate } from "src/helpers"
import { PaymentIntentType, PaymentType } from "src/types"
import { CalendarMonthOutlinedIcon, CheckCircleOutlineIcon } from "src/assets/icons/material"
import { Chip } from "@mui/material"
import { PaymentStatus } from "../../List/Table/Status"
// import { PaymentStatus } from "../../../../List/components/Table/Status"

export interface AsideItem<T = PaymentType> {
    key: keyof T
    title: string
    icon: string | FC
    render?: (value: any, row: T, index: number) => ReactNode
}

export const items: AsideItem[] = [
    {
        title: 'Método de pago',
        key: 'id',
        icon: MoneyIcon,
        render: (value: string) => 'Stripe'
    },
    {
        title: 'Fecha de pago',
        key: 'payment_intent',
        icon: CalendarMonthOutlinedIcon,
        render: (value: PaymentIntentType) => formatDate((value?.created || 0) * 1000) || '-'
    },
    {
        title: 'Estatus',
        key: 'status',
        icon: CheckCircleOutlineIcon,
        render: (value, row, index) => <PaymentStatus index={index} payment={row} />
    },
]