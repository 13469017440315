import { ToolbarProps as MuiToolbarProps } from "@mui/material"
import { useIsMobile } from "src/helpers"
import { Customer, Organization } from "src/types"
import { MobileToolbar } from "./Mobile"
import { DesktopToolbar } from "./Desktop"

export interface ToolbarProps {
    organization?: Organization
    customer?: Customer
    hideLoginButtons: boolean
    isAuthenticated: boolean
    nativeProps?: MuiToolbarProps
    logout: () => void
}

export function Toolbar(props: ToolbarProps) {
    const isMobile = useIsMobile('md')
    return isMobile ? <MobileToolbar {...props} /> : <DesktopToolbar {...props} />
}