import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    emptyCard: {
        alignItems: 'center',
        minHeight: 234,
    },
    cardTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: '0.15px',
        lineHeight: '26px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '22px',
        },
    },
}))