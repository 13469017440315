import { Button, Toolbar } from "@mui/material"
import { AccountCircleOutlined as PersonIcon } from "@mui/icons-material"
import { Link } from "src/components"
import { getImageSource } from "src/helpers"
import { ToolbarProps } from ".."
import { useStyles } from "./styles"
import { CustomerAvatar } from "../Avatar"

export function DesktopToolbar({
    organization,
    customer,
    hideLoginButtons,
    isAuthenticated,
    nativeProps,
    logout
}: ToolbarProps) {
    const { classes, cx } = useStyles(organization?.theme)()
    const { website, logo, theme, login_config } = organization || {}

    return (
        <Toolbar
            {...nativeProps}
            className={cx(
                classes.root,
                { [classes.justifyContentCenter]: hideLoginButtons },
                nativeProps?.className
            )}
        >
            <Link
                native={!isAuthenticated}
                to={!isAuthenticated && website || "/"}
            >
                <img
                    src={theme?.navbar?.logo || logo || getImageSource()}
                    alt={`${organization?.name} logo`}
                    style={{
                        width: theme?.navbar?.logo ? 'auto' : '91px',
                        maxWidth: 135,
                        aspectRatio: 'auto',
                        display: 'block',
                    }}
                />
            </Link>

            {!hideLoginButtons && (
                <div className={classes.navItems}>
                    {isAuthenticated ? (
                        <CustomerAvatar
                            organization={organization}
                            customer={customer}
                            logout={logout}
                        />
                    ) : (
                        <>
                            {location.pathname === "/magic_link" ? (
                                !Boolean(login_config?.disable_account_creation) && (
                                    <Link to="/create_account">
                                        <Button startIcon={<PersonIcon color="primary" />}>
                                            Crear una cuenta
                                        </Button>
                                    </Link>
                                )
                            ) : (
                                <Link
                                    to={location.pathname === "/magic_link" && !Boolean(login_config?.disable_account_creation)
                                        ? "/create_account"
                                        : "/magic_link"}
                                >
                                    <Button startIcon={<PersonIcon color="primary" />} >
                                        {location.pathname === "/magic_link" && !Boolean(login_config?.disable_account_creation)
                                            ? "Crear una cuenta"
                                            : "Iniciar sesión"}
                                    </Button>
                                </Link>
                            )}


                            {(!isAuthenticated && location.pathname != "/") && (
                                <Link to="/">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: 24 }}
                                    >
                                        Aplicar
                                    </Button>
                                </Link>
                            )}
                        </>
                    )}
                </div>
            )}
        </Toolbar>
    )
}