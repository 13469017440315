import { Action, CreditType } from "src/types";
import { CREDITS_TYPES } from "./types";

export interface CreditStateType {
    credit?: CreditType,
    isLoading: boolean
}

const INITIAL_STATE: CreditStateType = {
    isLoading: false
}

export function creditsReducer(state: CreditStateType = INITIAL_STATE, action: Action): CreditStateType {
    const { type, payload } = action
    switch (type) {

        case CREDITS_TYPES.GET_ONE: {
            return {
                ...state,
                isLoading: true
            }
        }

        case CREDITS_TYPES.SET_ONE: {
            return {
                ...state,
                isLoading: false,
                credit: payload.credit
            }
        }

        default:
            return state
    }
}