import { Fragment } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import ArrowIcon from "@mui/icons-material/ArrowUpward";
import { useStyles } from "./SortOrderTooltip.styles";
import { getTooltipTitle } from "../Table.helpers";
import { CheckIcon } from "src/assets/icons/actions";

interface TableSortOrderTooltipProps {
    label: string
    columnKey: string
    sortBy: { key: string, order: string }
    disableSortBy?: boolean
    onSortBy: (key: string, order: 'asc' | 'desc') => void
    formatSortByKey?: any
    descText?: string
    ascText?: string
}

export function TableSortOrderTooltip({
    label,
    columnKey,
    sortBy,
    disableSortBy,
    onSortBy,
    descText,
    ascText
}: TableSortOrderTooltipProps) {
    const { classes, cx } = useStyles()
    const isAsc = sortBy.key == columnKey && sortBy.order == 'asc'
    const isDesc = sortBy.key == columnKey && sortBy.order == 'desc'
    const TooltipComponent = disableSortBy ? Fragment : Tooltip

    return (
        <>
            {/* @ts-ignore */}
            <TooltipComponent
                {...(!disableSortBy && ({
                    classes: { tooltip: classes.paper },
                    placement: "bottom-start",
                    title: (
                        <Grid container className={classes.container}>
                            <Grid item xs={12}>
                                <div
                                    className={cx(classes.sortButton, {
                                        [classes.selectedOrder]: isDesc
                                    })}
                                    onClick={() => {
                                        if (!disableSortBy) {
                                            onSortBy(columnKey?.toString(), 'desc')
                                        }
                                    }}
                                    style={{ marginBottom: 8 }}
                                >
                                    <div>
                                        <ArrowIcon className={classes.sortIcon} />
                                        <Typography> {descText ? descText : getTooltipTitle(label, { ...sortBy, order: 'desc' })} </Typography>
                                    </div>
                                    {isDesc ? <CheckIcon /> : <div />}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div
                                    className={cx(classes.sortButton, {
                                        [classes.selectedOrder]: isAsc
                                    })}
                                    onClick={() => {
                                        if (!disableSortBy) {
                                            onSortBy(columnKey?.toString(), 'asc')
                                        }
                                    }}
                                >
                                    <div>
                                        <ArrowIcon className={cx(classes.sortIcon, classes.sortIconAsc)} />
                                        <Typography> {ascText ? ascText : getTooltipTitle(label, { ...sortBy, order: 'asc' })} </Typography>
                                    </div>
                                    {isAsc ? <CheckIcon /> : <div />}
                                </div>
                            </Grid>
                        </Grid>
                    )
                }))}
            >
                <span className={classes.label}>
                    {label}
                </span>
            </TooltipComponent>

        </>
    )
}