import { MetaDataType, RelatedApplication } from "src/types"
import { RELATED_APPLICATIONS_TYPES } from "./types"

export interface RelatedApplicationsStateType {
	data: RelatedApplication[]
	meta: MetaDataType
	isLoading: boolean
}

const INITIAL_STATE: RelatedApplicationsStateType = {
	data: [],
	meta: {
		page: 1,
		perPage: 25,
		total: 0,
		totalPages: 0,
	},
	isLoading: false
}

export function relatedApplcationsReducer(state = INITIAL_STATE, action: any): RelatedApplicationsStateType {
	const { type, payload } = action

	switch (type) {

		case RELATED_APPLICATIONS_TYPES.CREATE:
		case RELATED_APPLICATIONS_TYPES.GET_ALL:
			return {
				...state,
				isLoading: true
			}

		case RELATED_APPLICATIONS_TYPES.SET_ALL:
			return {
				...state,
				isLoading: false,
				data: payload.data,
				meta: {
					page: payload.meta?.current_page || state.meta.page,
					perPage: payload.meta?.per_page || state.meta.perPage,
					total: payload.meta?.total || state.meta.total,
					totalPages: payload.meta?.total_pages || state.meta.totalPages
				}
			}

		default:
			return state
	}
}