import { Box, Button } from '@mui/material'
import { PhoneOutlined as PhoneOutlinedIcon, WhatsApp as WhatsAppIcon } from "@mui/icons-material"
import { Application, User } from 'src/types'
import { getWhatsAppLinkWithMessage } from 'src/helpers'
import { useStyles } from './styles'

interface ConsultantCardActionsProps {
    user?: User
    application?: Application
}

export function ConsultantCardActions({ user, application }: ConsultantCardActionsProps) {
    const { classes } = useStyles()

    return (
        <Box className={classes.root}>
            {user?.phone && (
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    className={classes.actionButton}
                    startIcon={<WhatsAppIcon />}
                    target="_blank"
                    rel="noreferrer"
                    href={getWhatsAppLinkWithMessage({
                        application: application,
                        user,
                        origin: 'current'
                    })}
                >
                    Enviar Whatsapp
                </Button>
            )}
        </Box>
    )
}