import { call, put, takeLatest } from "redux-saga/effects"
import { sagaWrapper } from "src/helpers"
import { Service } from "src/services"
import { Action } from "src/types"
import { paymentsProductsActions } from "./actions"
import { PAYMENTS_PRODUCTS_TYPES } from "./types"
import { PaymentProductType } from "src/types"

const productsService = new Service('payments/products', { api: 'koncierge', provider: 'stripe' })

function* getAll({ payload }: Action) {
    const response: PaymentProductType[] = yield call(
        productsService.all,
        payload?.options,
        false
    )
    yield put(paymentsProductsActions.setAll(response || []))
}

export function* paymentsProductsSagas() {
    yield takeLatest(PAYMENTS_PRODUCTS_TYPES.GET_ALL, sagaWrapper(getAll, paymentsProductsActions.setAll()))
}