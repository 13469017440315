import { tss } from "tss-react/mui"
import { OrganizationThemeType } from "src/types"

export const useStyles = (orgTheme?: OrganizationThemeType) => tss.create(({ theme }) => {
    const { navbar } = orgTheme || {}
    return {
        root: {
            background: navbar?.background_color || '#fff',
            display: 'flex',
            alignItems: "center",
            width: '100%',
            height: '100%',
            padding: '0px 24px',
            justifyContent: 'center',
            position: 'relative',
            '& label, & button, & svg, & a': {
                color: navbar?.color,
            },
            '& svg path': {
                fill: navbar?.color
            },
            '& hr': {
                backgroundColor: navbar?.color
            },
            '& .MuiButton-contained': {
                color: navbar?.background_color,
                backgroundColor: navbar?.color,
                '&:hover': {
                    color: `${navbar?.background_color} !important`,
                    backgroundColor: `${navbar?.color} !important`,
                }
            },
        },
        menuIcon: {
            position: 'absolute',
            left: 24
        },
        avatarWrapper: {
            position: 'absolute',
            right: 24
        }
    }
})