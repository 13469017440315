import { Fragment } from "react"
import { MenuItem, Link as MuiLink } from "@mui/material";;
import { Link, Menu, TableProps } from "src/components";
import { useStyles } from "./ActionMenu.styles";
import _ from 'lodash';

interface ActionMenuProps {
    menu: { anchorEl: null | HTMLElement, row: any };
    closeMenu: () => void;
    CustomMenu?: TableProps['CustomMenu'];
    actions: TableProps['actions'];
}

export function ActionMenu<T>({ menu, closeMenu, CustomMenu, actions }: ActionMenuProps) {
    const { classes, cx } = useStyles()

    return (
        <>
            {CustomMenu ? (
                <CustomMenu
                    anchorEl={menu.anchorEl}
                    row={menu.row}
                    onClose={closeMenu}
                />
            ) : !_.isEmpty(actions) ? (
                <Menu
                    anchorEl={menu.anchorEl}
                    open={Boolean(menu.anchorEl)}
                    onClose={closeMenu}
                >
                    {actions?.map(({ label, icon: Icon, onClick, disabled, hidden, isExternalLink, getHref, isLink }, index) => {
                        if (hidden ? !hidden(menu.row) : true) {

                            if (isExternalLink || isLink) {
                                const disable = _.isFunction(disabled) ? disabled(menu.row) : disabled;
                                const Component = disable ? MenuItem : isExternalLink ? MuiLink : Link;
                                const componentProps = disable ? {} : isExternalLink ? {
                                    target: "_blank",
                                    rel: "noreferrer",
                                    href: getHref ? getHref(menu.row) : ''

                                } : {
                                    to: getHref ? getHref(menu.row) : ''
                                }

                                return (
                                    <MenuItem
                                        component={Component}
                                        disabled={disable}
                                        onClick={() => {
                                            if (onClick) {
                                                onClick(menu.row);
                                            }
                                            closeMenu();
                                        }}
                                        {...componentProps}
                                    >
                                        {Icon && <Icon className={classes.icon} />} {label}
                                    </MenuItem>
                                )
                            }

                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        if (onClick) {
                                            onClick(menu.row);
                                        }
                                        closeMenu();
                                    }}
                                    disabled={disabled ? disabled(menu.row) : false}
                                >
                                    {Icon && <Icon className={classes.icon} />} {label}
                                </MenuItem>
                            )
                        }
                    })}
                </Menu>
            ) : <Fragment />}
        </>
    )
}