import { call, debounce, put, select, takeLatest } from "redux-saga/effects"
import { sagaWrapper } from "src/helpers"
import { Service } from "src/services"
import { snackbarActions } from "src/store/snackbar/actions"
import { Action, Application, Response } from "src/types"
import { applicationPaymentsActions } from "./actions"
import { PAYMENTS_TYPES } from "./types"
import { PaymentType } from "src/types"
import { applicationActions } from "../application/actions"

const paymentsService = new Service('payments', { api: 'koncierge', provider: 'stripe' })

function* getAll({ payload }: Action) {
    const response: Response<PaymentType[]> = yield call(
        paymentsService.all,
        payload?.options,
    )
    yield put(applicationPaymentsActions.setAll(response?.data || []))
}

function* getOne({ payload }: Action) {
    const response: Response<PaymentType> = yield call(
        paymentsService.findOne,
        payload?.id
    )
    yield put(applicationPaymentsActions.setOne(response?.data))
}

function* create({ payload }: Action) {
    const { data: payment }: Response<PaymentType> = yield call(paymentsService.create, payload?.payment)

    if (payment?.customer) {
        const application: Application = yield select(state => state.applications.application)

        if (application?.payment_external_id) {
            yield put(applicationPaymentsActions.getAll({
                queryParams: { payment_external_id: payment.customer }
            }))
        } else {
            // After do this, payment table component (PaymentsTable)
            // fetches all payments with the same payment_external_id
            yield put(applicationActions.set('application', {
                ...application,
                payment_external_id: payment.customer
            }))
        }
    }

    yield put(applicationPaymentsActions.setOne(payment))
}

function* update({ payload }: Action) {
    yield put(applicationPaymentsActions.setOne())
    yield put(snackbarActions.add('success', 'Pago actualizado'))
}

function* destroy({ payload }: Action) {
    yield put(snackbarActions.add('success', 'Pago eliminado'))
}

function* link({ payload }: Action) {
    yield call(paymentsService.createPath, `${payload?.id}/sent_payment_link`, {
        template_type: payload?.templateType
    })
    yield put(applicationPaymentsActions.set('isGeneratingLink', false))
    yield put(snackbarActions.add('success', 'Link enviado'))
}

export function* applicationPaymentsSagas() {
    yield takeLatest(PAYMENTS_TYPES.GET_ALL, sagaWrapper(getAll, applicationPaymentsActions.setAll()))
    yield takeLatest(PAYMENTS_TYPES.GET_ONE, sagaWrapper(getOne, applicationPaymentsActions.setOne()))
    yield takeLatest(PAYMENTS_TYPES.CREATE, sagaWrapper(create))
    yield takeLatest(PAYMENTS_TYPES.UPDATE, sagaWrapper(update))
    yield takeLatest(PAYMENTS_TYPES.DESTROY, sagaWrapper(destroy))
    yield debounce(1000, PAYMENTS_TYPES.LINK, sagaWrapper(link))
}

export { paymentsProductsSagas } from "./products/sagas"