import { IncodeDocumentIdInput as KrediIncodeDocumentIdInput } from "@kredimx/form-builder";
import { ApplicationService } from "src/services";
import { UploadComponentProps } from "..";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { providersActions } from "src/store/providers/actions";
import { snackbarActions } from "src/store/snackbar/actions";

export function IncodeDocumentIdInput({
    onUpload,
    fieldKey,
    dispatch,
    application,
    organization,
    customer,
    approval,
    approvalId,
    actionable,
    isUploading,
    ...rest
}: UploadComponentProps) {
    const incodeData = useSelector(state => state.providers.incode.data)
    const verificationFlow = useSelector(state => state.providers.incode.verificationFlow)
    const incodeSessions = useSelector(state => state.providers.incode.sessions)
    const isLoadingSession = useSelector(state => state.providers.incode.isLoadingSession)

    return (
        <KrediIncodeDocumentIdInput
            {...rest}
            config={{
                ...approval?.field?.config,
                comments: approval?.comments,
                rejectedReasons: approval?.verification_reason?.rejected,
                requestReasons: approval?.notes?.map((note) => note?.note_text || '')
            }}
            //@ts-ignore
            name={fieldKey}
            sessions={incodeSessions}
            organization={organization}
            application={application}
            customerId={customer?.id || ''}
            customer={customer}
            verificationFlow={verificationFlow}
            apiKey={incodeData?.api_key}
            incodeUrl={import.meta.env.REACT_APP_INCODE_URL}
            isLoadingSession={isLoadingSession}
            isCustomUploading={isUploading}
            interviewId={_.isArray(incodeSessions) && incodeSessions.length > 0 ? (incodeSessions.find(s => s?.interview_id)?.interview_id) : undefined}
            actionableId={actionable?.id}
            actionableType={actionable?.type}
            approval_id={approvalId}
            user={customer}
            userType="Customer"
            hidePreviousFile
            onChangeCurrentAttachment={onUpload}
            itemProps={{
                md: 12
            }}
            getIncodeData={() => {
                dispatch(providersActions.getIncodeData())
            }}
            getVerificationFlow={flowId => {
                dispatch(providersActions.getIncodeVerificationFlow(flowId))
            }}
            getSession={(applicationWorkflowId, applicationId) => {
                dispatch(providersActions.getIncodeSessions(applicationWorkflowId, applicationId))
            }}
            upsert={data => {
                dispatch(providersActions.incodeUpsert(data))
            }}
            onUnmount={() => {
                dispatch(providersActions.setIncodeSessions())
            }}
            uploadAttachment={async (attachment: any, application: any, id: string) => {
                return await ApplicationService.patchPath(`${application?.id}/answers/${id}/manage`, attachment)
            }}
            onError={details => {
                dispatch(snackbarActions.add('error', 'Lo sentimos, ocurrió un error al procesar tu documento'))
            }}
        />
    )
}