import { CustomSwitchableEvaluatorQualifierField } from "src/types"
import _ from "lodash"

export function buildAttachment(currentAttachment?: FormData, approval?: CustomSwitchableEvaluatorQualifierField) {
    currentAttachment?.append('answer[key]', approval?.field?.key || '')
    currentAttachment?.append('answer[field_type]', approval?.field?.field_type || '')
    currentAttachment?.append('verification_id', approval?.verification_id || '')

    currentAttachment?.append(
        'additional_field_key',
        // @ts-ignore
        `answers.additional_fields.${approval?.field?.key || approval?.key}.value`
    )

    if (approval?.actionable_type === approval?.field?.config?.associated_resource) {
        currentAttachment?.append('associated_resource_id', approval?.actionable_id || '')
    }

    const locations = approval?.field?.config?.locations || []

    if (_.isArray(locations) && locations.length > 0) {
        locations.forEach((_location: string) => {
            currentAttachment?.append('locations[]', _location)
        })
    }

    return currentAttachment
}