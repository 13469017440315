import { Typography, Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import { StagePage, Link, Card, Table } from "src/components";
import { useStyles } from "./styles"

import ProcedureImg from "../img/procedure.svg"
import { proceduresColumns } from "./columns";

const prodecureData = [
    {
        type: 'Pagos',
        procedure: 'Aclaración de pago no aplicado',
        status: 'in_process',
        created_at: '06/Mar/22'
    },
    {
        type: 'Documentos',
        procedure: 'Cancelación simple',
        status: 'in_process',
        created_at: '19/Feb/25'
    },
]

export function ProceduresPage() {
    const { classes, cx } = useStyles()

    return (
        <StagePage
            title="Trámites y aclaraciones"
            breadcrumbs={[
                { name: 'Crédito', to: '/home/credit' },
                { name: 'Trámites y aclaraciones', to: '' },
            ]}
        >
            <Grid container className={cx(classes.card, classes.item, classes.flexColumn)}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={9} className={classes.flexColumn}>
                        <Typography variant="h3"> Inicia un trámite o aclaración </Typography>
                        <Typography> Selecciona las opciones que se adapten al tipo de trámite o aclaración, completa la información solicitada. Trabajaremos en darte una respuesta lo más pronto posible. </Typography>
                        <Link to="/home/credit/procedures/new">
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<AddIcon />}
                            >
                                Nuevo
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.flexEnd}>
                        <ProcedureImg />
                    </Grid>
                </Grid>
            </Grid>

            <Card title="Historial" contentGridProps={{ className: classes.table }}>
                <Table
                    data={prodecureData}
                    columns={proceduresColumns}
                    isLink
                    getHref={(row) => '/home/credit/procedures/1'}
                />
            </Card>
        </StagePage>
    )
}