import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { useStyles } from './Chip.styles';
import { alpha } from '@mui/material';

interface ChipProps extends Omit<Omit<MuiChipProps, 'color'>, 'variant'> {
    /**
     * Set variant
     * @default contained
     */
    variant?: 'outlined' | 'contained';
    /**
     * Set color variant
     */
    colorVariant?: 'primary' | 'warning' | 'error' | 'info' | 'disabled' | 'default';
    /**
     * Set color, overrides colorVariant
     * and set color alpha to 0.2 for background
     */
    color?: string
    /**
     * Set background opacity taking color as base
     * @default 0.2
     */
    backgroundOpacity?: number
    children?: any
}

export function Chip({
    label,
    children,
    color,
    variant,
    style,
    backgroundOpacity,
    colorVariant,
    ...rest
}: ChipProps) {
    const { classes, cx } = useStyles()

    return (
        <MuiChip
            label={label ? label : children}
            className={cx(classes.chip, {
                [classes.default]: !colorVariant && !variant,
                [classes.defaultOutlined]: variant === 'outlined',
                [classes.primary]: colorVariant === 'primary',
                [classes.primaryOutlined]: colorVariant === 'primary' && variant === 'outlined',
                [classes.warning]: colorVariant === 'warning',
                [classes.warningOutlined]: colorVariant === 'warning' && variant === 'outlined',
                [classes.error]: colorVariant === 'error',
                [classes.errorOutlined]: colorVariant === 'error' && variant === 'outlined',
                [classes.info]: colorVariant === 'info',
                [classes.infoOutlined]: colorVariant === 'info' && variant === 'outlined',
                [classes.disabled]: rest.disabled || colorVariant === 'disabled',
                [classes.disabledOutlined]: (rest.disabled || colorVariant === 'disabled') && variant === 'outlined',
            })}
            classes={{
                label: classes.label
            }}
            style={color ? {
                color,
                backgroundColor: alpha(color || '', backgroundOpacity || 0.2),
                ...style,
            } : style}
            {...rest}
        />
    )
}