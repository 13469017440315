import { Chip } from "@mui/material"
import { getApprovalStatusColor, translateApprovalDetailStatus } from "src/helpers"
import { CustomSwitchableEvaluatorQualifierField } from "src/types"

interface ApprovalStatusProps {
    approval: CustomSwitchableEvaluatorQualifierField
}

export function ApprovalStatus({ approval }: ApprovalStatusProps) {
    const { status: approvalStatus } = approval

    const status = translateApprovalDetailStatus[approvalStatus] || 'Sin estatus'
    const { background, color } = getApprovalStatusColor(approvalStatus)

    return (
        <Chip
            label={status || 'Sin estatus'}
            color='primary'
            size='small'
            sx={{
                background,
                color,
                fontSize: 12,
                padding: '4px 8px',
                height: 26,
            }}
        />
    )
}