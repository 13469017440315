import { tss } from "tss-react/mui"

const CELLS_SPACING = 8

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    table: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: CELLS_SPACING,
        width: '100%',
    },
    cell: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        // The formula considers the gap between cells and the number of fixed horizontal cells (3)
        flex: `1 calc(${100 / 3}% - ${CELLS_SPACING * 3}px)`,
        '& + &': {
            textAlign: 'center',
        },
    },
    tableHead: {
        display: 'flex',
        flexFlow: 'row wrap',
        backgroundColor: theme.palette.background.paper,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        marginBottom: CELLS_SPACING,
        width: '100%',
    },
    actionButton: {
        height: 32,
        marginTop: CELLS_SPACING,
    },
    emptyState: {
        '& p': {
            marginTop: 8,
        },
        '& svg': {
            height: 43,
            width: 43,
        }
    }
}))