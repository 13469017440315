import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    card: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: '24px 26px'
    },
    predeterminedLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8
    },
    helIconContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    helpIcon: {
        fontSize: '17px'
    },
    submitButton: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24
        }
    }
}))