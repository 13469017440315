import { Table } from "src/components"
import { SessionLineItemType } from "src/types"
import { paymentItemColumns } from "./columns"

interface PaymentLineItemTableProps {
    data: SessionLineItemType[]
}

export function PaymentLineItemTable({ data }: PaymentLineItemTableProps) {
    return (
        <Table
            hidePagination
            hideRowCounter
            disableAutofocus
            disableHover
            columns={paymentItemColumns}
            data={data}
            tableHeadProps={{
                sx: {
                    '& th:not(:first-child) > div': {
                        justifyContent: 'flex-end'
                    },
                    '& th': {
                        borderBottom: 'none !important'
                    }
                }
            }}
            tableCellProps={{
                style: {
                    borderBottom: `1px solid #CFD5E2`,
                },
                sx: {
                    '&:not(:first-child)': {
                        textAlign: 'right'
                    }
                }
            }}
            emptyStateProps={{
                style: {
                    marginTop: 0
                },
            }}
        />
    )
}