import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { AutoCompleteInput, BinaryInput, SelectInput } from "@kredimx/form-builder"
import { FormBuilder, Loading, Tooltip } from "src/components";
import { HelpOutline as HelpIcon } from "@mui/icons-material"
import { Employment } from "src/types";
import { isValidId, useIsKredi } from "src/helpers"
import _ from "lodash"

import { useDispatch, useSelector } from "react-redux";
import { applicationEvaluatorFieldsActions } from "src/store/application/actions";

import { getEmploymentFields } from "./fields";
import { initialValues } from "./helpers";
import { providersActions } from "src/store/providers/actions";


interface EmploymentDetailsFormProps {
    applicationId: string
    isNew: boolean
    handleSubmit?: () => void
}

export function EmploymentDetailsForm({ applicationId, isNew, handleSubmit }: EmploymentDetailsFormProps) {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const isKredi = useIsKredi()

    const employment = useSelector(state => state.employments.employment)
    const isLoading = useSelector(state => state.employments.isLoading)
    const { data: countries, isLoading: isLoadingCountries } = useSelector(state => state.providers.countries);

    const [values, setValues] = useState<Employment>({})
    const fields = getEmploymentFields({ values, catalogs: {}, isKredi })
    const _initialValues = initialValues({
        fields,
        employment: values,
        resourceType: "application",
        isNew: Boolean(isNew),
        isKredi
    })

    useEffect(() => {
        if (employment?.id) {
            setValues(employment)
        }
    }, [employment?.id])

    function onSubmit(values: any) {
        const _values = {
            ...(!isNew && ({ ...employment })),
            ...values,
            ends_on: values.predetermined ? '' : values.ends_on,
            net_salary: Boolean(values?.net_salary) ? values?.net_salary : 0,
        }

        if (isValidId(applicationId)) {
            dispatch(applicationEvaluatorFieldsActions.executeEvaluators(
                isNew ? 'create' : 'update',
                applicationId,
                'Employment',
                { ...employment, ..._values }
            ))

            if (_.isFunction(handleSubmit)) {
                handleSubmit()
            }
        }
    }

    return (
        <Loading isLoading={isLoading} variant="skeleton" skeletonsNumber={2}>
            <Grid container>
                <FormBuilder
                    fields={fields}
                    initialValues={_initialValues}
                    onSubmit={onSubmit}
                    hideCancel
                    hideHelpers
                    showValidationCheckIcon={false}
                    actionContainerProps={{
                        style: {
                            justifyContent: 'flex-end',
                            marginTop: 33
                        },
                    }}
                    setActionsOutsideFieldsContainer
                    submitCaption="Guardar ingreso"
                    submitButtonProps={{
                        size: "small",
                        className: classes.submitButton
                    }}
                    fieldsContainerProps={{
                        className: classes.card,
                    }}
                    customInputs={{
                        salary_scheme: function SalarySchemeInput({ onChange, ...rest }: any) {
                            return (
                                <SelectInput
                                    {...rest}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues(values => ({
                                            ...values,
                                            salary_scheme: e.target.value
                                        }))
                                    }}
                                />
                            )
                        },
                        custom_binary: function CustomBinaryInput({ onChange, ...rest }: any) {
                            return (
                                <BinaryInput
                                    {...rest}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues({
                                            ...values,
                                            predetermined: e.target.checked
                                        })
                                    }}
                                />
                            )
                        },
                        predetermined_binary: function CustomBinaryInput({ label, ...rest }: any) {
                            return (
                                <BinaryInput
                                    {...rest}
                                    label={(
                                        <Grid container className={classes.predeterminedLabel}>
                                            <Typography> {label} </Typography>
                                            <Tooltip title="Empleo que se prellenará en las solicitudes que haga el cliente">
                                                <span className={classes.helIconContainer}><HelpIcon color="primary" className={classes.helpIcon} /> </span>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                />
                            )
                        },
                        autocomplete: function customAutocompleteInput(props: any) {
                            return (
                                <AutoCompleteInput
                                    {...props}
                                    multiple={props?.config?.multiple || false}
                                    labelAttribute="caption"
                                    valueAttribute="value"
                                />
                            )
                        },
                        autocomplete2: function AutocompleteInput2(props: any) {
                            const value = props?.options?.find((o: any) => o.value === props?.value)
                            return (
                                <AutoCompleteInput
                                    {...props}
                                    multiple={props?.config?.multiple || false}
                                    labelAttribute="caption"
                                    valueAttribute="value"
                                    value={{ ...value }}
                                />
                            )
                        },
                        nationality: function NationalityInput({ value, onChange, ...rest }: any) {
                            return (
                                <SelectInput
                                    {...rest}
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues(values => ({
                                            ...values,
                                            nationality: e.target.value
                                        }))
                                    }}
                                />
                            )
                        },
                        checking_method: function CheckingMethodInput({ value, onChange, ...rest }: any) {
                            return (
                                <SelectInput
                                    {...rest}
                                    value={value}
                                    onChange={(e: any) => {
                                        onChange(e)
                                        setValues(values => ({
                                            ...values,
                                            proof_of_seniority_type: undefined,
                                            checking_method: e.target.value
                                        }))
                                    }}
                                />
                            )
                        },
                    }}
                    specialInputsProps={{
                        countryInputProps: {
                            isLoading: isLoadingCountries,
                            countries: countries || [],
                            getCountries: () => dispatch(providersActions.getCountries()),
                            setCountries: (countries?: any[]) => dispatch(providersActions.setCountries(countries)),
                            getStates: (countryCode?: string) => dispatch(providersActions.getStates(countryCode)),
                        }
                    }}
                />
            </Grid>
        </Loading>
    )
}