import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { StagePage } from "src/components";
import { useStyles } from "./styles";
import { EmploymentDetailsForm } from "./Form";
import { useEffect } from "react";
import { isValidId } from "src/helpers";
import { useDispatch } from "react-redux";
import { employmentsActions } from "src/store/employments/actions";
import { EmploymentDocuments } from "./Documents";

export function EmploymentDetailsPage() {
    const { applicationId, employmentId }: any = useParams()
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const isNew = employmentId === 'new'
    const breadcrumbTitle = isNew ? 'Añadir ingreso' : 'Detalles del ingreso'

    useEffect(() => {
        if (isValidId(employmentId)) {
            dispatch(employmentsActions.getOne(employmentId))
        }

        return () => {
            dispatch(employmentsActions.setOne())
        }
    }, [employmentId])

    return (
        <StagePage
            title={breadcrumbTitle}
            breadcrumbs={[
                { name: 'Inicio', to: '/' },
                { name: 'Ingresos', to: `/home/applications/${applicationId}/employments` },
                { name: breadcrumbTitle }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.title}> Datos del perfil económico </Typography>
                </Grid>

                <Grid item xs={12}>
                    <EmploymentDetailsForm
                        isNew={isNew}
                        applicationId={applicationId}
                    />
                </Grid>

                {!isNew && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.title}> Archivos de comprobación de ingresos </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <EmploymentDocuments
                                applicationId={applicationId}
                                employmentId={employmentId}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </StagePage>
    )
}