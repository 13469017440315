import { Chip } from "src/components"
import { PaymentType, SessionStatusType } from "src/types"
import { getPaymentSessionStatusColor, getPaymentStatus, customTranslatePaymentSessionStatus } from "src/helpers/payments"

interface PaymentStatusProps {
    payment?: PaymentType
    index: number
}

export function PaymentStatus({ index, payment }: PaymentStatusProps) {
    const paymentStatus = getPaymentStatus(payment!)
    const { color, backgroundColor } = getPaymentSessionStatusColor(paymentStatus as SessionStatusType)

    return (
        <Chip style={{ backgroundColor, color }}>
            Pago {customTranslatePaymentSessionStatus(payment)}
        </Chip>
    )
}