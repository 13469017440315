import { Pending2Icon } from "src/assets/icons";
import { useStyles } from "./styles"
import { PaymentType } from "src/types";
import { customTranslatePaymentSessionStatus, getPaymentSeverity, getPaymentStatus } from "src/helpers/payments";
import { Alert } from "@mui/material";
import { CheckIcon, CloseIcon } from "src/assets/icons/actions"

interface PaymentStatusAlertProps {
    payment?: PaymentType
}

export function PaymentStatusAlert({ payment }: PaymentStatusAlertProps) {
    const { classes, cx } = useStyles()
    const status = getPaymentStatus(payment)
    const severity = getPaymentSeverity(status)

    return (
        <Alert
            severity={severity}
            className={cx(classes.root, severity)}
            iconMapping={{
                success: <CheckIcon />,
                warning: <Pending2Icon />,
                error: <CloseIcon />,
            }}
            classes={{
                icon: classes.icon,
                message: classes.message,
            }}
        >
            Pago {customTranslatePaymentSessionStatus(payment)}
        </Alert>
    )
}