import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    tableHead: {
        backgroundColor: theme.palette.background.paper,
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    emptyState: {
        '& p': {
            marginTop: 8,
        },
        '& svg': {
            height: 43,
            width: 43,
        }
    }
}))