import { FieldType } from "src/types"

export function getTemplateContent(fieldType: FieldType): { content: string, imageUrl: string } {
    switch (fieldType) {
        case 'incode_document_id': return {
            content: '- Utiliza una identificación oficial vigente\n- Asegúrate de que la información sea clara y legible\n- Formatos aceptados: png, jpg o pdf\n- El archivo debe ser de máximo 10 mb',
            imageUrl: 'https://kredi-dev.s3.us-west-2.amazonaws.com/workflow_assets/id.png'
        }

        case 'incode_proof_of_income': return {
            content: '- Necesitamos comprobar tus ingresos de 3 meses\n- Asegúrate de que la información sea clara y legible\n- Formatos aceptados: png, jpg o pdf\n- El archivo debe ser de máximo 10 mb',
            imageUrl: 'https://kredi-dev.s3.us-west-2.amazonaws.com/workflow_assets/recibo_de_nomina.png'
        }

        case 'bank_statement': return {
            content: '- Necesitamos comprobar tus ingresos de 6 meses\n- Asegúrate de que la información sea clara y legible\n- Formatos aceptados: png, jpg o pdf\n- El archivo debe ser de máximo 10 mb',
            imageUrl: 'https://kredi-dev.s3.us-west-2.amazonaws.com/workflow_assets/estado_de_cuenta.png'
        }

        default: return {
            content: '',
            imageUrl: ''
        }
    }
}
