import { useEffect, ComponentType } from "react"
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { cookies, getCookieName, isValidId, segment } from "src/helpers";
import { useStyles } from "./styles";

import { useSelector, useDispatch } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { applicationActions } from "src/store/application/actions";
import { customerActions } from "src/store/customer/actions";

import { RejectedPage } from "./Rejected"
import { ApprovedPage } from "./Approved";
import { InReviewPage } from "./InReview";
import { PreapprovedPage } from "./Preapproved";
import { Breadcrumbs, Loading } from "src/components";

const responsesPages: { [key: string]: ComponentType<any> } = {
    approved: PreapprovedPage,
    rejected: RejectedPage,
    in_review: InReviewPage,
    pre_approved: PreapprovedPage,
    unreviewed: InReviewPage
}

export function ApplicationStatusResponsePage() {
    const { applicationId, status }: any = useParams();
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const scoringData = useSelector(state => state.auth.scoringSocketData);

    const Component = (
        Boolean(status)
            ? responsesPages[status]
            : responsesPages[scoringData?.action_tag
                ? (scoringData?.action_tag == 'comite' || scoringData?.action_tag == 'committee'
                    ? 'in_review'
                    : scoringData?.action_tag)
                : scoringData?.message || 'in_review']
    ) || LoadingComponent;

    function LoadingComponent() {
        return <Loading isLoading variant="circular" />
    }

    useEffect(() => {
        dispatch(applicationActions.set('isLoading', true));

        if (isValidId(applicationId)) {
            setTimeout(() => {
                dispatch(applicationActions.getApplication(applicationId));
            }, 5000)
        }

    }, [applicationId])

    useEffect(() => {
        if (isValidId(applicationId) && scoringData?.score_id) {
            dispatch(applicationActions.getScoreResult(applicationId, scoringData?.score_id))
        }
    }, [applicationId, scoringData?.score_id])

    useEffect(() => {
        if (scoringData?.message === 'rejected') {
            segment.track('Application', 'Rejected', {
                id: applicationId,
                action: 'Application Rejected',
            })
        }
    }, [scoringData?.message])

    useEffect(() => {
        dispatch(customerActions.set('isLoading', true))
        setTimeout(() => {
            if (cookies.get(getCookieName())) {
                dispatch(authActions.me());
            }
        }, 8000)
    }, []);

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.mainContainer}>
                <Grid container className={classes.container}>
                    <Component status={scoringData?.message || status} />
                </Grid>
            </Grid>
        </Grid>
    )
}