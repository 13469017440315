import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    wrapper: {
        maxWidth: '100%'
    },
    root: {
        background: "#FFFFFF",
        border: "1px solid #CFD5E2",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: '32px 14px 24px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 8px',
        },
    },
    iconContainer: {
        textAlign: 'center',
        marginBottom: 16,
        position: 'relative',
        '& svg': {
            height: 64,
            width: 64,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
            '& svg': {
                height: 49,
                width: 49,
            },
        },
    },
    titleContainer: {
    },
    actionContainer: {
        textAlign: 'center',
        marginTop: 18,
        [theme.breakpoints.down('sm')]: {
            marginTop: 16,
            marginBottom: 0,
        }
    },
    title: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        marginBottom: 4,
    },
    description: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center'
    },
    chip: {
        height: 24,
        position: 'absolute',
        right: -8,
        top: -8,
        '& svg': {
            display: 'none',
            height: 16,
            width: 16,
            fill: 'currentColor',
            color: 'inherit',
            '& path': {
                fill: 'currentColor',
            }
        },
        [theme.breakpoints.down('sm')]: {
            height: 24,
            width: 24,
            right: 0,
            '& .MuiChip-label': {
                display: 'none'
            },
            '& svg': {
                display: 'initial',
                margin: '0 auto'
            },
        }
    },
}))