import { useState } from "react"
import { Toolbar } from "@mui/material"
import { Menu as MenuIcon } from "@mui/icons-material"
import { Link } from "src/components"
import { cookies, getImageSource } from "src/helpers"
import { Menu } from "./Menu"
import { ToolbarProps } from ".."
import { CustomerAvatar } from "../Avatar"
import { useStyles } from "./styles"

export function MobileToolbar({
    organization,
    customer,
    hideLoginButtons,
    isAuthenticated,
    nativeProps,
    logout
}: ToolbarProps) {
    const { classes, cx } = useStyles(organization?.theme)()
    const { website, logo, theme } = organization || {}

    const [openSide, setOpenSide] = useState<boolean>(false)

    function handleLogout() {
        setOpenSide(false)
        logout()
    }

    return (
        <>
            <Menu
                open={openSide}
                isAuthenticated={isAuthenticated}
                cookies={cookies}
                logout={handleLogout}
                onClose={() => { setOpenSide(false) }}
            />

            <Toolbar {...nativeProps} className={cx(classes.root, nativeProps?.className)}>
                {!hideLoginButtons && (
                    <MenuIcon
                        color="primary"
                        onClick={() => setOpenSide(true)}
                        className={classes.menuIcon}
                    />
                )}

                <Link
                    native={!isAuthenticated}
                    to={!isAuthenticated && website || "/"}
                >
                    <img
                        src={theme?.navbar?.logo || logo || getImageSource()}
                        alt={`${organization?.name} logo`}
                        style={{
                            height: theme?.navbar?.logo ? 'auto' : '20px',
                            maxWidth: 107,
                            aspectRatio: 'auto',
                            display: 'block'
                        }}
                    />
                </Link>

                {(!hideLoginButtons && isAuthenticated) && (
                    <CustomerAvatar
                        organization={organization}
                        customer={customer}
                        logout={handleLogout}
                        wrapperProps={{
                            className: classes.avatarWrapper
                        }}
                    />
                )}
            </Toolbar>
        </>
    )
}