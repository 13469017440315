import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 16,
        marginLeft: 'auto',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginLeft: 0,
            gap: 32,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 16,
            flexDirection: 'column',
            gap: 24,
        }
    },
    actionButton: {
        height: 40,
        maxWidth: '100%',
        width: 268,
        [theme.breakpoints.down('md')]: {
            flex: 1,
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    }
}))