import { ReactChild, ReactNode } from 'react';
import { Drawer, Grid, Typography, DrawerProps, IconButton, TypographyProps, Box } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ArrowIcon from "@mui/icons-material/ArrowBack"
import { useStyles } from "./DrawerRight.styles";
import { GridProps } from '@mui/material';
export interface DrawerRightProps extends DrawerProps {
	title?: string;
	open: boolean;
	onClose?: () => void;
	children?: ReactChild | ReactChild[] | ReactNode,
	containerProps?: GridProps
	titleContainerProps?: GridProps
	titleProps?: TypographyProps
	closeVariant?: 'arrow' | 'close'

	/** @deprecated */
	disableDivider?: boolean
}

/** Use to show a custom component(s) in parent component right position */
export const DrawerRight = ({
	children,
	title,
	onClose,
	open,
	containerProps,
	titleContainerProps,
	titleProps,
	closeVariant = 'close',
	disableDivider,
	PaperProps,
	...rest
}: DrawerRightProps) => {
	const { classes, cx } = useStyles();
	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			variant="temporary"
			PaperProps={{
				className: classes.paper,
				style: {
					boxShadow: 'none',
					borderLeft: '1px solid #CFD5E2',
				},
				...PaperProps
			}}
			{...rest}
		>
			<Box className={classes.paperContent}>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					{...titleContainerProps}
					className={cx(classes.titleContainer, {
						[classes.titleContainerArrow]: closeVariant === 'arrow'
					}, titleContainerProps?.className)}
				>
					{closeVariant === 'close' ? (
						<>
							<Typography
								variant="h3"
								{...titleProps}
								className={cx("drawerTitle", classes.title, titleProps?.className)}
							>
								{title}
							</Typography>

							<IconButton data-testid="close-button" size="small" onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</>
					) : (
						<Grid container>
							<Grid item xs={12} className={classes.arrowContainer}>
								<IconButton data-testid="close-button" size="small" onClick={onClose} style={{ marginBottom: 16 }}>
									<ArrowIcon />
								</IconButton>
							</Grid>

							<Grid item xs={12}>
								<Typography
									variant="h3"
									{...titleProps}
									className={cx("drawerTitle", classes.title, titleProps?.className)}
								>
									{title}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid
					container
					{...containerProps}
					className={cx(classes.container, containerProps?.className)}
				>
					{children}
				</Grid>
			</Box>
		</Drawer>
	)
}