interface GradientBoxProps {
    wrapperRef: React.RefObject<HTMLDivElement>
    rgbaColor?: string
}

export function GradientBox({ wrapperRef, rgbaColor = "rgba(255, 255, 255, 0)" }: GradientBoxProps) {
    const wrapper = wrapperRef?.current
    const scrollWidth = wrapper?.scrollWidth || 0
    const clientWidth = wrapper?.clientWidth || 0
    const scrollLeft = wrapper?.scrollLeft || 0
    const clientHeight = wrapper?.clientHeight || 0

    function getGradient(): string {
        const nScrollWidth = scrollWidth - clientWidth

        if (nScrollWidth <= 0) {
            // When the scroll not exists
            return 'rgba(255, 255, 255, 0)'
        } else if (scrollLeft >= nScrollWidth) {
            // When the scroll is at the end
            return 'linear-gradient(-90deg, rgba(255, 255, 255, 0) 90%, #FFFFFF 100%)'
        } else if (scrollLeft === 0) {
            // When the scroll is at the beginning
            return 'linear-gradient(90deg, rgba(255, 255, 255, 0) 90%, #FFFFFF 100%)'
        } else {
            // When the scroll is in the middle
            return 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, #FFFFFF 100%)'
        }
    }

    if (!wrapper) {
        return null
    }

    return (
        <div
            style={{
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: 'none',
                height: clientHeight,
                // background: getGradient()
            }}
        />
    )
}