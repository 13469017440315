import { keyframes, Keyframes } from "@emotion/react"
import { alpha } from "@mui/material"
import { tss } from "tss-react/mui"

export const useStyles = tss
    .withNestedSelectors<'arrowIcon' | 'title' | 'iconWrapper'>()
    .create(({ theme, classes }) => ({
        root: {
            alignItems: "center",
            backgroundColor: theme.palette.primary.contrastText,
            border: '1px solid #CFD5E2',
            borderRadius: 10,
            cursor: 'pointer',
            height: 60,
            display: "flex",
            padding: '12px 16px',
            transition: 'all 0.2s ease-in-out',
            width: '100%',
            '&.disabled': {
                backgroundColor: theme.palette.grey[50],
                borderColor: theme.palette.grey[200],
                cursor: 'none',
                pointerEvents: 'none',
                [`& .${classes.arrowIcon}`]: {
                    fill: `${theme.palette.grey[400]} !important`
                },
                [`& .${classes.title}`]: {
                    color: theme.palette.grey[400]
                },
                [`& .${classes.iconWrapper}`]: {
                    opacity: 0.5
                }
            },
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                borderColor: 'transparent',
                [`& .${classes.arrowIcon}`]: {
                    animation: `${arrowAnimation} 0.5s ease-in-out infinite alternate`,
                }
            },
            '&:active': {
                boxShadow: 'none',
            }
        },
        iconWrapper: {
            marginRight: 24,
            '& svg': {
                width: 32,
                height: 32,
                verticalAlign: 'middle'
            }
        },
        title: {
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: "22px",
            letterSpacing: "0.15px",
            userSelect: 'none'
        },
        arrowIcon: {
            fill: `${theme.palette.primary.main} !important`,
            marginLeft: 'auto',
            transition: 'all 0.1s ease-in-out',
            [theme.breakpoints.between('sm', 'md')]: {
                display: 'none'
            }
        },
        badge: {
            marginRight: 16,
            '& .MuiBadge-badge': {
                height: 18,
                width: 18,
                minWidth: 16,
                fontSize: 12,
            }
        }
    }))

const arrowAnimation = keyframes({
    from: {
        transform: 'translateX(0)'
    },
    to: {
        transform: 'translateX(6px)'
    },
})