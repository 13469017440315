// TODO: Refactor Sidebar component, move to layout folder

import { useState, ComponentType, Fragment } from "react"

import {
	Typography,
	Breadcrumbs,
	Hidden,
	AppBar,
	Collapse,
	Divider,
	CircularProgress,
	Box,
	IconButton,
	Skeleton
} from "@mui/material"

import { MoreVert, ExpandMore as ExpandMoreIcon, ChevronLeft as LeftIcon } from "@mui/icons-material"
import { getApplicationStatusIcon, hasInstalledApp, navigate, useRouteMatch } from "src/helpers"
import { PageType, TrackingResourceType } from "src/helpers/segment"
import { ApplicationWorkflowSection } from "src/types"
import { Link } from "../Link"
import { SidebarLoading } from "./Loading"
import { useStyles } from "./Sidebar.styles"
import _ from "lodash"

import { useSelector } from "react-redux"

export interface SidebarBreadcrumb {
	to: string
	name?: string
}

export interface SidebarItem {
	id?: string
	icon?: any
	name?: string
	/** Use this if you need to declare params */
	path?: string
	/** Use this to redirect replacing the params */
	to: string
	isMenuItem?: boolean
	component?: ComponentType
	status?: string
	isParent?: boolean
	items?: SidebarItem[]
	sortOrder?: number
	page?: PageType
	resource?: TrackingResourceType
	disabled?: boolean
	/** Use for validate if a app is installed */
	app?: 'abacus'
	onClick?: () => void
	hidden?: boolean
}

export interface SidebarProps {
	title?: string
	items?: SidebarItem[]
	text?: string
	breadcrumbs?: SidebarBreadcrumb[]
	onItemClick?: (item: any) => void
	exactRoutes?: boolean
	showStatusIcon?: boolean
	titleLink?: SidebarBreadcrumb
	hover?: boolean
	isLoading?: boolean
	/** If using cypress this prop should is true */
	isTesting?: boolean
	/** Use for show steps progress in application */
	stepper?: boolean
	/** next section of appliction */
	nextSection?: ApplicationWorkflowSection
	openSectionDrawer?: () => void
	/** Is updating an application */
	isUpdating?: boolean
	sidebarProps?: any
	selectedItemClassname?: string
	hideAppbarOnMobile?: boolean
	isSummary?: boolean
}

/** Use to show a menu using items params */
export function Sidebar({
	title,
	items,
	text,
	breadcrumbs,
	onItemClick,
	exactRoutes,
	showStatusIcon,
	titleLink,
	hover,
	isLoading,
	isTesting,
	stepper,
	nextSection,
	isUpdating,
	openSectionDrawer,
	sidebarProps,
	selectedItemClassname,
	hideAppbarOnMobile,
	isSummary
}: SidebarProps) {
	const [open, setOpen] = useState(false)
	const { classes, cx } = useStyles()
	const [submenuOpen, setSubmenuOpen] = useState(0)
	const organization = useSelector(state => state.organization)
	const match = useRouteMatch()

	function onClick(item: SidebarItem, index: number) {
		if (item.isParent) {
			setSubmenuOpen(index)

			if ((item.items?.length || 0) > 0) {
				navigate.to(_.orderBy(item.items, 'sortOrder')[0].to!)
			}

		} else {
			if (_.isFunction(item.onClick)) {
				item.onClick()
			}
			if (typeof (onItemClick) === 'function') {
				onItemClick(item)
			}
			setOpen(false)

			if (index !== submenuOpen) {
				setSubmenuOpen(0)
			}
		}
	}

	function getPercentage(current: number, total: number) {
		return (current / total) * 100
	}

	const TitleWrapper = titleLink?.to ? Link : Fragment
	const titleWrapperProps = titleLink?.to ? { to: titleLink.to || '' } : {}

	function SidebarContent({ items = [] }: { items?: SidebarItem[] }) {
		return items.map((item: SidebarItem, index: number) => {
			if (item?.hidden || !item?.isMenuItem) {
				return null
			}

			if (item?.app && !hasInstalledApp(organization?.installed_apps || {}, item.app!)) {
				return null
			}

			const Icon = item.icon || Fragment
			const Component = (item.isParent || item.disabled) ? Fragment : Link
			const props = (item.isParent || item.disabled) ? {} : { to: item.to }

			const isSelected = (exactRoutes && (!!!item.isParent || true))
				? window.location.pathname === encodeURI(item.to.replace('?from=summary', '').replace('?from=stage_summary', ''))
				: window.location.pathname.includes(encodeURI(item.to?.toLowerCase()))

			return (
				<Fragment key={index}>
					{/*@ts-ignore*/}
					<Component {...props}>
						<div
							onClick={() => item.disabled ? null : onClick(item, index)}
							className={cx(classes.item, {
								[classes.hover]: hover && !item.disabled,
								[classes.selectedItem]: isSelected,
								[selectedItemClassname || '']: isSelected
							})}
						>
							<div className={classes.itemIconContainer}>
								<Icon />

								<Typography
									className={cx(classes.itemText, {
										[classes.disabledText]: item.disabled
									})}
								>
									{item.name}
								</Typography>
							</div>

							<div className={classes.statusIconContainer}>
								{showStatusIcon && getApplicationStatusIcon(item.status || '', isSummary)}
							</div>
						</div>
					</Component>

					{(item.items?.length || 0) > 0 && (
						<Collapse in={index === submenuOpen}>
							{_.orderBy(item.items || [], 'sortOrder').map(subItem => {
								const Component = subItem.disabled ? Fragment : Link
								const props = subItem.disabled ? {} : { to: subItem.to }

								return (
									/*@ts-ignore*/
									<Component key={subItem.id} {...props}>
										<div
											onClick={() => subItem.disabled ? null : onClick(subItem, index)}
											className={cx(classes.subItem, {
												[classes.hover]: hover && !subItem.disabled,
												[classes.selectedSubitem]: window.location.pathname === subItem.to
											})}
										>
											<div className={classes.itemIconContainer}>
												<Typography> {subItem.name} </Typography>
											</div>

											<div className={classes.statusIconContainer}>
												{showStatusIcon && getApplicationStatusIcon(subItem.status || '', isSummary)}
											</div>
										</div>
									</Component>
								)
							})}
						</Collapse>
					)}
				</Fragment>
			)
		})
	}

	return (
		<>
			<Box
				{...sidebarProps}
				className={cx(classes.sidebar, sidebarProps?.className)}
				sx={{
					display: {
						// Hide sidebar on mobile or tablet (between xs and md)
						xs: 'none',
						// Show sidebar on desktop (up to md)
						md: 'block',
					}
				}}
			>
				<div className={classes.sidebarContent}>
					{breadcrumbs && (
						<Breadcrumbs className={classes.breadcrumbs}>
							{breadcrumbs.map((breadcrumb: SidebarBreadcrumb, index: number) => (
								index === breadcrumbs.length - 1 ? (
									<Link to={breadcrumb.to}> {breadcrumb.name || ''} </Link>
								) : (
									<Typography color="textPrimary"> {breadcrumb.name} </Typography>
								)
							))}
						</Breadcrumbs>
					)}

					{title && (
						// @ts-ignore
						<TitleWrapper {...titleWrapperProps}>
							{isLoading ? <Fragment /> : (
								<Typography
									className={classes.title}
									style={{ paddingLeft: titleLink?.to ? 30 : 37 }}
								>
									{titleLink?.to && <LeftIcon color="primary" />}

									{title}
								</Typography>
							)}
						</TitleWrapper>
					)}

					{isLoading ? <SidebarLoading /> : <SidebarContent items={items} />}
				</div>

				{text && (
					<Typography
						className={classes.help}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				)}
			</Box>

			{!hideAppbarOnMobile && (
				<Hidden mdUp>
					<AppBar
						elevation={0}
						position="fixed"
						className={cx(classes.appbar, {
							[classes.appbarOpen]: open,
							[classes.appbarProgress]: stepper
						})}
					>
						<div
							className={cx(classes.toolbar, { [classes.toolbarProgress]: stepper })}
							onClick={() => {
								if (!stepper) {
									setOpen(!open)
								}
							}}
						>
							{stepper ? (
								<>
									<div className={classes.progressAndTitle}>
										<Box position="relative" display="inline-flex">
											<CircularProgress
												className={classes.top}
												variant="determinate" value={100} />
											<Box
												top={0}
												left={0}
												bottom={0}
												right={0}
												position="absolute"
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Typography variant="caption" component="div" color="textSecondary">
													{nextSection === null && match.url.includes('summary') ?
														`${items?.length}/${items?.length}`
														: (`${((items?.findIndex((item) => item.name === title) || 0) + 1)} /${items?.length}`)}
												</Typography>
											</Box>
											<CircularProgress
												className={classes.bottom}
												classes={{ circle: classes.circle }}
												disableShrink
												variant="determinate"
												color="primary"
												value={nextSection === null && match.url.includes('summary') ? 100 : getPercentage((items?.findIndex((item) => item.name === title) || 0), (items?.length || 0))}
											/>
										</Box>
										<Typography className={classes.title}>
											{isLoading || isUpdating ? <Skeleton width={250} /> : (
												nextSection === null && match.url.includes('summary') ?
													"Resumen"
													: title
											)}
										</Typography>
									</div>
									<IconButton onClick={() => openSectionDrawer && openSectionDrawer()} edge="end">
										<MoreVert />
									</IconButton>
								</>
							) : (
								<>
									<Typography className={classes.title}> {title} </Typography>

									<ExpandMoreIcon className={cx({ [classes.expandIconOpen]: open }, classes.expandIcon)} />
									{open && (
										<div className={classes.dividerContainer}>
											<Divider />
										</div>
									)}
								</>

							)}

						</div>
					</AppBar>

					<div className={cx(classes.toolbarContent, { [classes.toolbarContentHidde]: !open })}>
						{isLoading ? <SidebarLoading /> : <SidebarContent items={items} />}
					</div>

					<div
						onClick={() => setOpen(false)}
						className={cx({ [classes.toolbarContentHidde]: !open }, classes.overlay)}
					/>
				</Hidden>
			)}
		</>
	)
}