
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { Routes, Sidebar } from "src/components";
import { NotFound } from "../NotFound";

import { applicationActions } from "src/store/application/actions";
import { hasInstalledApp, isProduction, isValidId, useIsKredi, useIsMobile } from "src/helpers";
import { convertSidebarItemsToRoutes, getSidebarItems } from "./sidebarItems";
import { useStyles } from "./styles";
import { relatedApplcationsActions } from "src/store/relatedApplications/actions";
import { creditProposalsActions } from "src/store/creditProposals/actions";
import { creditsActions } from "src/store/credits/actions";

export function Home() {
	const { classes, cx } = useStyles()
	const dispatch = useDispatch()
	const isMobile = useIsMobile('sm')
	const isKredi = useIsKredi()

	const organization = useSelector(state => state.organization)
	const lastOneApplication = useSelector(state => state.applications.lastApplication)
	const customer = useSelector(state => state.auth.customer)
	const credit = useSelector(state => state.credits.credits.credit)

	const sidebarItems = getSidebarItems({
		lastOneApplication,
		credit,
		customer,
		isMobile,
		isKredi
	})

	const allowedSidebarItems = sidebarItems
		.filter(i => !Boolean(i?.disabled))
		.filter((item, index) => {
			if (item.app) {
				return hasInstalledApp(organization?.installed_apps || {}, item.app!)
			} else {
				return true
			}
		}).map(i => i)

	useEffect(() => {
		if (!lastOneApplication && !window.location.pathname.includes('/home/main')) {
			dispatch(applicationActions.getLastOneApplication())
		}

	}, [])

	useEffect(() => {
		if (!isProduction() && isValidId(lastOneApplication?.id)) {
			dispatch(relatedApplcationsActions.getAll(lastOneApplication!.id!, {
				search: { 'association_type_eq': 'co_accredited' }
			}))
		}

		if (lastOneApplication?.id) {
			dispatch(creditProposalsActions.getAll(lastOneApplication?.id, {}))
		}

		if (lastOneApplication?.credit_id) {
			dispatch(creditsActions.getOne(lastOneApplication?.credit_id))
		}
	}, [lastOneApplication?.id])

	return (
		<Grid container className={classes.container}>
			<Sidebar
				hover
				hideAppbarOnMobile
				items={allowedSidebarItems}
				selectedItemClassname={classes.selectedItem}
				sidebarProps={{ className: classes.sidebar }}
			/>

			<div className={classes.content}>
				<Routes
					routes={convertSidebarItemsToRoutes(allowedSidebarItems)}
					notFoundPage={() => <NotFound backgroundColor="#fff" />}
				/>
			</div>
		</Grid>
	)
}