import { Action } from "src/types";
import { SnackbarVariant } from "src/components";
import { SNACKBAR_TYPES } from "./types";

export interface SnackbarState {
    open: boolean;
    variant: SnackbarVariant;
    message: string;
    duration?: number
}

const INITIAL_STATE: SnackbarState = {
    open: false,
    variant: 'info',
    message: ''
}

export const snackbarReducer = (state: SnackbarState = INITIAL_STATE, action: Action): SnackbarState => {
    const { payload, type } = action;
    switch (type) {

        case SNACKBAR_TYPES.ADD:
            return {
                ...state,
                open: true,
                variant: payload.variant,
                message: payload.message,
                duration: payload.duration,
            }

        case SNACKBAR_TYPES.CLEAN:
            return INITIAL_STATE;

        default:
            return state;
    }
}