import { Grid } from "@mui/material";
import { useStyles } from "./styles"
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationStatusComponent } from "../components";
import { useIsMobile } from "src/helpers";

interface RejectedPageProps {
    status?: any;
}

export function RejectedPage({ status }: RejectedPageProps) {
    const queryParams: any = useParams()
    const { classes, cx } = useStyles();
    const isMobile = useIsMobile()
    const { application, isLoading } = useSelector(state => state.applications);

    return (
        <Grid
            id="rejected-hero"
            container
            spacing={isMobile ? 6 : 3}
            className={classes.hero}
        >
            <Grid item xs={12} md={8}>
                <ApplicationStatusComponent
                    status={status || queryParams.status}
                    isLoading={isLoading}
                    application={application}
                />
            </Grid>
        </Grid >
    )
}