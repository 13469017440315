import { SIDEBAR_WIDTH } from "src/components/Sidebar/Sidebar.styles"
import { MOBILE_TOOLBAR_HEIGHT } from "src/layout/Topbar"
import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    container: {
        height: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            height: `calc(100vh - ${MOBILE_TOOLBAR_HEIGHT}px)`
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F9F9FB',
        justifyContent: 'flex-start',
        width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    sidebar: {
        background: '#fff',
        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            height: '100%',
        },
    },
    selectedItem: {
        paddingLeft: '12px !important'
    }
}))