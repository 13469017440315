import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        background: '#fff',
        borderRadius: 20,
        padding: '24px 60px',
        textAlign: 'left',
        marginBottom: 40,
        '& > :last-child': {
            borderBottom: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 16,
            // justifyContent: 'flex-start',
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: 23.5,
            lineHeight: '24px'
        },
    },
    reason: {
        padding: '12px 0',
    },
    reasonTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 10,
        paddingBottom: 8,
        '& > p': {
            fontSize: 20,
            fontWeight: 'bold'
        },
        '& svg': {
            minWidth: 24
        }
    },
    reasonDescription: {
        fontWeight: 400,
        '& > a': {
            color: '#4AAAFF',
            textDecoration: 'none'
        }
    }
}));