import { MouseEvent, useEffect, useState, ReactNode } from "react"

import {
	TableContainer,
	Table as MuiTable,
	TableHead, TableBody,
	TableCell, TableRow,
	IconButton,
	TableProps as MuiTableProps,
	TableContainerProps,
	TableCellProps,
	TableHeadProps,
	TableBodyProps,
	Grid,
	TableRowProps,
	Collapse,
} from "@mui/material"

import {
	ArrowDropDown as CollapsableDownIcon,
	ArrowDropUp as CollapsableUpIcon,
	MoreVert as MoreVertIcon
} from "@mui/icons-material"


import { EmptyState, EmptyStateProps, Link } from ".."
import { RowCounter } from './RowCounter'
import { ActionMenu } from "./ActionMenu"
import { TableSortOrderTooltip } from "./SortOrderTooltip"
import { PaginationProps, Pagination } from "../Pagination"

import { useIsMobile } from "src/helpers"
import { TableActionsType, TableColumnType, TableRowVariantType } from "./Table.helpers"
import { useStyles } from "./Table.styles"
import _ from "lodash"

export interface TableProps<T = any> {
	/** Items to display */
	data?: T[]
	columns: TableColumnType<T>[]
	/** List of actions displayed at the right position inside row */
	actions?: TableActionsType<T>[]
	disableActions?: boolean
	onItemClick?: (row: T) => void
	isLink?: boolean
	emptyStateText?: string
	pagination?: PaginationProps
	hidePagination?: boolean
	hideRowCounter?: boolean
	CustomMenu?: any
	emptyStateProps?: EmptyStateProps
	getHref?: (row: T) => string
	tableProps?: MuiTableProps
	tableContainerProps?: TableContainerProps
	tableCellProps?: TableCellProps
	tableHeadProps?: TableHeadProps
	tableBodyProps?: TableBodyProps
	showEmptyStateInsideTable?: boolean
	onSortBy?: (key: string, order?: 'asc' | 'desc') => void
	/** Order rows ascendent or descendent (asc or desc)*/
	sortOrder?: string
	/** Related to sortOrder, is indicated to know which column to sort by name */
	sortBy?: string,
	/** If is necessary, use to convert data key to column key for styling purposes */
	getSortByToKey?: (key: string) => void,
	/** Disable first element autofocus */
	disableAutofocus?: boolean
	/** Hide all border bottom from all table cells */
	hideBorder?: boolean
	/** Hide the last border of the last row (if hideBorder prop is true this props is ignored) */
	hideLastOneRowBorder?: boolean
	showColumnNameInEachRow?: boolean
	/**
	 * Callback to disable a row
	 * @param row 
	 * @returns 
	 */
	disableRow?: (row: T) => boolean
	disableHover?: boolean
	getRowProps?: (row: T) => TableRowProps
	collapsable?: {
		component?: (row: T) => ReactNode,
		hiden?: (row: T) => boolean

		/** If true, keep open all collapsable components instead of just one */
		allowMultipleOpen?: boolean
	}
	rowVariant?: TableRowVariantType
}

/** Use to show an items list (if the device in use is mobile the table becomes a card list) */
export function Table<T = any>({
	data = [],
	columns,
	onItemClick,
	actions,
	disableActions,
	emptyStateText,
	pagination,
	CustomMenu,
	emptyStateProps,
	getHref,
	isLink,
	tableProps,
	tableContainerProps,
	tableCellProps,
	tableHeadProps,
	tableBodyProps,
	showEmptyStateInsideTable,
	onSortBy,
	sortOrder,
	sortBy: externalSortBy,
	disableAutofocus,
	hidePagination = true,
	hideRowCounter = true,
	hideBorder = true,
	hideLastOneRowBorder,
	showColumnNameInEachRow,
	disableRow,
	disableHover,
	getRowProps,
	collapsable,
	rowVariant = 'default',
}: TableProps<T>) {
	const { classes, cx } = useStyles()
	const isMobile = useIsMobile()

	const [sortBy, setSortBy] = useState({ key: '', order: 'asc' })
	const [menu, setMenu] = useState<{ anchorEl: null | HTMLElement, row: any }>({ anchorEl: null, row: {} })
	const [rowCollapsableOpen, setRowCollapsableOpen] = useState<{ [index in number]: boolean }>()

	const { page, perPage, totalPages, total, handlePagination } = pagination || {}

	const showColumns = (
		!showColumnNameInEachRow &&
		rowVariant === 'default' &&
		(data?.length > 0 || showEmptyStateInsideTable)
	)

	useEffect(() => {
		if (sortOrder || externalSortBy) {
			setSortBy({ key: externalSortBy!, order: sortOrder! })
		}
	}, [sortOrder, externalSortBy])

	useEffect(() => {
		if (!disableAutofocus) {
			setTimeout(() => {
				document.getElementById('table-row-1')?.focus()
			}, 300)
		}
	}, [disableAutofocus])

	function closeMenu() {
		setMenu({ anchorEl: null, row: {} })
	}

	function handleSortBy(key: string, order: 'asc' | 'desc') {
		if (_.isFunction(onSortBy)) {
			setSortBy((prevState) => ({
				key,
				order: order ? order : key === prevState.key && prevState.order === 'asc' ? 'desc' : 'asc',
			}))
			onSortBy(key, order)
		}
	}

	function handleCollapsable(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, index: number) {
		e.preventDefault()
		e.stopPropagation()

		setRowCollapsableOpen({
			// if allowMultipleOpen is true, keep the previous state and just change the current row
			...(collapsable?.allowMultipleOpen && rowCollapsableOpen),
			[index]: !Boolean(rowCollapsableOpen?.[index])
		})
	}

	return (
		<>
			<ActionMenu
				menu={menu}
				actions={actions}
				CustomMenu={CustomMenu}
				closeMenu={closeMenu}
			/>

			<TableContainer
				{...tableContainerProps}
				className={cx({
					[classes.rowVariantCardTableContainer]: rowVariant === 'cards'
				}, tableContainerProps?.className)}
			>
				<MuiTable
					{...tableProps}
					className={cx({
						[classes.rowVariantCardTable]: rowVariant === 'cards'
					}, tableProps?.className)}
				>
					{showColumns && (
						<TableHead {...tableHeadProps}>
							<TableRow>
								{_.sortBy(columns.filter(column => !column.hidden), 'sortOrder')
									.map(({
										key,
										label,
										filterOptions,
										onFilterSelect,
										hidden,
										disableSortBy,
										tableHeaderCellProps,
										...rest
									}) => {
										return (
											<TableCell
												key={key as React.Key}
												{...tableCellProps}
												{...tableHeaderCellProps}
												{...rest}
												className={cx(classes.columnName, {
													[classes.columnNameSortBy]: onSortBy && !disableSortBy
												}, tableCellProps?.className, tableHeaderCellProps?.className)}
											>
												<div className={classes.header}>
													<TableSortOrderTooltip
														label={label}
														key={key as string}
														columnKey={key as string}
														sortBy={sortBy}
														disableSortBy={disableSortBy || !_.isFunction(onSortBy)}
														onSortBy={handleSortBy}
													/>
												</div>
											</TableCell>
										)
									})}
								{!_.isEmpty(actions) && <TableCell className={classes.columnName} />}
							</TableRow>
						</TableHead>
					)}

					<TableBody
						{...tableBodyProps}
						className={cx({
							[classes.rowVariantCardTableBody]: rowVariant === 'cards'
						}, tableBodyProps?.className)}
					>
						{data?.map((row, index) => {
							const href = getHref?.(row)
							const RowComponent = isLink && href ? Link : 'tr'
							const disabledRow = disableRow ? disableRow(row) : false

							const rowProps = isLink && href ? { to: href } : {
								onClick: () => {
									if (_.isFunction(onItemClick) && !disabledRow)
										onItemClick(row)
								}
							}

							const customRowProps = getRowProps?.(row)

							const isHiddenCollapsable = collapsable?.hiden?.(row)
							const collapsableComponent = collapsable?.component && !isHiddenCollapsable
								? collapsable.component(row)
								: undefined

							return (
								<>
									<TableRow
										id={disableAutofocus ? '' : `table-row-${index + 1}`}
										// @ts-ignore
										data-row-id={row?.id || index}
										// @ts-ignore
										key={`table-row-${row.id}`}
										component={RowComponent}
										disabled={disabledRow}
										componentIfDisabled="tr"
										{..._.merge(rowProps, customRowProps)}
										className={cx(classes.row, {
											[classes.rowDisabled]: disabledRow,
											[classes.rowHover]: !disabledRow && !disableHover,
										}, customRowProps?.className)}
									>
										{_.sortBy(columns.filter(column => !column.hidden), 'sortOrder')
											.map(({
												key,
												label,
												render,
												tableCellProps: columnTableCellProps,
												disabled,
											}, colIndex) => {
												const value = _.get(row, key)
												const result = _.isFunction(render) ? render(value, row, index) : value ?? ""
												const isDisabled = _.isFunction(disabled) ? disabled(value, row) : false

												return (
													<TableCell
														id={`table-row-${index + 1}-cell-${key as string}`}
														key={`table-row-${index + 1}-cell-${key as string}`}
														{...tableCellProps}
														{...columnTableCellProps}
														className={cx({
															[classes.borderBottomNone]: (
																hideBorder ||
																Boolean(rowCollapsableOpen?.[index]) ||
																(hideLastOneRowBorder && index === data?.length - 1)
															),
															[classes.cellDisabled]: isDisabled,
															[classes.columnNameInCell]: showColumnNameInEachRow
														}, tableCellProps?.className, columnTableCellProps?.className)}
													>
														{(collapsable?.component && colIndex == 0) && (
															isHiddenCollapsable ? (
																// simulate the space of the collapsable icon button
																<div style={{ display: 'inline-block', width: 26 }} />
															) : (
																<IconButton
																	size="small"
																	className={classes.collapsableIconButton}
																	onClick={e => handleCollapsable(e, index)}
																>
																	{rowCollapsableOpen?.[index]
																		? <CollapsableUpIcon />
																		: <CollapsableDownIcon />}
																</IconButton>
															)
														)}

														{showColumnNameInEachRow && (
															<div className={classes.columnNameInCellLabel}>
																{label}
															</div>
														)}

														{result}
													</TableCell>
												)
											})}

										{(!_.isEmpty(actions) || CustomMenu) && (
											<TableCell
												id={`table-row-${index + 1}-actions`}
												key={`table-row-${index + 1}-actions`}
												align="right"
												className={cx({
													[classes.borderBottomNone]: (
														hideBorder ||
														Boolean(rowCollapsableOpen?.[index]) ||
														(hideLastOneRowBorder && index === data?.length - 1)
													)
												})}
											>
												<IconButton
													size="small"
													disabled={disableActions || actions?.every(a => a?.hidden && a.hidden(row))}
													onClick={e => {
														e.stopPropagation()
														e.preventDefault()
														setMenu({ anchorEl: e.currentTarget, row })
													}}
													data-cy='actions-menu'
												>
													<MoreVertIcon />
												</IconButton>
											</TableCell>
										)}
									</TableRow>

									{collapsableComponent && (
										<TableRow>
											<TableCell
												sx={{ padding: 0 }}
												colSpan={columns.filter(column => !column.hidden).length + 1}
												{...tableCellProps}
												className={cx({
													[classes.borderBottomNone]: (
														hideBorder ||
														!Boolean(rowCollapsableOpen?.[index]) ||
														(hideLastOneRowBorder && index === data?.length - 1)
													),
													[classes.columnNameInCell]: showColumnNameInEachRow
												}, tableCellProps?.className)}
											>
												<Collapse in={Boolean(rowCollapsableOpen?.[index])} timeout="auto" unmountOnExit>
													{collapsableComponent}
												</Collapse>
											</TableCell>
										</TableRow>
									)}
								</>
							)
						})}
					</TableBody>
				</MuiTable>
			</TableContainer>

			{data?.length === 0 && showEmptyStateInsideTable && (
				<EmptyState text={emptyStateText} {...emptyStateProps} />
			)}

			{(!hideRowCounter || !hidePagination) && (
				<Grid container direction="row" alignItems="center" style={{ paddingTop: 24 }}>
					{!hideRowCounter && (
						<RowCounter
							perPage={data ? data?.length : 0}
							total={total!}
						/>
					)}
					{!hidePagination && data?.length > 0 && (
						<Pagination
							page={page!}
							perPage={perPage!}
							totalPages={totalPages!}
							total={total!}
							handlePagination={handlePagination!}
							classes={{
								ul: isMobile ? classes.mobilePaginationList : undefined
							}}
						/>
					)}
				</Grid>
			)}

			{((data?.length || 0) <= 0 && !showEmptyStateInsideTable) && (
				<EmptyState text={emptyStateText} {...emptyStateProps} />
			)}
		</>
	)
}