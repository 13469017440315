import { alpha } from "@mui/material"
import { tss } from "tss-react/mui"

export const ELEMENTS_SPACING = 20

export const useStyles = tss.create(({ theme }) => ({
    root: {
        backgroundColor: 'white',
        borderRadius: 10,
        border: '1px solid #CFD5E2',
        display: 'block',
        height: '100%',
        padding: '16px 20px 20px',
        position: 'relative',
        transition: 'all 0.2s ease-in-out',
        '&.disabled': {
            opacity: 0.6,
            cursor: 'none',
            pointerEvents: 'none',
        },
        '&:hover': {
            boxShadow: `0px 4px 8px ${alpha(theme.palette.primary.main, 0.1)}`,
        },
        '&:active': {
            boxShadow: 'none',
        }
    },
    container: {
        display: 'flex',
        height: '100%',
    },
    menuButton: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    statusChip: {
        borderRadius: 8,
        fontSize: 12,
        margin: '0 auto',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        padding: '4px 8px',
        marginTop: 8,
        width: '100%',
    },
    iconContainer: {
        '& svg': {
            display: 'block',
            margin: '0 auto',
            height: 40,
            width: 40,
        }
    },
    titleDescriptionContainer: {
        marginBottom: 8,
    },
    title: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        marginTop: ELEMENTS_SPACING,
    },
    description: {
        fontSize: '12px',
        fontWeight: 450,
        lineHeight: '16px',
        marginTop: ELEMENTS_SPACING,
    },
    actionButton: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '18px'
    },
    footerContainer: {
        marginTop: 'auto',
    }
}))