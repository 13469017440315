import { tss } from "tss-react/mui"
import { OrganizationThemeType } from "src/types"

export const DESKTOP_TOOLBAR_HEIGHT = 64
export const MOBILE_TOOLBAR_HEIGHT = 56

export const useStyles = (orgTheme?: OrganizationThemeType) => tss.create(({ theme }) => {
    const { navbar } = orgTheme || {}
    return {
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: navbar?.background_color || '#fff',
            height: DESKTOP_TOOLBAR_HEIGHT,
            zIndex: 1200,
            [theme.breakpoints.down('sm')]: {
                height: MOBILE_TOOLBAR_HEIGHT,
            }
        },
    }
})