import { useEffect, useRef, useState } from "react"
import { Grid, GridProps } from "@mui/material";
import { useStyles } from "./ContainerCarrousel.styles"

import { DirectionArrow, SCROLL_SPEED } from "./DirectionArrow"
import { GradientBox } from "./GradientBox"

interface ContainerCarrouselProps extends GridProps {
    onRef?: (ref: HTMLDivElement) => void
    rgbaGradient?: string
}

/**
 * Provides a horizontally scrollable container with custom mouse-enter and mouse-leave behaviors to control the scrolling animation
 */
export function ContainerCarrousel({ children, onRef, className, rgbaGradient, ...rest }: ContainerCarrouselProps) {
    const { classes, cx } = useStyles()

    const [isRefLoaded, setIsRefLoaded] = useState(false)
    const [scrollAnimation, setScrollAnimation] = useState<number | null>(null)
    const [scrollSpeed, setScrollSpeed] = useState(SCROLL_SPEED)

    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const scrollSpeedRef = useRef(scrollSpeed)

    useEffect(() => {
        if (!isRefLoaded && wrapperRef?.current) {
            setIsRefLoaded(true)
            onRef?.(wrapperRef.current)
        }
    }, [wrapperRef?.current])

    useEffect(() => {
        scrollSpeedRef.current = scrollSpeed
    }, [scrollSpeed])

    function handleMouseEnter(direction: 'left' | 'right') {
        if (!scrollAnimation && wrapperRef?.current) {
            requestAnimationFrame(() => animateScroll(direction))
        }
    }

    function handleMouseLeave() {
        setScrollSpeed(SCROLL_SPEED)

        if (scrollAnimation) {
            cancelAnimationFrame(scrollAnimation || 0)
            setScrollAnimation(null)
        }
    }

    function animateScroll(direction: 'left' | 'right') {
        if (wrapperRef?.current) {
            wrapperRef.current.scrollLeft += direction === 'left'
                ? -scrollSpeedRef?.current || 0
                : scrollSpeedRef?.current || 0
        }

        setScrollAnimation(requestAnimationFrame(() => animateScroll(direction)))
    }

    return (
        <Grid container className={cx(classes.root, className)} {...rest}>

            <GradientBox
                wrapperRef={wrapperRef}
                rgbaColor={rgbaGradient}
            />

            <Grid item xs={12} ref={wrapperRef} className={classes.wrapper}>
                {['left', 'right'].map(direction => (
                    <DirectionArrow
                        key={direction}
                        direction={direction as 'left' | 'right'}
                        wrapperRef={wrapperRef}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        setScrollSpeed={setScrollSpeed}
                    />
                ))}

                {children}
            </Grid>
        </Grid>
    )
}